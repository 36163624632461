// https://fkhadra.github.io/react-toastify/introduction
import { toast } from 'react-toastify';

export function toastSuccessCreate(message1, message2) {
    var msg2 = "Record was successfully created.";
    if (message2) {
        msg2 = message2;
    }
    // toast.success(<div> <b className="text-success">{msg2}: </b>
    //     <hr className="p-0 m-1" style={{ color: "#28a745", backgroundColor: "#28a745", height: "2px" }} />  {message1} </div>,
    //     { position: "bottom-left", toastId: "toast.success.create" });
}

export function toastSuccessUpdate(message1, message2) {
    var msg2 = "Record was updated.";
    if (message2) {
        msg2 = message2;
    }
    // toast.success(<div> <b className="text-success">{msg2}: </b>
    //     <hr className="p-0 m-1" style={{ color: "#28a745", backgroundColor: "#28a745", height: "2px" }} /> {message1} </div>,
    //     { position: "bottom-left", toastId: "toast.success.update" });
}

export function toastSuccessDelete(message1, message2) {
    var msg2 = "Record was deleted.";
    if (message2) {
        msg2 = message2;
    }
    // toast.info(<div> <b className="text-info">{msg2}: </b>
    //     <hr className="p-0 m-1" style={{ color: "#17a2b8", backgroundColor: "#17a2b8", height: "2px" }} /> {message1} </div>,
    //     { position: "bottom-left", toastId: "toast.success.delete" });
}

export function toastError(message) {
    var errMessage = "Sorry, something went wrong. Please try again later!";
    if (message) {
        errMessage = message;
    }
    // toast.error(<span className="text-danger">{errMessage}</span>, { toastId: "toast.error", autoClose: 7000 });
    toast.error(<span className="text-danger">{errMessage}</span>, { toastId: "toast.error", autoClose: 86400000 });
}

export function toastDataError(data, message2) {
    var msg2 = "Errors";
    if (message2) {
        msg2 = message2;
    }
    var errMessage = Object.keys(data.errors).map((key) => {
        return data.errors[key].map(value => {
            return (
                <dir key={key} className="px-0 py-1 m-0">
                    {/* <b className="text-dark">{key}: </b><span className="text-danger">{value}</span> */}
                    <b className="text-dark">- </b><span className="text-danger">{value}</span>
                </dir>
            )
        });
    });
    toast.error(<div className="p-0 m-0"> <b className="text-danger">{msg2}: </b>
        <hr className="p-0 m-1" style={{ color: "#dc3545", backgroundColor: "#dc3545", height: "2px" }} /> {errMessage}</div>,
        // { toastId: "toast.data.error", autoClose: 12000 });
        { toastId: "toast.data.error", autoClose: 86400000 });
}

export function toastDataUploadError(errors, message2) {
    var msg2 = "Errors";
    if (message2) {
        msg2 = message2;
    }
    var errMessage = errors.map((err, i) => {
        return (
            <dir key={i} className="px-0 py-1 m-0">
                <b className="text-dark">{i + 1}: </b><span className="text-danger">{err}</span>
            </dir>
        )
    });
    toast.error(<div className="p-0 m-0"> <b className="text-danger">{msg2}: </b>
        <hr className="p-0 m-1" style={{ color: "#dc3545", backgroundColor: "#dc3545", height: "2px" }} /> {errMessage}</div>,
        // { toastId: "toast.data.error", autoClose: 8000 });
        { toastId: "toast.data.error", autoClose: 86400000 });
}