const { DateTime } = require("luxon");

export function getPatchArray(body, props) {
  var patchArray = [];
  Object.keys(body).forEach((k) => {
    var key = "/" + k;
    var value = body[k];
    var propsValue = props[k];

    if (value !== propsValue) {
      patchArray.push({
        op: "replace",
        path: key,
        value: value,
      });
    }
  });
  return patchArray;
}

export function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

// CH-17 - Excel - begin
export function pivot(data) {
  var iter = function (pivoted, record) {
    for (var key in record) {
      if (!pivoted[key]) pivoted[key] = []
      pivoted[key].push(record[key])
    }
    return pivoted;
  };
  return data.reduce(iter, {})
};


export function getPivotHeader(pvheader) {
  return ["fieldName", ...pvheader.map(column => column)];
};


export function pivotArray(pivotData, pivotHeaders, headers) {
  var pivotArr = [];
  Object.entries(pivotData).forEach(([key, values], k) => {
    var obj = {};
    // obj["fieldName"] = key;
    obj["fieldName"] = headers[2][k];
    values.forEach((value, i) => {
      pivotHeaders.forEach((column, j) => {
        if (i + 1  === j) {
          obj[column] = value;
        }
      })
    })
    pivotArr.push(obj);
  });
  return pivotArr;
};

export function exportToExcel(records, filename, headers, options, workbookSheet) {
  var XLSX = require("xlsx");
  // Create a new workbook
  var workbook = XLSX.utils.book_new();
  // Convert the data to export to sheet format
  var ws = XLSX.utils.json_to_sheet(records, options);
  // Add column headers
  XLSX.utils.sheet_add_aoa(ws, headers);
  // Append the sheet to the workbook
  XLSX.utils.book_append_sheet(workbook, ws, workbookSheet);
  // Write the workbook to an excel file to be downloaded
  XLSX.writeFileXLSX(workbook, filename);
}
// CH-17 - Excel - end

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age + "y";
}

// CH-62
export function age(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function isEmpty(value) {
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}