
export function logRequest(method, func, entity, compClass, fetchUrl, body) {
    console.log(entity + " " + method + " Request: " + compClass + ": " + func + " -> Request URL:");
    console.log(fetchUrl);
    if (body) {
        console.log(compClass + " " + method + " Request: " + compClass + ": " + func + " -> body:");
        console.log(body);
    }
}

export function logSuccessResponse(method, func, entity, compClass, data) {
    console.log(entity + " " + method + " Success Response: " + compClass + ": " + func + " -> data:");
    console.log(data);
}

export function logErrorResponse(method, func, entity, compClass, error) {
    console.log(entity + " " + method + " Error Response: " + compClass + ": " + func + " -> error:");
    console.log(error);
}

export function logDataErrorResponse(method, func, entity, compClass, data) {
    console.log(entity + " " + method + " Data Model Error Response: " + compClass + ": " + func + " -> error:");
    console.log(data);
}
