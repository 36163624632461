import React, { Component } from "react";
import $ from "jquery";

import Select from "@ukhc/devops-react-library/components/Select";
import TextInput from "@ukhc/devops-react-library/components/TextInput";
import TextArea from "@ukhc/devops-react-library/components/TextArea";
import DateInput from "@ukhc/devops-react-library/components/DateInput";
import NumberInput from "@ukhc/devops-react-library/components/NumberInput";

import Visits from "../Visit/Visits";
import VisitModal from "../Visit/VisitModal";

import Button from "../Lib/Button";
import { getPatchArray, age } from "../Lib/Helper";
import { logRequest, logSuccessResponse, logErrorResponse, logDataErrorResponse } from "../Lib/Logs";
import { toastSuccessCreate, toastSuccessUpdate, toastError, toastDataError } from "../Lib/Toasts";

export class PatientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props: null,

      id: null,

      firstName: null,
      lastName: null,

      mrn: null,
      dob: null,
      institutionName: null,

      dateHAISurgery: null,
      height: null,
      sex: null,

      // new fields
      asT_ULN: null,
      alT_ULN: null,      // CH-29
      alP_ULN: null,
      bili_ULN: null,

      pumpType: null,     // Pump Type  ->  Medtronic / Intera                                                                  - CH-38
      pumpVolume: null,   // Medtronic	->	Pump Volume		->	20 ml / 40 ml      | Intera		  ->	Pump Volume		->	30 ml       - CH-38
      flowRate: null,     // Medtronic	->	Flow Rate	    -> 	1.0 (0.1 … 20.0)   | Intera		  ->  Flow Rate	-> 	1.2 (1.2 … 1.3) - CH-38

      onCoreId: null,     // CH-51
      test: null,         // CH-55

      // =============================================
      disabled: this.props.disabled,
      isFamilyMember: false,

      isModal: this.props.isModal,
      action: this.props.action,
      modalEntity: this.props.modalEntity,

      isProcessing: false,
      isSubmitButtonEnabled: false,

      // ===========================
      visitAddModalShow: false,
      visitEditModalShow: false,
      visitViewModalShow: false,

      // visitApproveModalShow: false,        
      visitTOApprovalModalShow: false,               // TO Approval (Tertiary Oncologist Approval)  -  CH-32
      visitCOApprovalModalShow: false,               // CO Approval (Community Oncologist Approval) -  CH-32

      lastDateOfVisit: null,      // CH-68
    };
    this.controller = "patients";
    this.entity = "Patient";
    this.compClass = "PatientModal";

    this.handleVisitsButtonClick = this.handleVisitsButtonClick.bind(this);
    this.handleLastDateOfVisit = this.handleLastDateOfVisit.bind(this);           // CH-68

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);

    this.handleSelectLast = this.handleSelectLast.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.getSuccessMessage = this.getSuccessMessage.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (state.action !== "add" && state.id !== props.patient.id) {
      return {
        props: props.patient,

        id: props.patient.id,

        firstName: props.patient.firstName,
        lastName: props.patient.lastName,

        mrn: props.patient.mrn,
        dob: props.patient.dob,
        institutionName: props.patient.institutionName,

        dateHAISurgery: props.patient.dateHAISurgery,
        height: props.patient.height,
        sex: props.patient.sex,

        // old fields
        // result: props.patient.result,
        // status: props.patient.status,
        // comment: props.patient.comment,

        // new fields
        asT_ULN: props.patient.asT_ULN,
        alT_ULN: props.patient.alT_ULN,         // CH-29
        alP_ULN: props.patient.alP_ULN,
        bili_ULN: props.patient.bili_ULN,

        pumpType: props.patient.pumpType,       // Pump Type  ->  Medtronic / Intera                                                                  - CH-38
        pumpVolume: props.patient.pumpVolume,   // Medtronic	->	Pump Volume		->	20 ml / 40 ml      | Intera		  ->	Pump Volume		->	30 ml       - CH-38
        flowRate: props.patient.flowRate,       // Medtronic	->	Flow Rate	    -> 	1.0 (0.1 … 20.0)   | Intera		  ->  Flow Rate	-> 	1.2 (1.2 … 1.3) - CH-38

        onCoreId: props.patient.onCoreId,       // CH-51
        test: props.patient.test,               // CH-55
      };
    }
    return null;
  }

  componentDidMount() {
    // console.log("==== PatientModal this.props ====");
    // console.log(this.props);
    // console.log("==== this.props.selectionOptions.Institution_InstitutionName ====");
    // console.log(this.props.selectionOptions.Institution_InstitutionName);
    // console.log("==== PatientModal this.state ====");
    // console.log(this.state);
  }

  // Add/View/Edit Visits <button>
  handleVisitsButtonClick(selectedRecord, action) {
    if (action === "add") {
      this.setState(
        {
          visitAddModalShow: true,
        },
        () => {
          $("#visitModal").modal("show");
        }
      )
    } else if (action === "view") {
      this.setState(
        {
          visitViewModalShow: true,
          selectedRecord: selectedRecord,
        },
        () => {
          $("#visitModal").modal("show");
        }
      );
      // } else if (action === "approve") {
    } else if (action === "to-approval") {                  // TO Approval (Tertiary Oncologist Approval)  -  CH-32
      this.setState(
        {
          // visitApproveModalShow: true,
          visitTOApprovalModalShow: true,                   // TO Approval (Tertiary Oncologist Approval)  -  CH-32
          selectedRecord: selectedRecord,
        },
        () => {
          $("#visitModal").modal("show");
        }
      );
    } else if (action === "co-approval") {                  // CO Approval (Community Oncologist Approval) -  CH-32
      this.setState(
        {
          visitCOApprovalModalShow: true,                   // CO Approval (Community Oncologist Approval) -  CH-32
          selectedRecord: selectedRecord,
        },
        () => {
          $("#visitModal").modal("show");
        }
      );
    } else if (action === "edit") {
      this.setState(
        {
          visitEditModalShow: true,
          selectedRecord: selectedRecord,
        },
        () => {
          $("#visitModal").modal("show");
        }
      );
    }
  }


  handleLastDateOfVisit(lastDateOfVisit) {
    this.setState({lastDateOfVisit: lastDateOfVisit});
  }

  getSuccessMessage(id) {
    var moment = require("moment");
    return (
      <>
        {this.entity} # <span className="text-success">{id}</span>
        , First Name: <span className="text-success">{this.state.firstName}</span>
        , Last Name: <span className="text-success">{this.state.lastName}</span>
        , MRN: <span className="text-success">{this.state.mrn}</span>
        , DOB: <span className="text-success">{moment(this.state.dob).format("l")}</span>
        , Institution: <span className="text-success">{this.state.institutionName}</span>
      </>
    )
  }

  // patientsPost / patientsPatch
  handleSubmit(event) {
    event.preventDefault();
    var body;

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    } else {
      this.setState({ isProcessing: true });
      form.classList.add("was-validated");
    }

    body = {
      // id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,

      mrn: this.state.mrn,
      dob: this.state.dob,
      institutionName: this.state.institutionName ? this.state.institutionName : this.props.selectionOptions.Institution_InstitutionName[0].value,

      dateHAISurgery: this.state.dateHAISurgery,
      height: this.state.height,
      sex: this.state.sex,

      // old fields
      // result: this.state.result,
      // status: this.state.status,
      // comment: this.state.comment,

      // new fields
      asT_ULN: this.state.asT_ULN,
      alT_ULN: this.state.alT_ULN,        // CH-29
      alP_ULN: this.state.alP_ULN,
      bili_ULN: this.state.bili_ULN,

      pumpType: this.state.pumpType,       // Pump Type  ->  Medtronic / Intera                                                                 - CH-38
      pumpVolume: this.state.pumpVolume,   // Medtronic	->	Pump Volume		->	20 ml / 40 ml      | Intera		  ->	Pump Volume		->	30 ml       - CH-38
      flowRate: this.state.flowRate,       // Medtronic	->	Flow Rate	    -> 	1.0 (0.1 … 20.0)   | Intera		  ->  Flow Rate	-> 	1.2 (1.2 … 1.3) - CH-38

      onCoreId: this.state.onCoreId,       // CH-51
      test: this.state.test,               // CH-55
    }

    if (this.props.action === "add") {

      let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller;
      logRequest("POST", "handleSubmit", this.entity, this.compClass, fetchUrl, body);

      fetch(fetchUrl, {
        method: "POST",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          // error handling
          if (response.ok) {
            return response.json();
          } else {
            if (response.json) {
              return response.json();
            } else {
              throw new Error(response.status);
            }
          }
        })
        .then((data) => {
          if (data.errors) {
            //alert("Error Saving Record: " + error);
            logDataErrorResponse("POST", "handleSubmit", this.entity, this.compClass, data);
            toastDataError(data);
            throw new Error("data.errors")
          } else {
            if (data.id) {
              //alert("Record Saved Sucessfully");
              logSuccessResponse("POST", "handleSubmit", this.entity, this.compClass, data);
              this.handleSelectLast(data.id);
              toastSuccessCreate(this.getSuccessMessage(data.id));
              this.setState({ isSubmitButtonEnabled: false });
              return true;
            } else {
              throw new Error("data.id")
            }
          }
        })
        .then(this.handleCloseModal)
        .catch((error) => {
          this.setState({ isSubmitButtonEnabled: true });
          if (error.message && error.message === "data.errors") {
            return false;
          }
          //alert("Error Saving Record: " + error);
          logErrorResponse("POST", "handleSubmit", this.entity, this.compClass, error);
          toastError();
          return false;
        })
        .finally(() => {
          this.setState({ isProcessing: false });
        });

    } else if (this.props.action === "edit") {
      var patchArray = getPatchArray(body, this.state.props);

      if (patchArray && patchArray.length > 0) {

        let id = this.state.id;
        let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "/" + id;
        logRequest("PATCH", "handleSubmit", this.entity, this.compClass, fetchUrl, patchArray);

        fetch(fetchUrl, {
          method: "PATCH",
          headers: {
            ACCEPT: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
          },
          body: JSON.stringify(patchArray),
        })
          .then((response) => {
            // error handling
            if (response.ok) {
              return response;
            } else {
              if (response.json) {
                return response.json();
              } else {
                throw new Error(response.status);
              }
            }
          })
          .then((data) => {
            if (data.errors) {
              //alert("Error Saving Record: " + error);
              logDataErrorResponse("PATCH", "handleSubmit", this.entity, this.compClass, data);
              toastDataError(data);
              throw new Error("data.errors")
            } else {
              //alert("Record Saved Sucessfully");
              logSuccessResponse("PATCH", "handleSubmit", this.entity, this.compClass, data);
              toastSuccessUpdate(this.getSuccessMessage(id));
              this.setState({ isSubmitButtonEnabled: false });
              return true;
            }
          })
          .then(this.handleCloseModal)
          .then(this.handleFetch)
          .catch((error) => {
            this.setState({ isSubmitButtonEnabled: true });
            if (error.message && error.message === "data.errors") {
              return false;
            }
            //alert("Error Saving Record: " + error);
            logErrorResponse("PATCH", "handleSubmit", this.entity, this.compClass, error);
            toastError();
            return false;
          })
          .finally(() => {
            this.setState({ isProcessing: false });
          });
      }
      else {
        this.handleCloseModal();
      }
    }
  }

  handleSelectLast(id) {
    this.props.onSelectLast(id);
  }

  handleFetch() {
    this.props.onFetch();
  }

  handleCloseModal() {
    // Close the modal
    this.props.onHide();
  }

  handleOnChange = (name, value) => {
    // console.log("name: " + name + " value: " + value);
    this.setState({ [name]: value, isSubmitButtonEnabled: true }, () => {

      if (name === 'pumpType') {
        if (value === 'Intera') {
          this.setState({ pumpVolume: "30", flowRate: "1.2" }, () => {
            $("#pumpVolume").val("30");
            $("#flowRate").val("1.2");
          });
        } else if (value === 'Medtronic') {
          this.setState({ pumpVolume: null, flowRate: "1.0" }, () => {
            $("#pumpVolume").val(null);
            $("#flowRate").val("1.0");
          });
        } else {
          this.setState({ pumpVolume: null, flowRate: null }, () => {
            $("#pumpVolume").val(null);
            $("#flowRate").val(null);
          });
        }
      }

      // CH-62 - begin
      if (this.state.action === "add" && this.state.dob) {
        if (name === 'sex') {
          this.setULN(this.state.dob, value)
        }
        if (name === 'dob' && this.state.sex) {
          this.setULN(value, this.state.sex)
        }
      }
      // CH-62 - end
    });
  }

  // CH-62
  setULN = (dob, sex) => {
    const yo = age(dob);
    if (sex === 'Male') {
      // AST
      if (yo < 60) {
        this.setState({ asT_ULN: "40" }, () => {
          $("#asT_ULN").val("40");
        });
      } else {
        this.setState({ asT_ULN: "48" }, () => {
          $("#asT_ULN").val("48");
        });
      }
      // ALT
      this.setState({ alT_ULN: "41" }, () => {
        $("#alT_ULN").val("41");
      });
      // ALP
      this.setState({ alP_ULN: "115" }, () => {
        $("#alP_ULN").val("115");
      });
      // Bilirubin
      this.setState({ bili_ULN: "1.1" }, () => {
        $("#bili_ULN").val("1.1");
      });
    } else if (sex === 'Female') {
      // AST
      if (yo < 60) {
        this.setState({ asT_ULN: "32" }, () => {
          $("#asT_ULN").val("32");
        });
      } else {
        this.setState({ asT_ULN: "36" }, () => {
          $("#asT_ULN").val("36");
        });
      }
      // ALT
      this.setState({ alT_ULN: "33" }, () => {
        $("#alT_ULN").val("33");
      });
      // ALP
      if (yo < 56) {
        this.setState({ alP_ULN: "104" }, () => {
          $("#alP_ULN").val("104");
        });
      } else {
        this.setState({ alP_ULN: "142" }, () => {
          $("#alP_ULN").val("142");
        });
      }
      // Bilirubin
      this.setState({ bili_ULN: "1.1" }, () => {
        $("#bili_ULN").val("1.1");
      });
    }
  }

  render() {
    var moment = require("moment");

    let visitAddModalClose = () =>
      this.setState({ visitAddModalShow: false }, () => {
        // this.handleFetch();
      });
    let visitEditModalClose = () =>
      this.setState({ visitEditModalShow: false }, () => {
        // this.handleFetch();
      });
    let visitViewModalClose = () =>
      this.setState({ visitViewModalShow: false }, () => {
        // this.handleFetch();
      });

    // let visitApproveModalClose = () =>
    let visitTOApprovalModalClose = () =>                               // TO Approval (Tertiary Oncologist Approval)  -  CH-32
      // this.setState({ visitApproveModalShow: false }, () => {
      this.setState({ visitTOApprovalModalShow: false }, () => {
        // this.handleFetch();
      });

    let visitCOApprovalModalClose = () =>                               // CO Approval (Community Oncologist Approval) -  CH-32
      this.setState({ visitCOApprovalModalShow: false }, () => {
        // this.handleFetch();
      });

    let patient = {
      id: this.state.id,

      firstName: this.state.firstName,
      lastName: this.state.lastName,

      mrn: this.state.mrn,
      dob: this.state.dob,
      institutionName: this.state.institutionName,

      dateHAISurgery: this.state.dateHAISurgery,
      height: this.state.height,
      sex: this.state.sex,

      // new fields
      asT_ULN: this.state.asT_ULN,
      alT_ULN: this.state.alT_ULN,         // CH-29
      alP_ULN: this.state.alP_ULN,
      bili_ULN: this.state.bili_ULN,

      pumpType: this.state.pumpType,       // Pump Type  ->  Medtronic / Intera                                                                 - CH-38
      pumpVolume: this.state.pumpVolume,   // Medtronic	->	Pump Volume		->	20 ml / 40 ml      | Intera		  ->	Pump Volume		->	30 ml       - CH-38
      flowRate: this.state.flowRate,       // Medtronic	->	Flow Rate	    -> 	1.0 (0.1 … 20.0)   | Intera		  ->  Flow Rate	-> 	1.2 (1.2 … 1.3) - CH-38

      onCoreId: this.state.onCoreId,       // CH-51
      test: this.state.test,               // CH-55
    }

    var modalDialogStyle = { minWidth: "97%" };
    if (this.state.action === "add") {
      modalDialogStyle = { minWidth: "65%" };
    }

    // CH-55, CH-56 
    var modalHeaderBg = "";
    if (this.state.test === "Yes") {
      modalHeaderBg = "bg-warning";
    }

    if (this.state.action === "dashboard")
      var dashboardInfo = <>
        <span className="px-md-1 px-lg-3 px-xl-4"><i className="bi bi-forward-fill mr-2"></i></span>
        Patient ID: <span className="text-primary">{this.state.id}</span>,
        First Name: <span className="text-primary">{this.state.firstName}</span>,
        Last Name: <span className="text-primary">{this.state.lastName}</span>,
        MRN: <span className="text-primary">{this.state.mrn}</span>,
        DOB: <span className="text-primary">{moment(this.state.dob).format("l")}</span>,
        Date HAI Surgery: <span className="text-primary">{moment(this.state.dateHAISurgery).format("l")}</span>,
        Height (cm): <span className="text-primary">{this.state.height}</span>,
        Sex: <span className="text-primary">{this.state.sex}</span>,
        {/* CH-64  */}
        {/* AST ULN: <span className="text-primary">{this.state.asT_ULN}</span>,
        ALT ULN: <span className="text-primary">{this.state.alT_ULN}</span>,
        ALP ULN: <span className="text-primary">{this.state.alP_ULN}</span>,
        Bili ULN: <span className="text-primary">{this.state.bili_ULN}</span>, */}
        Institution: <span className="text-primary">{this.state.institutionName}</span>,
        Pump Type: <span className="text-primary">{this.state.pumpType}</span>,
        Pump Volume (ml): <span className="text-primary">{this.state.pumpVolume}</span>,
        Flow Rate: <span className="text-primary">{this.state.flowRate}</span>,
        {/* CH-51 */}
        ONCORE ID: <span className="text-primary">{this.state.onCoreId}</span>
        {/* CH-55, CH-56 */}
        {(this.state.test === "Yes") && (<>   -   <span className="text-danger">This is a TEST Record !!!</span></>)}
      </>

    return (
      <div
        className="modal fade"
        id="patientModal"
        role="dialog"
        aria-labelledby="patientModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered" role="document" style={modalDialogStyle}>
          <div className="modal-content" style={{ minHeight: "35vh" }} >
            <div className={`modal-header card-header ${modalHeaderBg}`} >
              <h5 className="modal-title" id="patientModalLabel">
                {(this.state.action === "add") && (
                  <>
                    <i className="bi bi-person-plus mr-2"></i> Add New {this.state.modalEntity}
                  </>
                )}
                {(this.state.action === "view") && (
                  <>
                    {/* <i className="bi bi-card-list mr-2"></i> View {this.state.modalEntity} Profile # <span className="text-primary">{this.state.id}</span> */}
                    {/* CH-18 */}
                    <i className="bi bi-card-list mr-2"></i> View <span className="text-primary">{this.state.firstName} {this.state.lastName}</span> Profile
                    {/* CH-55, CH-56 */}
                    {(this.state.test === "Yes") && (<>   -   <span className="text-danger">This is a TEST Record !!!</span></>)}
                  </>
                )}
                {(this.state.action === "edit") && (
                  <>
                    {/* <i className="bi bi-pencil mr-2"></i> Edit {this.state.modalEntity} Profile # <span className="text-primary">{this.state.id}</span> */}
                    {/* CH-18 */}
                    <i className="bi bi-pencil mr-2"></i> Edit <span className="text-primary">{this.state.firstName} {this.state.lastName}</span> Profile
                    {/* CH-55, CH-56 */}
                    {(this.state.test === "Yes") && (<>   -   <span className="text-danger">This is a TEST Record !!!</span></>)}
                  </>
                )}
                {(this.state.action === "dashboard") && (
                  <>
                    {/* CH-17 */}
                    <i className="bi bi-clipboard-data mr-2"></i> Dashboard {dashboardInfo}
                  </>
                )}
              </h5>
              <button
                type="button"
                id="closeAddModalButton"
                className="close"
                onClick={this.handleCloseModal}
                aria-label="Close"
                aria-hidden="true"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <>

                {/* CH-17 */}
                {(this.state.action !== "dashboard") && (
                  <form
                    className="needs-validation"
                    noValidate
                    id="patientForm"
                    onSubmit={this.handleSubmit}>


                    <div className="row">

                      {/* CH-18 */}
                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <div className="form-group">
                          <label className="mb-0 font-weight-bold text-dark"
                            style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                            <span style={{ lineHeight: "1.7" }}>Patient ID</span>
                          </label>
                          <input
                            className="form-control form-control-sm"
                            id="patientId"
                            name="patientId"
                            type="text"
                            value={this.state.id}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <TextInput
                          label="First Name"
                          id="firstName"
                          name="firstName"
                          defaultValue={this.state.firstName}
                          onChange={(v) => { this.handleOnChange("firstName", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          maxLength={25}
                          required={true}
                          invalidFeedback="The First Name field is required."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <TextInput
                          label="Last Name"
                          id="lastName"
                          name="lastName"
                          defaultValue={this.state.lastName}
                          onChange={(v) => { this.handleOnChange("lastName", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          maxLength={35}
                          required={true}
                          invalidFeedback="The Last Name field is required."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <TextInput
                          label="MRN"
                          id="mrn"
                          name="mrn"
                          defaultValue={this.state.mrn}
                          onChange={(v) => { this.handleOnChange("mrn", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          maxLength={32}
                          required={true}
                          invalidFeedback="The MRN field is required."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <DateInput
                          label="DOB"
                          id="dob"
                          name="dob"
                          defaultValue={this.state.dob}
                          onChange={(v) => { this.handleOnChange("dob", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          required={true}
                          invalidFeedback="The DOB field is required."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <DateInput
                          label="Date HAI Surgery"
                          id="dateHAISurgery"
                          name="dateHAISurgery"
                          defaultValue={this.state.dateHAISurgery}
                          onChange={(v) => { this.handleOnChange("dateHAISurgery", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          required={true}
                          invalidFeedback="The Date HAI Surgery field is required."
                        />
                      </div>

                      {/* CH-22 */}
                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <TextInput
                          label="Height (cm)"
                          id="height"
                          name="height"
                          defaultValue={this.state.height}
                          onChange={(v) => { this.handleOnChange("height", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          maxLength={5}
                          // number with 1 decimal between 5.0 and 300
                          pattern="^(?!(0(\.[0-9]{0,1})?|1(\.[0-9]{0,1})?|2(\.[0-9]{0,1})?|3(\.[0-9]{0,1})?|4(\.[0-9]{0,1})?)$)((([1-2]{0,1}[0-9]{0,2})(\.[0-9]{0,1})?)|(([1-9][0-9]{0,1})(\.[0-9]{0,1})?))$|^300$"
                          required={true}
                          invalidFeedback="Height must be a number with 1 decimal between 152.4 and 300."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <Select
                          label="Sex"
                          id="sex"
                          name="sex"
                          defaultValue={this.state.sex}
                          onChange={(v) => { this.handleOnChange("sex", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          options={[
                            { value: "", label: "" },
                            { value: "Male", label: "Male" },
                            { value: "Female", label: "Female" },
                          ]}
                          required={true}
                          invalidFeedback="The Sex field is required."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <NumberInput
                          label="AST ULN"
                          id="asT_ULN"
                          name="asT_ULN"
                          defaultValue={this.state.asT_ULN}
                          onChange={(v) => { this.handleOnChange("asT_ULN", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          min={0}
                          max={999}
                          required={true}
                          invalidFeedback="The field AST ULN must be numeric between 0 and 999."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <NumberInput
                          label="ALT ULN"
                          id="alT_ULN"
                          name="alT_ULN"
                          defaultValue={this.state.alT_ULN}
                          onChange={(v) => { this.handleOnChange("alT_ULN", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          min={0}
                          max={999}
                          required={true}
                          invalidFeedback="The field ALT ULN must be numeric between 0 and 999."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <NumberInput
                          label="ALP ULN"
                          id="alP_ULN"
                          name="alP_ULN"
                          defaultValue={this.state.alP_ULN}
                          onChange={(v) => { this.handleOnChange("alP_ULN", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          min={0}
                          max={9999}
                          required={true}
                          invalidFeedback="The field ALP ULN must be numeric between 0 and 9999."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <TextInput
                          label="Bili ULN"
                          id="bili_ULN"
                          name="bili_ULN"
                          defaultValue={this.state.bili_ULN}
                          onChange={(v) => { this.handleOnChange("bili_ULN", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          maxLength={4}
                          pattern="^(\d*\.?)?\d+$"
                          required={true}
                          invalidFeedback="The field Bili ULN must be decimal between 0 and 99."
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <Select
                          label="Institution"
                          id="institutionName"
                          name="institutionName"
                          defaultValue={this.state.institutionName}
                          onChange={(v) => { this.handleOnChange("institutionName", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          // options={[                                                        // CH-57
                          //   { value: "", label: "" },
                          //   { value: "Duke", label: "Duke" },
                          //   { value: "Mayo", label: "Mayo" },
                          //   { value: "Prisma Health", label: "Prisma Health" },
                          //   { value: "Northwell", label: "Northwell" },
                          //   { value: "UC Davis", label: "UC Davis" },
                          //   { value: "Case Western", label: "Case Western" },
                          //   { value: "Northwestern", label: "Northwestern" },
                          //   { value: "University of Colorado", label: "University of Colorado" },
                          //   { value: "UK HealthCare Hospital", label: "UK HealthCare Hospital" },
                          // ]}
                          options={this.props.selectionOptions.Institution_InstitutionName}
                          required={true}
                          invalidFeedback="The Institution field is required."
                        />
                      </div>

                      {/* CH-38 */}
                      {/* Pump Type */}
                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <Select
                          label="Pump Type"
                          id="pumpType"
                          name="pumpType"
                          defaultValue={this.state.pumpType}
                          onChange={(v) => { this.handleOnChange("pumpType", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          options={[
                            { value: "", label: "" },
                            { value: "Intera", label: "Intera" },
                            { value: "Medtronic", label: "Medtronic" },
                          ]}
                          required={true}
                          invalidFeedback="The Pump Type field is required."
                        />
                      </div>

                      {/* Pump Volume */}
                      {(this.state.pumpType === "Intera") && (
                        <div className="col-xl-3 col-lg-6 col-sm-6">
                          <Select
                            label="Pump Volume (ml)"
                            id="pumpVolume"
                            name="pumpVolume"
                            defaultValue={this.state.pumpVolume}
                            onChange={(v) => { this.handleOnChange("pumpVolume", v) }}
                            disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                            options={[
                              { value: "30", label: "30" },
                            ]}
                            required={true}
                            invalidFeedback="The Pump Volume Type field is required."
                          />
                        </div>
                      )}
                      {(this.state.pumpType === "Medtronic") && (
                        <div className="col-xl-3 col-lg-6 col-sm-6">
                          <Select
                            label="Pump Volume (ml)"
                            id="pumpVolume"
                            name="pumpVolume"
                            defaultValue={this.state.pumpVolume}
                            onChange={(v) => { this.handleOnChange("pumpVolume", v) }}
                            disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                            options={[
                              { value: "", label: "" },
                              { value: "20", label: "20" },
                              { value: "40", label: "40" },
                            ]}
                            required={true}
                            invalidFeedback="The Pump Volume Type field is required."
                          />
                        </div>
                      )}
                      {/* Flow Rate */}
                      {(this.state.pumpType === "Intera") && (
                        <div className="col-xl-3 col-lg-6 col-sm-6">
                          <TextInput
                            label="Flow Rate"
                            id="flowRate"
                            name="flowRate"
                            defaultValue={this.state.flowRate}
                            onChange={(v) => { this.handleOnChange("flowRate", v) }}
                            disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                            maxLength={3}
                            // number with 1 decimal between 1.2 and 1.3
                            pattern="^1.2$|^1.3$"
                            required={true}
                            invalidFeedback="Flow Rate must be a number with 1 decimal between 1.2 and 1.3"
                          />
                        </div>
                      )}
                      {(this.state.pumpType === "Medtronic") && (
                        <div className="col-xl-3 col-lg-6 col-sm-6">
                          <TextInput
                            label="Flow Rate"
                            id="flowRate"
                            name="flowRate"
                            defaultValue={this.state.flowRate}
                            onChange={(v) => { this.handleOnChange("flowRate", v) }}
                            disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                            maxLength={4}
                            // number with 1 decimal between 0.1 and 20
                            pattern="^(?!(0\.|0*(\.0+)?)$)((1?[0-9]{0,1})(\.[0-9]{0,1})?)$|^20$"
                            required={true}
                            invalidFeedback="Flow Rate must be a number with 1 decimal between 0.1 and 20"
                          />
                        </div>
                      )}

                      {/* CH-51 - ONCORE ID */}
                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <TextInput
                          label="ONCORE ID"
                          id="onCoreId"
                          name="onCoreId"
                          defaultValue={this.state.onCoreId}
                          onChange={(v) => { this.handleOnChange("onCoreId", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          maxLength={32}
                        />
                      </div>
                      {/* CH-51 - Test*/}
                      <div className="col-xl-3 col-lg-6 col-sm-6">
                        <Select
                          label="Test"
                          id="test"
                          name="test"
                          defaultValue={this.state.test}
                          onChange={(v) => { this.handleOnChange("test", v) }}
                          disabled={this.state.disabled || (this.props.action === "edit" && !this.props.user.isAdministrator)}	// CH-60
                          options={[
                            { value: "", label: "" },
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                          ]}
                        />
                      </div>

                    </div>

                    <div className="row">
                      <div className="col">
                        <br></br>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col pt-2 text-right col align-self-end">

                        {/* CH-60 */}
                        {(this.props.action === "add" || (this.props.action === "edit" && this.props.user.isAdministrator)) && (

                          <Button
                            type="submit"
                            text={"Save " + this.state.modalEntity}
                            loadingText={"Save " + this.state.modalEntity}
                            form="patientForm"
                            icon="bi-arrow-return-left"
                            variant="success"
                            classes="mr-2"
                            isLoading={this.state.isProcessing}
                            isDisabled={!this.state.isSubmitButtonEnabled}
                          />

                        )}

                        <span className="ml-3"></span>

                        <Button
                          type="button"
                          text="Cancel"
                          icon="bi-x-circle"
                          variant="secondary"
                          isOutline={true}
                          classes="mr-2"
                          onClick={this.handleCloseModal}
                        />

                      </div>
                    </div>

                  </form>
                )}

                {(this.props.action !== "add") && (
                  <div className="row">

                    <div className="col">
                      <Visits
                        onVisitsButtonClick={this.handleVisitsButtonClick}
                        onLastDateOfVisit={this.handleLastDateOfVisit}              // CH-68
                        ref={ref => (this.visitsRef = ref)}
                        user={this.props.user}
                        patient={patient}
                        isModal={false}
                        action={this.props.action}
                        parentComp={"PatientModal"}
                        modalEntity={"Visit"}
                      />
                    </div>

                  </div>
                )}

                {this.state.visitAddModalShow && (
                  <VisitModal
                    onHide={visitAddModalClose}
                    onSelectLast={this.visitsRef.handleSelectLast}
                    show={this.state.visitAddModalShow}
                    // selectOptions={this.props.selectOptions}
                    selectionOptions={this.props.selectionOptions}
                    user={this.props.user}
                    patient={patient}
                    lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                    disabled={false}
                    action={"add"}
                    parentComp={"PatientModal"}
                    modalEntity={"Visit"}
                  />
                )}
                {this.state.visitViewModalShow &&
                  this.state.selectedRecord !== null && (
                    <VisitModal
                      onHide={visitViewModalClose}
                      show={this.state.visitViewModalShow}
                      // selectOptions={this.props.selectOptions}
                      selectionOptions={this.props.selectionOptions}
                      user={this.props.user}
                      patient={patient}
                      lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                      visit={this.state.selectedRecord}
                      disabled={true}
                      action={"view"}
                      parentComp={"PatientModal"}
                      modalEntity={"Visit"}
                    />
                  )}
                {/* {this.state.visitApproveModalShow && */}
                {this.state.visitTOApprovalModalShow &&                         //  TO Approval (Tertiary Oncologist Approval)  -  CH-32
                  this.state.selectedRecord !== null && (
                    <VisitModal
                      onHide={visitTOApprovalModalClose}
                      onFetch={this.visitsRef.handleFetch}
                      show={this.state.visitTOApprovalModalShow}
                      // selectOptions={this.props.selectOptions}
                      selectionOptions={this.props.selectionOptions}
                      user={this.props.user}
                      patient={patient}
                      lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                      visit={this.state.selectedRecord}
                      disabled={true}
                      action={"to-approval"}                                    //  TO Approval (Tertiary Oncologist Approval)  -  CH-32
                      parentComp={"PatientModal"}
                      modalEntity={"Visit"}
                    />
                  )}
                {this.state.visitCOApprovalModalShow &&                         // CO Approval (Community Oncologist Approval) -  CH-32
                  this.state.selectedRecord !== null && (
                    <VisitModal
                      onHide={visitCOApprovalModalClose}
                      onFetch={this.visitsRef.handleFetch}
                      show={this.state.visitCOApprovalModalShow}
                      // selectOptions={this.props.selectOptions}
                      selectionOptions={this.props.selectionOptions}
                      user={this.props.user}
                      patient={patient}
                      lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                      visit={this.state.selectedRecord}
                      disabled={true}
                      action={"co-approval"}                                    // CO Approval (Community Oncologist Approval) -  CH-32
                      parentComp={"PatientModal"}
                      modalEntity={"Visit"}
                    />
                  )}
                {this.state.visitEditModalShow &&
                  this.state.selectedRecord !== null && (
                    <VisitModal
                      onHide={visitEditModalClose}
                      onFetch={this.visitsRef.handleFetch}
                      show={this.state.visitEditModalShow}
                      // selectOptions={this.props.selectOptions}
                      selectionOptions={this.props.selectionOptions}
                      patient={patient}
                      lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                      user={this.props.user}
                      visit={this.state.selectedRecord}
                      disabled={false}
                      action={"edit"}
                      parentComp={"PatientModal"}
                      modalEntity={"Visit"}
                    />
                  )}
              </>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PatientModal;