import React from "react";

class Reports extends React.Component {
  render() {
    return (
      <div className="container-fluid mt-2">
      <div className="row no-gutters">
        <div className="col">
          <h3 className="h6 d-none d-lg-block mt-2 mb-0">Reports</h3>
        </div>
      </div>
      <div className="row mt-2">
          <div className="col">
            <div class="card">
              <div class="card-body">
                <center className="text-muted">
                  <br />
                  <h5>Reports go here</h5>
                  <br />
                  <br />
                  <br />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Reports;
