import React from "react";
import $ from "jquery";

// @ukhc/devops-react-library
import Select from "@ukhc/devops-react-library/components/Select";           // CH-57-58
import Table from "@ukhc/devops-react-library/components/Table";
import Search from "@ukhc/devops-react-library/components/Search";
import Pagination from "@ukhc/devops-react-library/components/Pagination";
import ItemsPerPage from "@ukhc/devops-react-library/components/ItemsPerPage";

import DeleteConfirmationModal from "../Lib/DeleteConfirmationModal";
// App components
import PatientModal from "./PatientModal";

import Button from "../Lib/Button";
import { exportToExcel } from "../Lib/Helper";                                // CH-17 - Excel
import { logRequest, logSuccessResponse, logErrorResponse } from "../Lib/Logs";
import { toastError, toastSuccessDelete } from "../Lib/Toasts";

class Patients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      excelExportData: null,                                                  // CH-17 - Excel
      fetchResponseStatusCode: null,
      isLoading: true,
      isProcessingExcelExport: false,                                         // CH-17 - Excel

      isSearching: false,
      isTableDisabled: true,
      itemsPerPage: 10,
      itemsPerPageOptions: [5, 10, 25, 50, 100],

      records: [],
      recordsFilter: "",

      search: "",
      institutionNameFilter: null,        // CH-57
      testFilter: null,                   // CH-58

      selectedRecord: {},
      selectedRows: [],
      sortDescending: false,
      sortedBy: "id",
      totalPages: null,
      // totalResults: null,
      totalItems: null,
      uidProperty: "id",

      // ===========================
      patientAddModalShow: false,
      patientEditModalShow: false,
      patientViewModalShow: false,
      patientDashboardModalShow: false,         // CH-17
      deleteConfirmationModalShow: false,

      id: null,
      isHandleSelectLast: false,
      // ===========================
      isModal: this.props.isModal,
      modalEntity: this.props.modalEntity,
      // =============================================
      isProcessing: false,
    };
    this.controller = "patients";
    this.entity = "Patient";
    this.compClass = "Patients";

    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleDashboardButtonClick = this.handleDashboardButtonClick.bind(this);

    this.handleViewButtonClick = this.handleViewButtonClick.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteConfirmationModalClose = this.handleDeleteConfirmationModalClose.bind(this);

    this.handlePageBack = this.handlePageBack.bind(this);
    this.handlePageForward = this.handlePageForward.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSetPage = this.handleSetPage.bind(this);
    this.handleSetItemsPerPage = this.handleSetItemsPerPage.bind(this);
    this.handleSetRecordsFilter = this.handleSetRecordsFilter.bind(this);
    this.handleSetSelectedRows = this.handleSetSelectedRows.bind(this);
    this.handleSetSortedBy = this.handleSetSortedBy.bind(this);
    this.handleSetSortDescending = this.handleSetSortDescending.bind(this);

    this.handleSelectLast = this.handleSelectLast.bind(this);
    this.handleExcelExport = this.handleExcelExport.bind(this);                     // CH-17 - Excel
    this.handleFetch = this.handleFetch.bind(this);
    this.getDeleteMessage = this.getDeleteMessage.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);

    // Initial modal state
    // $(".modal-backdrop").remove();

    //Timer for when a query takes too long
    this.isLoadingTimeout = null;
  }

  // for the data refresh inter@val
  intervalID;


  handleCloseModal() {
    // Close the modal
    // document.getElementById("closePersonsModalButton").click();
    this.props.onHide();
  }

  componentDidMount() {
    this.handleFetch();
    // call handleFetch() every 30 minutes
    this.intervalID = setInterval(this.handleFetch, 1800000);
  }

  componentWillUnmount() {
    /*
            stop handleFetch() from continuing to run even
            after unmounting this component.
        */
    clearInterval(this.intervalID);
  }

  handleSelectLast(id) {
    let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller +
      // "?pageSize=" + this.state.itemsPerPage + "&pageNumber=1";
      "?pageSize=" + this.state.itemsPerPage + "&currentPage=1";

    // CH-57
    if (this.state.institutionNameFilter) {
      fetchUrl = fetchUrl + "&institutionName=" + this.state.institutionNameFilter;
    }
    // CH-58
    if (this.state.testFilter) {
      fetchUrl = fetchUrl + "&test=" + this.state.testFilter;
    }

    logRequest("GET", "handleSelectLast", this.entity, this.compClass, fetchUrl);

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
    })
      .then((response) => {
        // error handling
        if (response.ok) {
          logSuccessResponse("GET", "handleSelectLast", this.entity, this.compClass, response);
          // Read pagination from the header
          let pagination = JSON.parse(response.headers.get("x-pagination"));
          this.setState({
            // currentPage: Math.ceil(pagination.totalResults / this.state.itemsPerPage),
            currentPage: Math.ceil(pagination.totalItems / this.state.itemsPerPage),
            fetchResponseStatusCode: response.status,
            // totalPages: Math.ceil(pagination.totalResults / this.state.itemsPerPage),
            totalPages: Math.ceil(pagination.totalItems / this.state.itemsPerPage),
            // totalResults: pagination.totalResults,
            totalItems: pagination.totalItems,

            id: id,
            isSearching: true,
            search: "",
            isHandleSelectLast: true,
          },
            () => {
              this.searchRef.setState({ searchValue: "" });
              this.handleFetch();
            });
        } else {
          this.setState({ fetchResponseStatusCode: response.status });
          throw new Error(response.status);
        }
      })
      .catch((error) => {
        logErrorResponse("GET", "handleSelectLast", this.entity, this.compClass, error);
        // toastError();

        this.setState({
          isLoading: false,
          isSearching: false,
          isTableDisabled: false,
          isHandleSelectLast: false,
          records: [],
          totalPages: 0,
          // totalResults: 0,
          totalItems: 0,
        });
      });
  }

  // CH-17 - Excel - begin
  handleExcelExport() {
    var sheetTitle = "Patients     -";              // CH-57
    var fName = "Patients";
    if (this.state.search && this.state.search !== "") {
      sheetTitle = sheetTitle + "      Search:  " + this.state.search;
    }

    // CH-57
    if (this.state.institutionNameFilter) {
      sheetTitle = sheetTitle + "     Institution: " + this.state.institutionNameFilter;
      fName = fName + "-Institution-" + this.state.institutionNameFilter;
    }
    // CH-58
    if (this.state.testFilter) {
      sheetTitle = sheetTitle + "     Test: " + this.state.testFilter;
      fName = fName + "-Test-" + this.state.testFilter;
    }

    const filename = `${window.REACT_APP_NAME} - ${fName}.xlsx`;
    const workbookSheet = "Patients";

    var headers = [];
    headers = [[""], ["Patient ID", "First Name", "Last Name", "MRN", "DOB", "Date HAI Surgery", "Height", "Sex", "AST ULN", "ALT ULN", "ALP ULN", "Bili ULN", "Institution", "Pump Type", "Pump Volume", "Flow Rate", "Test"]];  // CH-57
    headers.unshift([sheetTitle]);

    const options = {
      // origin: "A2",
      origin: "A4",
      skipHeader: true,
      header: ["id", "firstName", "lastName", "mrn", "dobFormatted", "dateHAISurgeryFormatted", "height", "sex", "asT_ULN", "alT_ULN", "alP_ULN", "bili_ULN", "institutionName", "pumpType", "pumpVolume", "flowRate", "test"],   // CH-57
    };

    exportToExcel(this.state.excelExportData, filename, headers, options, workbookSheet)
  }
  // CH-17 - Excel - end

  handleFetch(isExportToExcel) {

    // CH-17 - Excel - begin
    let fetchUrl;
    if (!isExportToExcel) {
      this.setState({ isLoading: true });
      fetchUrl = window.REACT_APP_API_URL + "/" + this.controller +
        "?pageSize=" + this.state.itemsPerPage + "&currentPage=" + this.state.currentPage;
    } else {
      this.setState({ isProcessingExcelExport: true });
      fetchUrl = window.REACT_APP_API_URL + "/" + this.controller +
        "?pageSize=1000&currentPage=1";
    }
    // CH-17 - Excel - begin


    if (this.state.sortDescending) {
      // fetchUrl = fetchUrl + "&orderby=" + this.state.sortedBy + " desc";
      fetchUrl = fetchUrl + "&orderby=" + this.state.sortedBy + "&orderByDirection=desc";
    } else {
      fetchUrl = fetchUrl + "&orderby=" + this.state.sortedBy;
    }

    if (this.state.search) {
      fetchUrl = fetchUrl + "&search=" + this.state.search;
    }

    // CH-57
    if (this.state.institutionNameFilter) {
      fetchUrl = fetchUrl + "&institutionName=" + this.state.institutionNameFilter;
    }
    // CH-58
    if (this.state.testFilter) {
      fetchUrl = fetchUrl + "&test=" + this.state.testFilter;
    }

    logRequest("GET", "handleFetch", this.entity, this.compClass, fetchUrl);

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
      },
    })
      .then((response) => {
        // error handling
        if (response.ok) {
          // Read pagination from the header
          let pagination = JSON.parse(response.headers.get("x-pagination"));
          this.setState({
            currentPage: pagination.currentPage,
            fetchResponseStatusCode: response.status,
            totalPages: pagination.totalPages,
            // totalResults: pagination.totalResults,
            totalItems: pagination.totalItems,
          });
          return response.json();
        } else {
          this.setState({ fetchResponseStatusCode: response.status });
          throw new Error(response.status);
        }
      })
      .then((data) => {
        logSuccessResponse("GET", "handleFetch", this.entity, this.compClass, data);
        // for date formatting
        var moment = require("moment");
        // create formatted dob property
        data.forEach(function (element) {
          if (element.dob) {
            element.dobFormatted = moment(element.dob).format("l");
          } else {
            element.dobFormatted = "";
          }
          if (element.dateHAISurgery) {
            element.dateHAISurgeryFormatted = moment(element.dateHAISurgery).format("l");
          } else {
            element.dateHAISurgeryFormatted = "";
          }
        });

        // CH-17 - Excel - begin
        if (!isExportToExcel) {
          this.setState({
            isLoading: false,
            isSearching: false,
            isTableDisabled: false,
            records: data || [],
          });
          // Update the selected record
          if (this.state.selectedRows.length > 0 && data.length > 0) {
            let selectedRow = this.state.selectedRows[0];
            var selectedRecordValue = {};
            selectedRecordValue = data.filter(function (record) {
              return record.id === selectedRow.uid;
            })[0];
            this.setState({
              selectedRecord: selectedRecordValue,
            });
          }
          // Handle select last record
          if (this.state.isHandleSelectLast) {
            this.setState({
              isHandleSelectLast: false,
            },
              () => {
                if (this.state.id) {
                  this.handleSetSelectedRows([{ uid: this.state.id }]);
                }
              });
          }
        } else {
          var excelExportData = [];
          excelExportData = data.map((d) => {
            return Object.assign(
              { id: d.id },
              { firstName: d.firstName },
              { lastName: d.lastName },
              { mrn: d.mrn },
              { dobFormatted: d.dobFormatted },
              { dateHAISurgeryFormatted: d.dateHAISurgeryFormatted },
              { height: d.height },
              { sex: d.sex },
              { asT_ULN: d.asT_ULN },
              { alT_ULN: d.alT_ULN },
              { alP_ULN: d.alP_ULN },
              { bili_ULN: d.bili_ULN },
              { institutionName: d.institutionName },
              { pumpType: d.pumpType },
              { pumpVolume: d.pumpVolume },
              { flowRate: d.flowRate },
              { test: d.test },             // CH-57
            );
          });

          this.setState(
            {
              isLoading: false,
              isSearching: false,
              isTableDisabled: false,
              excelExportData: excelExportData || [],
            },
            () => {
              if (excelExportData && excelExportData.length > 0) {
                this.handleExcelExport();
                setTimeout(() => {
                  this.setState({ isProcessingExcelExport: false });
                }, 2000);
              } else {
                this.setState({ isProcessingExcelExport: false });
              }
            }
          )
        }
        // CH-17 - Excel - end

      })
      .catch((error) => {
        logErrorResponse("GET", "handleFetch", this.entity, this.compClass, error);
        toastError();

        this.setState({
          isProcessingExcelExport: false,     // CH-17 - Excel
          isLoading: false,
          isSearching: false,
          isTableDisabled: false,
          isHandleSelectLast: false,
          records: [],
          totalPages: 0,
          // totalResults: 0,
          totalItems: 0,
        });
      });
  }

  // CRUD Handle - begin
  handleAddButtonClick() {
    this.setState(
      {
        patientAddModalShow: true,
      },
      () => {
        $("#patientModal").modal("show");
      }
    );
  }

  handleEditButtonClick() {
    let selectedRows = this.state.selectedRows;
    let selectedRecord = this.state.records.filter(function (record) {
      return record.id === selectedRows[0].uid;
    })[0];
    this.setState(
      {
        patientEditModalShow: true,
        selectedRecord: selectedRecord,
      },
      () => {
        $("#patientModal").modal("show");
      }
    );
  }

  handleViewButtonClick() {
    let selectedRows = this.state.selectedRows;
    let selectedRecord = this.state.records.filter(function (record) {
      return record.id === selectedRows[0].uid;
    })[0];

    this.setState(
      {
        patientViewModalShow: true,
        selectedRecord: selectedRecord,
      },
      () => {
        $("#patientModal").modal("show");
      }
    );
  }

  // CH-17   - begin
  handleDashboardButtonClick() {
    let selectedRows = this.state.selectedRows;
    let selectedRecord = this.state.records.filter(function (record) {
      return record.id === selectedRows[0].uid;
    })[0];
    this.setState(
      {
        patientDashboardModalShow: true,
        selectedRecord: selectedRecord,
      },
      () => {
        $("#patientModal").modal("show");
      }
    );
  }
  // CH-17   - end
  // CRUD Handle - edit

  // Delete - begin
  handleDelete() {
    let deleteMessage = this.getDeleteMessage();

    if (this.state.selectedRecord && this.state.selectedRecord.id) {

      let id = this.state.selectedRecord.id;
      // let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "/" + id;    // CH-61
      let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "/hard/" + id;
      logRequest("DELETE", "handleDelete", this.entity, this.compClass, fetchUrl, this.state.selectedRecord);

      // Delete the record
      fetch(fetchUrl, {
        method: "DELETE",
        headers: {
          ACCEPT: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
        },
      })
        .then((response) => {
          // error handling
          if (response.ok) {
            return response;
          }
          throw new Error(response.status)
        })
        .then((data) => {
          //alert("Record Deleted Sucessfully");
          logSuccessResponse("DELETE", "handleDelete", this.entity, this.compClass, data);
          toastSuccessDelete(deleteMessage);
          return true;
        })
        .then(this.setState({ selectedRows: [], selectedRecord: {} }))
        // .then(this.handleFetch)
        .then(() => this.handleFetch(false))
        .catch((error) => {
          //alert("Error Deleting Record: " + error);
          logErrorResponse("DELETE", "handleDelete", this.entity, this.compClass, error);
          toastError();
          return false;
        });
    }
  }


  getDeleteMessage() {
    var moment = require("moment");
    return (
      <>
        {this.entity} # <span className="text-primary">{this.state.selectedRecord.id}</span>
        , First Name: <span className="text-primary">{this.state.selectedRecord.firstName}</span>
        , Last Name: <span className="text-primary">{this.state.selectedRecord.lastName}</span>
        , MRN: <span className="text-primary">{this.state.selectedRecord.mrn}</span>
        , DOB: <span className="text-primary">{moment(this.state.selectedRecord.dob).format("l")}</span>
      </>
    )
  }

  handleDeleteButtonClick() {
    let selectedRows = this.state.selectedRows;
    let selectedRecord = this.state.records.filter(function (record) {
      return record.id === selectedRows[0].uid;
    })[0];

    this.setState(
      {
        deleteConfirmationModalShow: true,
        selectedRecord: selectedRecord,
      },
      () => {
        $("#deleteConfirmationModal").modal("show");
      }
    );
  }

  handleDeleteConfirmationModalClose() {
    this.setState({ deleteConfirmationModalShow: false }, () => { });
  }
  // Delete - end


  handleSetSortedBy(field) {
    // console.log(field);
    this.setState(
      {
        currentPage: 1,
        isTableDisabled: true,
        sortedBy: field,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetSortDescending(boolValue) {
    this.setState(
      {
        currentPage: 1,
        isTableDisabled: true,
        sortDescending: boolValue,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handlePageForward() {
    var newPage;
    if (this.state.currentPage >= this.state.totalPages) {
      newPage = this.state.totalPages;
    } else {
      newPage = this.state.currentPage + 1;
    }
    this.setState(
      {
        currentPage: newPage,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handlePageBack() {
    var newPage;
    if (this.state.currentPage < 2) {
      newPage = 1;
    } else {
      newPage = this.state.currentPage - 1;
    }
    this.setState(
      {
        currentPage: newPage,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetPage(newPage) {
    this.setState(
      {
        currentPage: newPage,
        selectedRecord: {},
        selectedRows: [],
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetItemsPerPage(newLimit) {
    this.setState(
      {
        itemsPerPage: newLimit,
        currentPage: 1,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSearch(newSearch) {
    this.setState(
      {
        isSearching: true,
        search: newSearch,
        currentPage: 1,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  // CH-57-58
  handleFilter(name, value) {
    let isValidValue = true;
    if (isValidValue) {
      this.setState(
        {
          isSearching: true,
          [name]: value,
          currentPage: 1,
        },
        () => {
          this.handleFetch();
        }
      );
    }
  }

  handleSetRecordsFilter(newFilter) {
    this.setState(
      {
        recordsFilter: newFilter,
        currentPage: 1,
      },
      () => {
        this.handleFetch();
      }
    );
  }

  handleSetSelectedRows(rows) {
    if (rows) {
      var selectedRecord = {};
      if (rows.length > 0) {
        selectedRecord = this.state.records.filter(function (record) {
          return record.id === rows[0].uid;
        })[0];
      }
      if (window.REACT_APP_DEBUG)
        console.log("handleSetSelectedRows:" + selectedRecord);
      this.setState({
        selectedRows: rows,
        selectedRecord: selectedRecord,
      });
    } else {
      this.setState({
        selectedRows: [],
        selectedRecord: {},
      });
    }
  }

  handleOnChange = (name, value) => {
    // console.log("name: " + name + " value: " + value);
    // this.props.onUpdateForm(name, value);
    this.setState({ [name]: value });
  }

  render() {
    let patientAddModalClose = () =>
      this.setState({ patientAddModalShow: false }, () => {
        // this.handleFetch();
      });
    let patientEditModalClose = () =>
      this.setState({ patientEditModalShow: false }, () => {
        // this.handleFetch();
      });
    let patientViewModalClose = () =>
      this.setState({ patientViewModalShow: false }, () => {
        // this.handleFetch();
      });
    // CH-17   - begin
    let patientDashboardModalClose = () =>
      this.setState({ patientDashboardModalShow: false }, () => {
        // this.handleFetch();
      });
    // CH-17   - end

    // get the selected records into an array
    var selectedRecords = [];
    for (const row of this.state.selectedRows) {
      let rowToAdd = this.state.records.filter(
        (record) => record[this.state.uidProperty] === row.uid
      )[0];
      if (rowToAdd) {
        selectedRecords.push(rowToAdd);
      }
    }

    var resultsCountString = "";
    // if (this.state.totalResults > 0) {
    if (this.state.totalItems > 0) {
      resultsCountString =
        this.state.currentPage * this.state.itemsPerPage -
        (this.state.itemsPerPage - 1) +
        "-" +
        (this.state.currentPage * this.state.itemsPerPage -
          this.state.itemsPerPage +
          this.state.records.length) +
        " of " +
        // this.state.totalResults;
        this.state.totalItems;
    } else {
      resultsCountString = "0 items";
    }


    var mainBody = <>

      {/* CH-57-58 */}
      <div className="row pb-2">

        <div className="col-md-4">
          {!this.state.isTableDemoOnly && (
            <>
              <br />
              <Search
                isSearching={this.state.isSearching}
                onChange={this.handleSearch}
                ref={ref => (this.searchRef = ref)}
              />
            </>
          )}
        </div>

        {/* CH-57 */}
        {this.props.selectionOptions && this.props.selectionOptions.Institution_InstitutionName && (
          <div className="col-md-4">
            <Select
              label="Institution"
              id="institutionNameFilter"
              name="institutionNameFilter"
              defaultValue={this.state.institutionNameFilter}
              onChange={(v) => { this.handleFilter("institutionNameFilter", v) }}
              // options={[
              //   { value: "", label: "" },
              //   { value: "Duke", label: "Duke" },
              //   { value: "Mayo", label: "Mayo" },
              //   { value: "Prisma Health", label: "Prisma Health" },
              //   { value: "Northwell", label: "Northwell" },
              //   { value: "UC Davis", label: "UC Davis" },
              //   { value: "Case Western", label: "Case Western" },
              //   { value: "Northwestern", label: "Northwestern" },
              //   { value: "University of Colorado", label: "University of Colorado" },
              //   { value: "UK HealthCare Hospital", label: "UK HealthCare Hospital" },
              // ]}
              // options={this.props.selectionOptions.Institution_InstitutionName}
              options={[{ value: "", label: "" }, ...this.props.selectionOptions.Institution_InstitutionName]}
            />
          </div>
        )}
        {/* CH-58 */}
        <div className="col-md-4">
          <Select
            label="Test"
            id="testFilter"
            name="testFilter"
            defaultValue={this.state.testFilter}
            onChange={(v) => { this.handleFilter("testFilter", v) }}
            options={[
              { value: "", label: "" },
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
          />
        </div>
      </div>

      <div className="row">

        {/* CH-57-58 */}
        {/* <div className="col-md-4">
          {!this.state.isTableDemoOnly && (
            <Search
              isSearching={this.state.isSearching}
              onChange={this.handleSearch}
              ref={ref => (this.searchRef = ref)}
            />
          )}
        </div> */}

        {/* CH-57-58 */}
        {/* <div className="col-md-8 text-right pr-2"> */}
        <div className="col text-right pr-2">
          <div className="d-inline-flex">

            {/*
            // =========================================================

            // =========================================================
            */}
            {this.state.selectedRows.length !== 1 &&
              (this.props.user.isUser || this.props.user.isAdministrator) && (
                <button
                  type="button"
                  id="toolbarAddButton"
                  className="btn btn-link btn-sm"
                  onClick={this.handleAddButtonClick}
                  style={{ fontSize: 13, color: "#0067d5" }}
                >
                  <i className="bi bi-person-plus"></i> Add
                </button>
              )}

            {this.state.selectedRows.length === 1 && (
              <button
                type="button"
                id="toolbarViewButton"
                className="btn btn-link btn-sm mr-2"
                onClick={this.handleViewButtonClick}
                style={{ fontSize: 13, color: "#0067d5" }}
              >
                <i className="bi bi-card-list"></i> View
              </button>
            )}

            {/*
            // =========================================================

            // =========================================================
            */}
            {this.state.selectedRows.length === 1 &&
              (this.props.user.isUser || this.props.user.isAdministrator) && (
                <button
                  type="button"
                  id="toolbarEditButton"
                  className="btn btn-link btn-sm mr-2"
                  onClick={this.handleEditButtonClick}
                  style={{ fontSize: 13, color: "#0067d5" }}
                >
                  <i className="bi bi-pencil"></i> Edit + Visits
                </button>
              )}

            {/*
            // =========================================================

            // =========================================================
            */}
            {/* CH-17     - begin */}
            {this.state.selectedRows.length === 1 &&
              (this.props.user.isUser || this.props.user.isAdministrator) && (
                <button
                  type="button"
                  id="toolbarDashboardButton"
                  className="btn btn-link btn-sm mr-2"
                  onClick={this.handleDashboardButtonClick}
                  style={{ fontSize: 13, color: "#0067d5" }}
                >
                  <i className="bi bi-clipboard-data"></i> Dashboard View
                </button>
              )}
            {/* CH-17     - end */}

            {/*
            // =========================================================
            // CH-61
            // =========================================================
            */}
            {this.state.selectedRows.length === 1 &&
              (this.props.user.isAdministrator) &&
              (this.state.selectedRecord.test === "Yes") && (
                <button
                  type="button"
                  id="toolbarDeleteButton"
                  className="btn btn-link btn-sm mr-2"
                  onClick={this.handleDeleteButtonClick}
                  style={{ fontSize: 13, color: "#0067d5" }}
                >
                  <i className="bi bi-trash"></i> Delete
                </button>
              )}

            {(this.props.user.isUser || this.props.user.isAdministrator) && (
              <Button
                type="button"
                text={"Export to Excel"}
                loadingText={"Export to Excel"}
                icon="bi-file-earmark-excel-fill"
                size="small"
                variant="link"
                classes="mr-2"
                onClick={() => { this.handleFetch(true) }}              // CH-17 - Excel
                isLoading={this.state.isProcessingExcelExport}
              />
            )}

          </div>
        </div>

      </div>
      <div className="row pt-2">
        {/* <div className="col overflow-auto table-overflow"
          style={{                               
            // height: `calc(100vh - 355px)`,
            height: `calc(100vh - 300px)`,
          }}
        > */}
        {/* CH-57-58  */}
        <div className="col overflow-auto">
          <Table
            checkboxSelectOnly={false}
            columns={tableColumns}
            disabled={this.state.isTableDisabled}
            handleSetSelectedRows={this.handleSetSelectedRows}
            handleSetSortedBy={this.handleSetSortedBy}
            handleSetSortDescending={this.handleSetSortDescending}
            isBordered={false}
            isLoading={this.state.isLoading}
            isMultiSelect={false}
            isSmall={true}
            records={this.state.records}
            responseStatusCode={this.state.fetchResponseStatusCode}
            selectedRows={this.state.selectedRows}
            sortedBy={this.state.sortedBy}
            sortDescending={this.state.sortDescending}
            uidProperty={this.state.uidProperty}
            useHoverableRows={true}
            useStripedRows={false}
          />
        </div>
      </div>
      <hr className="my-0 py-0" />
      <div className="row no-gutters">
        {/* <div className="col mt-1"> */}
        <div className="col-6 col-sm-4 mt-1">
          <ItemsPerPage
            disabled={
              this.state.isTableDisabled ||
              this.state.fetchResponseStatusCode > 399
            }
            onChange={this.handleSetItemsPerPage}
            options={this.state.itemsPerPageOptions}
            value={this.state.itemsPerPage}
          />
        </div>
        {/* <div className="col align-middle"> */}
        <div className="col-6 col-sm-4 d-flex justify-content-end justify-content-sm-center">
          <div>
            <center>
              <div className="mt-2 text-muted">
                {resultsCountString}
              </div>
            </center>
          </div>
        </div>
        {/* <div className="col d-flex justify-content-end py-1"> */}
        <div className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-end">
          <Pagination
            currentPage={this.state.currentPage}
            disabled={
              this.state.isTableDisabled ||
              this.state.fetchResponseStatusCode > 399
            }
            onChange={this.handleSetPage}
            totalPages={Math.ceil(
              // this.state.totalResults / this.state.itemsPerPage
              this.state.totalItems / this.state.itemsPerPage
            )}
          />
        </div>
      </div>
    </>

    return (
      <>

        {!this.state.isModal ? (
          <>
            <div className="container-fluid mt-2">
              {/* <div className="row no-gutters mb-2 d-block d-sm-none d-md-none d-lg-block d-xl-block">
                <div className="col mt-2">
                  <h4 className="d-none d-sm-block">Patients</h4>
                  <h6 className="d-block d-sm-none">Patients</h6>
                </div>
              </div> */}
              {/* CH-57-58 */}
              <div className="row no-gutters">
                <div className="col">
                  <h3 className="h6 d-none d-lg-block mt-2 mb-0">Patients</h3>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <div className="card shadow">
                    <div className="card-body m-0 p-2">
                      {mainBody}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="modal fade"
              id="patientIndexModal"
              role="dialog"
              aria-labelledby="patientIndexModalLabel"
              aria-hidden="true"
              data-backdrop="static"
            >
              <div className="modal-dialog modal-dialog-centered" role="document" style={{ minWidth: "90%" }}>
                <div className="modal-content" style={{ minHeight: "35vh" }} >
                  <div className="modal-header card-header py-2 my-2">

                    <h5 className="modal-title" id="patientIndexModalLabel">
                      {(this.state.modalEntity !== "Patient") && (
                        <>
                          <i className="bi bi-person mr-2"></i> Find, Choose, Add, View, Edit, or Delete {this.state.modalEntity}
                        </>
                      )}
                    </h5>

                    <button
                      type="button"
                      id="closePersonsModalButton"
                      className="close"
                      onClick={this.handleCloseModal}
                      aria-label="Close"
                      aria-hidden="true"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>

                  </div>
                  <div className="modal-body">
                    {mainBody}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {this.state.patientAddModalShow && (
          <PatientModal
            onHide={patientAddModalClose}
            onSelectLast={this.handleSelectLast}
            show={this.state.patientAddModalShow}
            // selectOptions={this.props.selectOptions}
            selectionOptions={this.props.selectionOptions}
            user={this.props.user}
            disabled={false}
            action={"add"}
            modalEntity={this.state.modalEntity}
          />
        )}
        {this.state.patientViewModalShow &&
          this.state.selectedRecord !== null && (
            <PatientModal
              onHide={patientViewModalClose}
              show={this.state.patientViewModalShow}
              // selectOptions={this.props.selectOptions}
              selectionOptions={this.props.selectionOptions}
              user={this.props.user}
              patient={this.state.selectedRecord}
              disabled={true}
              action={"view"}
              modalEntity={this.state.modalEntity}
            />
          )}
        {this.state.patientEditModalShow &&
          this.state.selectedRecord !== null && (
            <PatientModal
              onHide={patientEditModalClose}
              onFetch={this.handleFetch}
              show={this.state.patientEditModalShow}
              // selectOptions={this.props.selectOptions}
              selectionOptions={this.props.selectionOptions}
              user={this.props.user}
              patient={this.state.selectedRecord}
              disabled={false}
              action={"edit"}
              modalEntity={this.state.modalEntity}
            />
          )}
        {/* CH-17    - begin */}
        {this.state.patientDashboardModalShow &&
          this.state.selectedRecord !== null && (
            <PatientModal
              onHide={patientDashboardModalClose}
              onFetch={this.handleFetch}
              show={this.state.patientDashboardModalShow}
              // selectOptions={this.props.selectOptions}
              selectionOptions={this.props.selectionOptions}
              user={this.props.user}
              patient={this.state.selectedRecord}
              disabled={false}
              action={"dashboard"}
              modalEntity={this.state.modalEntity}
            />
          )}
        {/* CH-17    - end */}
        {this.state.deleteConfirmationModalShow && this.state.selectedRecord !== null && (
          <DeleteConfirmationModal
            handleDelete={this.handleDelete}
            onHide={this.handleDeleteConfirmationModalClose}
            selectedRecords={[this.state.selectedRecord]}
            confirmationText={this.getDeleteMessage()}
          />
        )}
      </>
    );

  }
}


let tableColumns = [
  {
    name: "Patient ID",
    property: "id",
    align: "center",
    sortable: true,
  },
  {
    name: "First Name",
    property: "firstName",
    sortable: true,
  },
  {
    name: "Last Name",
    property: "lastName",
    sortable: true,
  },
  {
    name: "MRN",
    property: "mrn",
    sortable: true,
  },
  {
    name: "DOB",
    property: "dobFormatted",
    sortable: true,
    sortableProperty: "dob",
  },
  // CH-51
  {
    name: "ONCORE ID",
    property: "onCoreId",
    sortable: true,
  },
  // CH-55
  {
    name: "Test",
    property: "test",
    sortable: true,
  },

  // CH-57
  {
    name: "Institution",
    property: "institutionName",
    sortable: true,
  },
  /*
  {
    name: "Date HAI Surgery",
    property: "dateHAISurgeryFormatted",
    sortable: true,
    sortableProperty: "dateHAISurgery",
  },
  {
    name: "Height",
    property: "height",
    sortable: true,
  },
  {
    name: "Sex",
    property: "sex",
    sortable: true,
  },
  // {
  //   name: "Result",
  //   property: "result",
  //   sortable: true,
  // },
  // {
  //   name: "Status",
  //   property: "status",
  //   sortable: true,
  // },
  // {
  //   name: "Comment",
  //   property: "comment",
  //   sortable: true,
  // },
  {
    name: "AST ULN",
    property: "asT_ULN",
    sortable: true,
  },
  {
    name: "ALP ULN",
    property: "alP_ULN",
    sortable: true,
  },
  {
    name: "Bili ULN",
    property: "bili_ULN",
    sortable: true,
  },
  */

];

export default Patients;
