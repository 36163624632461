/// DeleteConfirmationModal.js
///
/// - Props:
///   - handleDelete: A function to launch the delete operation
///   - onHide: A function to take the modal out of the dom
///   - selectedRecords: An array of selected records
///
/// - Example usage:
///     <DeleteConfirmationModal
///       handleDelete={this.props.handleDelete}
///       onHide={this.handleDeleteConfirmationModalClose}
///       selectedRecords={this.props.selectedRecords}
///     />

import React, { Component } from "react";
import PropTypes from "prop-types";
import "bootstrap-icons/font/bootstrap-icons.css";

export default class DeleteConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleDeleteButtonClick() {
    if (this.props.handleDelete) {
      // delete stuff with the prop func
    //   this.props.handleDelete(this.props.selectedRecords);
      this.props.handleDelete();
    }
    // Close the modal
    // document.getElementById("deleteCancelButton").click();
    this.props.onHide();
  }

  handleCloseModal() {
    // Close the modal
    // document.getElementById("closeAddModalButton").click();
    this.props.onHide();
  }

  render() {
    return (
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        role="dialog"
        aria-labelledby="deleteConfirmationModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="mt-3 text-center">
                <p className="h2 mb-2">
                  <i className="bi bi-trash text-danger"></i>
                </p>
                <h6
                  id="deleteConfirmationModalLabel"
                  className="font-weight-bold mb-4"
                >
                  {/* Delete {this.props.selectedRecords.length}{" "}
                  {this.props.selectedRecords.length === 1
                    ? "record"
                    : "records"}
                  ? */}
                  Delete {this.props.confirmationText} record?
                </h6>
                <div className="mb-1">
                  <p className="mb-1">
                    <strong>This action cannot be undone</strong>
                  </p>
                  <p id="deleteRecordsMessage">
                    This will permanently delete the selected{" "}
                    {this.props.selectedRecords.length === 1
                      ? "record"
                      : "records"}
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="deleteConfirmationButton"
                className="btn btn-danger mr-auto"
                disabled={this.props.handleDelete ? false : true}
                onClick={this.handleDeleteButtonClick}
                style={{ fontSize: 13 }}
                tabIndex={0}
              >
                Delete
              </button>
              <button
                type="button"
                id="deleteCancelButton"
                className="btn btn-outline-secondary"
                // data-dismiss="modal"
                onClick={this.handleCloseModal}
                style={{ fontSize: 13 }}
                tabIndex={0}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Props validation and defaults
DeleteConfirmationModal.propTypes = {
  handleDelete: PropTypes.func,
  selectedRecords: PropTypes.array,
};

DeleteConfirmationModal.defaultProps = {
  selectedRecords: [],
};
