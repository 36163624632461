import React, { Component } from "react";
import $ from "jquery";

import Select from "@ukhc/devops-react-library/components/Select";
import TextInput from "@ukhc/devops-react-library/components/TextInput";
import TextArea from "@ukhc/devops-react-library/components/TextArea";
import DateInput from "@ukhc/devops-react-library/components/DateInput";
import NumberInput from "@ukhc/devops-react-library/components/NumberInput";

import Button from "../Lib/Button";
import { getPatchArray } from "../Lib/Helper";
import { addDays } from "../Lib/Helper";
import { logRequest, logSuccessResponse, logErrorResponse, logDataErrorResponse } from "../Lib/Logs";
import { toastSuccessCreate, toastSuccessUpdate, toastError, toastDataError } from "../Lib/Toasts";

export class VisitModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            props: null,

            id: null,

            patientId: null,

            // ==================================================

            treatment: null,
            dateOfVisit: null,
            treatmentHoliday: null,     // CH-68
            weight: null,
            wbc: null,                  // WBC (10^3/ul)	        Decimal 5, 2	    0-999	White Blood Cell Count      -   CH-26
            hb: null,                   // Hb (g/dL)	            Decimal 4, 1	    0-999	Hemoglobin                  -   CH-26
            plt: null,                  // PLT (10^3/ul)	        Decimal 4	        0-9999	Platelets                   -   CH-26

            anc: null,                  // ANC (10^3/ul)	        Decimal 5, 2	    0-999	Absolute Neutrophil Count   -   CH-26
            ast: null,                  // AST	                    Decimal 3	        0-999   AST	                        -   before 26
            alt: null,                  // ALT (U/L)	            Decimal 4	        0-9999	Alanine Transaminase        -   CH-26
            alp: null,                  // ALP                      Decimal 4	        0-9999  Alk Phos	                -   before 26
            bili: null,                 // Bili	                    Decimal 3, 1	    0-99    Bilirubin                   -   before 26
            albumin: null,              // Albumin (g/dL)	        Decimal 3, 1	    0-99	Albumin                     -   CH-26

            creatinine: null,           // Creatinine (mg/dL)	    Decimal 4, 2	    0-99	Creatinine                  -   CH-26
            ldh: null,                  // LDH (U/L)	            Decimal 5	        0-9999	Lactate Dehydrogrenase      -   CH-26
            cea: null,                  // CEA (ng/mL)	            Decimal 4, 1	    0-999	Carcinoembryonic Antigen    -   CH-26
            cA19_9: null,               // CA19-9 (U/mL)	        Decimal 4, 1	    0-999	Carbohydrate antigen 19-9   -   CH-26
            comments: null,

            // ==================================================

            // calc fields
            maximumDosePercentage: null,
            todaysDose: null,
            todaysDosePercentage: null,
            todaysDoseStatus: null,
            idealBodyWeight: null,
            idealAverageWeight: null,

            correctDosingBodyWeight: null,
            fullDose: null,
            reference_AST: null,
            reference_ALP: null,
            reference_Bili: null,
            comparator_AST: null,

            comparator_ALP: null,
            comparator_Bili: null,
            flowRateLabTime: null,                      // Flow Rate At Lab Time
            systemicChemotherapyStatus: null,           // Systemic Chemotherapy Status
            approved: null,                             // TO Approved

            toApprover: null,                           // TO Approver
            toApprovedOn: null,                         // TO Approved On 
            // ==================================================

            fluorouracil: null,                         // 5-Fluorouracil	            0 - 2400 mg/m2      - CH-31
            fluorouracilHold: null,                     // 5-Fluorouracil Hold                              - CH-31    
            fluorouracilComment: null,                  // 5-Fluorouracil Hold Comment                      - CH-31

            leucovorin: null,                           // Leucovorin	                0 - 200 mg/m2       - CH-31 
            leucovorinHold: null,                       // Leucovorin Hold                                  - CH-31
            leucovorinComment: null,                    // Leucovorin Hold Comment                          - CH-31
            // =======
            oxaliplatin: null,                          // Oxaliplatin	                0 - 85 mg/m2        - CH-31
            oxaliplatinHold: null,                      // Oxaliplatin Hold                                 - CH-31   
            oxaliplatinComment: null,                   // Oxaliplatin Hold Comment                         - CH-31 

            irinotecan: null,                           // Irinotecan	                0 - 180 mg/m2       - CH-31
            irinotecanHold: null,                       // Irinotecan Hold                                  - CH-31    
            irinotecanComment: null,                    // Irinotecan Hold Comment                          - CH-31
            // =======
            gemcitabine: null,                          // Gemcitabine	                0 - 1000 mg/m2      - CH-31
            gemcitabineHold: null,                      // Gemcitabine Hold                                 - CH-31   
            gemcitabineComment: null,                   // Gemcitabine Hold Comment                         - CH-31

            panitumumab: null,                          // Panitumumab	                0 - 6 mg/kg         - CH-31
            panitumumabHold: null,                      // Panitumumab Hold                                 - CH-31   
            panitumumabComment: null,                   // Panitumumab Hold Comment                         - CH-31

            capecitabine: null,                         // Capecitabine	                 0-2500  (mg/m2/d)   - CH-52
            capecitabineHold: null,                     // Capecitabine Hold                                 - CH-52   
            capecitabineComment: null,                  // Capecitabine Hold Comment                         - CH-52
            capecitabineDosingSchedule: null,           // Capecitabine Dosing Schedule                      - CH-52


            // ==================================================
            fudrHold: null,                             // FUDR Hold                                        - CH-33   
            fudrComment: null,                          // FUDR Hold Comment                                - CH-33

            holdReleaseDate: null,                      // Hold Release Date                                - CH-44
            hepNSFill: null,                            // Hep/NS Fill                                      - CH-43

            pumpResidualVolume: null,                   // Pump Residual Volume     Decimal 3, 1	 0-40   - CH-40
            // ==================================================

            flowRateAtChemoTime: null,                  // Flow Rate At Chemo Time  - Calculated/Displayed  - CH-31
            pumpResidualDaysSinceLastFill: null,        // PR Days Since Last Fill  - Calculated/Displayed  - CH-31
            pumpResidualVolumeMin: null,                // PR Volume Min  - Calculated/Displayed            - CH-31
            pumpResidualVolumeMax: null,                // PR Volume Max  - Calculated/Displayed            - CH-31

            coCompleted: null,                          // CO Completed                                     - CH-42

            coApprover: null,                           // CO Approver
            coApprovedOn: null,                         // CO Approved On

            residualVolumeOutOfRange: null,             // PumpResidualVolume field shows as red color

            // =============================================
            disabled: this.props.disabled,
            isFamilyMember: false,

            isModal: this.props.isModal,
            action: this.props.action,
            modalEntity: this.props.modalEntity,

            lastDateOfVisit: this.props.lastDateOfVisit,     // CH-68
            allowTreatmentHoliday: false,                    // CH-68

            isProcessing: false,
            isSubmitButtonEnabled: false,
        };
        this.controller = "visits";
        this.entity = "Visit";
        this.compClass = "VisitModal";

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnChangeCB = this.handleOnChangeCB.bind(this);

        this.handleSelectLast = this.handleSelectLast.bind(this);
        this.handleFetch = this.handleFetch.bind(this);
        this.getSuccessMessage = this.getSuccessMessage.bind(this);

        this.isAllowTreatmentHoliday = this.isAllowTreatmentHoliday.bind(this);     // CH-68
    }

    static getDerivedStateFromProps(props, state) {
        if (state.action !== "add" && state.id !== props.visit.id) {
            return {
                props: props.visit,

                id: props.visit.id,

                // patientId: props.visit.patientId
                // ==================================================

                treatment: props.visit.treatment,
                dateOfVisit: props.visit.dateOfVisit,
                treatmentHoliday: props.visit.treatmentHoliday,     // CH-68
                weight: props.visit.weight,
                wbc: props.visit.wbc,                      // WBC (10^3/ul)	        Decimal 5, 2	    0-999	White Blood Cell Count      -   CH-26
                hb: props.visit.hb,                        // Hb (g/dL)	            Decimal 4, 1	    0-999	Hemoglobin                  -   CH-26
                plt: props.visit.plt,                      // PLT (10^3/ul)	        Decimal 4	        0-9999	Platelets                   -   CH-26

                anc: props.visit.anc,                      // ANC (10^3/ul)	        Decimal 5, 2	    0-999	Absolute Neutrophil Count   -   CH-26
                ast: props.visit.ast,                      // AST	                Decimal 3	        0-999   AST	                        -   before 26
                alt: props.visit.alt,                      // ALT (U/L)	            Decimal 4	        0-9999	Alanine Transaminase        -   CH-26
                alp: props.visit.alp,                      // ALP                   Decimal 4	        0-9999  Alk Phos	                -   before 26
                bili: props.visit.bili,                    // Bili	                Decimal 3, 1	    0-99    Bilirubin                   -   before 26
                albumin: props.visit.albumin,              // Albumin (g/dL)	    Decimal 3, 1	    0-99	Albumin                     -   CH-26

                creatinine: props.visit.creatinine,        // Creatinine (mg/dL)	Decimal 4, 2	    0-99	Creatinine                  -   CH-26
                ldh: props.visit.ldh,                      // LDH (U/L)	            Decimal 5	        0-9999	Lactate Dehydrogrenase      -   CH-26
                cea: props.visit.cea,                      // CEA (ng/mL)	        Decimal 4, 1	    0-999	Carcinoembryonic Antigen    -   CH-26
                cA19_9: props.visit.cA19_9,                // CA19-9 (U/mL)	        Decimal 4, 1	    0-999	Carbohydrate antigen 19-9   -   CH-26

                comments: props.visit.comments,
                // ==================================================

                // calc fields
                maximumDosePercentage: props.visit.maximumDosePercentage,
                todaysDose: props.visit.todaysDose,
                todaysDosePercentage: props.visit.todaysDosePercentage,
                todaysDoseStatus: props.visit.todaysDoseStatus,
                idealBodyWeight: props.visit.idealBodyWeight,
                idealAverageWeight: props.visit.idealAverageWeight,

                correctDosingBodyWeight: props.visit.correctDosingBodyWeight,
                fullDose: props.visit.fullDose,
                reference_AST: props.visit.reference_AST,
                reference_ALP: props.visit.reference_ALP,
                reference_Bili: props.visit.reference_Bili,
                comparator_AST: props.visit.comparator_AST,

                comparator_ALP: props.visit.comparator_ALP,
                comparator_Bili: props.visit.comparator_Bili,
                flowRateLabTime: props.visit.flowRateLabTime,                           // Flow Rate At Lab Time
                systemicChemotherapyStatus: props.visit.systemicChemotherapyStatus,     // Systemic Chemotherapy Status
                approved: props.visit.approved,                                         // TO Approved

                toApprover: props.visit.toApprover,                         // TO Approver
                toApprovedOn: props.visit.toApprovedOn,                     // TO Approved On 

                // ==================================================

                fluorouracil: props.visit.fluorouracil,                     // 5-Fluorouracil	            0 - 2400 mg/m2      - CH-31
                fluorouracilHold: props.visit.fluorouracilHold,             // 5-Fluorouracil Hold                              - CH-31   
                fluorouracilComment: props.visit.fluorouracilComment,       // 5-Fluorouracil Hold Comment                      - CH-31

                leucovorin: props.visit.leucovorin,                         // Leucovorin	                0 - 200 mg/m2       - CH-31
                leucovorinHold: props.visit.leucovorinHold,                 // Leucovorin Hold                                  - CH-31   
                leucovorinComment: props.visit.leucovorinComment,           // Leucovorin Hold Comment                          - CH-31

                oxaliplatin: props.visit.oxaliplatin,                       // Oxaliplatin	                0 - 85 mg/m2        - CH-31
                oxaliplatinHold: props.visit.oxaliplatinHold,               // Oxaliplatin Hold                                 - CH-31   
                oxaliplatinComment: props.visit.oxaliplatinComment,         // Oxaliplatin Hold Comment                         - CH-31 

                irinotecan: props.visit.irinotecan,                         // Irinotecan	                0 - 180 mg/m2       - CH-31
                irinotecanHold: props.visit.irinotecanHold,                 // Irinotecan Hold                                  - CH-31   
                irinotecanComment: props.visit.irinotecanComment,           // Irinotecan Hold Comment                          - CH-31

                gemcitabine: props.visit.gemcitabine,                       // Gemcitabine	                0 - 1000 mg/m2      - CH-31
                gemcitabineHold: props.visit.gemcitabineHold,               // Gemcitabine Hold                                 - CH-31   
                gemcitabineComment: props.visit.gemcitabineComment,         // Gemcitabine Hold Comment                         - CH-31

                panitumumab: props.visit.panitumumab,                       // Panitumumab	                0 - 6 mg/kg         - CH-31
                panitumumabHold: props.visit.panitumumabHold,               // Panitumumab Hold                                 - CH-31    
                panitumumabComment: props.visit.panitumumabComment,         // Panitumumab Hold Comment                         - CH-31

                capecitabine: props.visit.capecitabine,                             // Capecitabine	                 0-2500  (mg/m2/d)   - CH-52
                capecitabineHold: props.visit.capecitabineHold,                     // Capecitabine Hold                                 - CH-52   
                capecitabineComment: props.visit.capecitabineComment,               // Capecitabine Hold Comment                         - CH-52
                capecitabineDosingSchedule: props.visit.capecitabineDosingSchedule, // Capecitabine Dosing Schedule                      - CH-52

                // ==================================================
                fudrHold: props.visit.fudrHold,                             // FUDR Hold                                        - CH-33   
                fudrComment: props.visit.fudrComment,                       // FUDR Hold Comment                                - CH-33

                holdReleaseDate: props.visit.holdReleaseDate,               // Hold Release Date                                - CH-44
                hepNSFill: props.visit.hepNSFill,                           // Hep/NS Fill                                      - CH-43

                pumpResidualVolume: props.visit.pumpResidualVolume,         // Pump Residual Volume     Decimal 3, 1	 0-40   - CH-40
                // ==================================================

                flowRateAtChemoTime: props.visit.flowRateAtChemoTime,                       // Flow Rate At Chemo Time  - Calculated/Displayed  - CH-31
                pumpResidualDaysSinceLastFill: props.visit.pumpResidualDaysSinceLastFill,   // PR Days Since Last Fill  - Calculated/Displayed  - CH-31
                pumpResidualVolumeMin: props.visit.pumpResidualVolumeMin,                   // PR Volume Min  - Calculated/Displayed            - CH-31
                pumpResidualVolumeMax: props.visit.pumpResidualVolumeMax,                   // PR Volume Max  - Calculated/Displayed            - CH-31

                coCompleted: props.visit.coCompleted,                                       // CO Completed                                     - CH-42

                coApprover: props.visit.coApprover,                                         // CO Approver
                coApprovedOn: props.visit.coApprovedOn,                                     // CO Approved On

                residualVolumeOutOfRange: props.visit.residualVolumeOutOfRange,             // PumpResidualVolume field shows as red color

                // =============================================


            };
        }
        return null;
    }

    componentDidMount() {
        // console.log("==== VisitModal this.props ====");
        // console.log(this.props);
        // console.log("==== VisitModal this.state ====");
        // console.log(this.state);

        // CH-68
        if (this.props.lastDateOfVisit) {
            if (this.isAllowTreatmentHoliday(this.props.lastDateOfVisit)) {
                this.setState({ allowTreatmentHoliday: true });
            }
        }
    }

    getSuccessMessage(id) {
        var moment = require("moment");
        return (
            <>
                {this.entity} # <span className="text-success">{id}</span>
                , First Name: <span className="text-success">{this.props.patient.firstName}</span>
                , Last Name: <span className="text-success">{this.props.patient.lastName}</span>
                , MRN: <span className="text-success">{this.props.patient.mrn}</span>
                , DOB: <span className="text-success">{moment(this.props.patient.dob).format("l")}</span>

                , Treatment: <span className="text-success">{this.state.treatment}</span>
                , Weight: <span className="text-success">{this.state.weight}</span>
                , AST: <span className="text-success">{this.state.ast}</span>
                , ALP: <span className="text-success">{this.state.alp}</span>
                , Bili: <span className="text-success">{this.state.bili}</span>
            </>
        )
    }

    // visitsPost / visitsPatch
    handleSubmit(event) {
        event.preventDefault();
        var body;

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            form.classList.add("was-validated");
            return;
        } else {
            this.setState({ isProcessing: true });
            form.classList.add("was-validated");
        }

        var patientId = 1;
        if (this.props.parentComp === "PatientModal") {
            if (this.props.patient && this.props.patient.id) {
                patientId = this.props.patient.id;
            }
        }


        if (this.props.action === "add" || this.props.action === "edit") {
            body = {
                patientId: patientId,
                // ==================================================
                treatment: this.state.treatment,
                dateOfVisit: this.state.dateOfVisit,
                treatmentHoliday: this.state.treatmentHoliday,      // CH-68
                weight: this.state.weight,
                wbc: this.state.wbc,                                // WBC (10^3/ul)	        Decimal 5, 2	    0-999	White Blood Cell Count      -   CH-26
                hb: this.state.hb,                                  // Hb (g/dL)	            Decimal 4, 1	    0-999	Hemoglobin                  -   CH-26
                plt: this.state.plt,                                // PLT (10^3/ul)	        Decimal 4	        0-9999	Platelets                   -   CH-26

                anc: this.state.anc,                                // ANC (10^3/ul)	        Decimal 5, 2	    0-999	Absolute Neutrophil Count   -   CH-26
                ast: this.state.ast,                                // AST	                    Decimal 3	        0-999   AST	                        -   before 26
                alt: this.state.alt,                                // ALT (U/L)	            Decimal 4	        0-9999	Alanine Transaminase        -   CH-26
                alp: this.state.alp,                                // ALP                      Decimal 4	        0-9999  Alk Phos	                -   before 26
                bili: this.state.bili,                              // Bili	                    Decimal 3, 1	    0-99    Bilirubin	                -   before 26          
                albumin: this.state.albumin,                        // Albumin (g/dL)	        Decimal 3, 1	    0-99	Albumin                     -   CH-26

                creatinine: this.state.creatinine,                  // Creatinine (mg/dL)	    Decimal 4, 2	    0-99	Creatinine                  -   CH-26
                ldh: this.state.ldh,                                // LDH (U/L)	            Decimal 5	        0-9999	Lactate Dehydrogrenase      -   CH-26
                cea: this.state.cea,                                // CEA (ng/mL)	            Decimal 4, 1	    0-999	Carcinoembryonic Antigen    -   CH-26
                cA19_9: this.state.cA19_9,                          // CA19-9 (U/mL)	        Decimal 4, 1	    0-999	Carbohydrate antigen 19-9   -   CH-26
                comments: this.state.comments,
            }
        }

        if (this.props.action === "to-approval") {                   // TO Approval (Tertiary Oncologist Approval)  -  CH-32
            body = {
                approved: this.state.approved,
            }
        }

        if (this.props.action === "co-approval") {
            body = {
                patientId: patientId,
                // ==================================================
                treatment: this.state.treatment,
                dateOfVisit: this.state.dateOfVisit,
                weight: this.state.weight,
                wbc: this.state.wbc,                                // WBC (10^3/ul)	        Decimal 5, 2	    0-999	White Blood Cell Count      -   CH-26
                hb: this.state.hb,                                  // Hb (g/dL)	            Decimal 4, 1	    0-999	Hemoglobin                  -   CH-26
                plt: this.state.plt,                                // PLT (10^3/ul)	        Decimal 4	        0-9999	Platelets                   -   CH-26

                anc: this.state.anc,                                // ANC (10^3/ul)	        Decimal 5, 2	    0-999	Absolute Neutrophil Count   -   CH-26
                ast: this.state.ast,                                // AST	                    Decimal 3	        0-999   AST	                        -   before 26
                alt: this.state.alt,                                // ALT (U/L)	            Decimal 4	        0-9999	Alanine Transaminase        -   CH-26
                alp: this.state.alp,                                // ALP                      Decimal 4	        0-9999  Alk Phos	                -   before 26
                bili: this.state.bili,                              // Bili	                    Decimal 3, 1	    0-99    Bilirubin	                -   before 26          
                albumin: this.state.albumin,                        // Albumin (g/dL)	        Decimal 3, 1	    0-99	Albumin                     -   CH-26

                creatinine: this.state.creatinine,                  // Creatinine (mg/dL)	    Decimal 4, 2	    0-99	Creatinine                  -   CH-26
                ldh: this.state.ldh,                                // LDH (U/L)	            Decimal 5	        0-9999	Lactate Dehydrogrenase      -   CH-26
                cea: this.state.cea,                                // CEA (ng/mL)	            Decimal 4, 1	    0-999	Carcinoembryonic Antigen    -   CH-26
                cA19_9: this.state.cA19_9,                          // CA19-9 (U/mL)	        Decimal 4, 1	    0-999	Carbohydrate antigen 19-9   -   CH-26
                comments: this.state.comments,

                approved: this.state.approved,                      // TO Approved
                // ==================================================

                fluorouracil: this.state.fluorouracil,                     // 5-Fluorouracil	            0 - 2400 mg/m2     - CH-31
                fluorouracilHold: this.state.fluorouracilHold,             // 5-Fluorouracil Hold                              - CH-31   
                fluorouracilComment: this.state.fluorouracilComment,       // 5-Fluorouracil Hold Comment                      - CH-31

                leucovorin: this.state.leucovorin,                         // Leucovorin	                0 - 200 mg/m2      - CH-31
                leucovorinHold: this.state.leucovorinHold,                 // Leucovorin Hold                                  - CH-31    
                leucovorinComment: this.state.leucovorinComment,           // Leucovorin Hold Comment                          - CH-31

                oxaliplatin: this.state.oxaliplatin,                       // Oxaliplatin	                0 - 85 mg/m2       - CH-31
                oxaliplatinHold: this.state.oxaliplatinHold,               // Oxaliplatin Hold                                 - CH-31    
                oxaliplatinComment: this.state.oxaliplatinComment,         // Oxaliplatin Hold Comment                         - CH-31 

                irinotecan: this.state.irinotecan,                         // Irinotecan	                0 - 180 mg/m2      - CH-31
                irinotecanHold: this.state.irinotecanHold,                 // Irinotecan Hold                                  - CH-31    
                irinotecanComment: this.state.irinotecanComment,           // Irinotecan Hold Comment                          - CH-31

                gemcitabine: this.state.gemcitabine,                       // Gemcitabine	                0 - 1000 mg/m2     - CH-31
                gemcitabineHold: this.state.gemcitabineHold,               // Gemcitabine Hold                                 - CH-31    
                gemcitabineComment: this.state.gemcitabineComment,         // Gemcitabine Hold Comment                         - CH-31

                panitumumab: this.state.panitumumab,                       // Panitumumab	                0 - 6 mg/kg        - CH-31
                panitumumabHold: this.state.panitumumabHold,               // Panitumumab Hold                                 - CH-31    
                panitumumabComment: this.state.panitumumabComment,         // Panitumumab Hold Comment                         - CH-31

                capecitabine: this.state.capecitabine,                             // Capecitabine	                 0-2500  (mg/m2/d)  - CH-52
                capecitabineHold: this.state.capecitabineHold,                     // Capecitabine Hold                                 - CH-52   
                capecitabineComment: this.state.capecitabineComment,               // Capecitabine Hold Comment                         - CH-52
                capecitabineDosingSchedule: this.state.capecitabineDosingSchedule, // Capecitabine Dosing Schedule                      - CH-52

                // ==================================================
                fudrHold: this.state.fudrHold,                             // FUDR Hold                                        - CH-33   
                fudrComment: this.state.fudrComment,                       // FUDR Hold Comment                                - CH-33

                holdReleaseDate: this.state.holdReleaseDate,               // Hold Release Date                                - CH-44
                hepNSFill: this.state.hepNSFill,                           // Hep/NS Fill                                      - CH-43

                pumpResidualVolume: this.state.pumpResidualVolume,         // Pump Residual Volume     Decimal 3, 1	 0-40      - CH-40

                coCompleted: this.state.coCompleted,                       // CO Completed                                     - CH-42
                // ==================================================
            }
        }



        if (this.props.action === "add") {

            let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller;
            logRequest("POST", "handleSubmit", this.entity, this.compClass, fetchUrl, body);

            fetch(fetchUrl, {
                method: "POST",
                headers: {
                    ACCEPT: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    // error handling
                    if (response.ok) {
                        return response.json();
                    } else {
                        if (response.json) {
                            return response.json();
                        } else {
                            throw new Error(response.status);
                        }
                    }
                })
                .then((data) => {
                    if (data.errors) {
                        //alert("Error Saving Record: " + error);
                        logDataErrorResponse("POST", "handleSubmit", this.entity, this.compClass, data);
                        toastDataError(data);
                        throw new Error("data.errors")
                    } else {
                        if (data.id) {
                            //alert("Record Saved Sucessfully");
                            logSuccessResponse("POST", "handleSubmit", this.entity, this.compClass, data);
                            this.handleSelectLast(data.id);
                            toastSuccessCreate(this.getSuccessMessage(data.id));
                            this.setState({ isSubmitButtonEnabled: false });
                            return true;
                        } else {
                            throw new Error("data.id")
                        }
                    }
                })
                .then(this.handleCloseModal)
                .catch((error) => {
                    this.setState({ isSubmitButtonEnabled: true });
                    if (error.message && error.message === "data.errors") {
                        return false;
                    }
                    //alert("Error Saving Record: " + error);
                    logErrorResponse("POST", "handleSubmit", this.entity, this.compClass, error);
                    toastError();
                    return false;
                })
                .finally(() => {
                    this.setState({ isProcessing: false });
                });

            // } else if (this.props.action === "edit" || this.props.action === "approve") {
        } else if (this.props.action === "edit" || this.props.action === "to-approval" || this.props.action === "co-approval") {   // TO Approval / CO Approval - CH-32 / CH-36 / CH-37
            var patchArray = getPatchArray(body, this.state.props);

            if (patchArray && patchArray.length > 0) {

                let id = this.state.id;
                let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "/" + id;
                // if (this.props.action === "approve") {
                if (this.props.action === "to-approval") {                                              // TO Approval (Tertiary Oncologist Approval)  -  CH-32
                    fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "/approve/" + id;
                }
                if (this.props.action === "co-approval") {                                              // CO Approval (Community Oncologist Approval) -  CH-32
                    fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "/coapprove/" + id;
                }
                logRequest("PATCH", "handleSubmit", this.entity, this.compClass, fetchUrl, patchArray);

                fetch(fetchUrl, {
                    method: "PATCH",
                    headers: {
                        ACCEPT: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
                    },
                    body: JSON.stringify(patchArray),
                })
                    .then((response) => {
                        // error handling
                        if (response.ok) {
                            return response;
                        } else {
                            if (response.json) {
                                return response.json();
                            } else {
                                throw new Error(response.status);
                            }
                        }
                    })
                    .then((data) => {
                        if (data.errors) {
                            //alert("Error Saving Record: " + error);
                            logDataErrorResponse("PATCH", "handleSubmit", this.entity, this.compClass, data);
                            toastDataError(data);
                            throw new Error("data.errors")
                        } else {
                            //alert("Record Saved Sucessfully");
                            logSuccessResponse("PATCH", "handleSubmit", this.entity, this.compClass, data);
                            toastSuccessUpdate(this.getSuccessMessage(id));
                            this.setState({ isSubmitButtonEnabled: false });
                            return true;
                        }
                    })
                    .then(this.handleCloseModal)
                    .then(this.handleFetch)
                    .catch((error) => {
                        this.setState({ isSubmitButtonEnabled: true });
                        if (error.message && error.message === "data.errors") {
                            return false;
                        }
                        //alert("Error Saving Record: " + error);
                        logErrorResponse("PATCH", "handleSubmit", this.entity, this.compClass, error);
                        toastError();
                        return false;
                    })
                    .finally(() => {
                        this.setState({ isProcessing: false });
                    });
            }
            else {
                this.handleCloseModal();
            }
        }
    }

    handleSelectLast(id) {
        this.props.onSelectLast(id);
    }

    handleFetch() {
        this.props.onFetch();
    }

    handleCloseModal() {
        // Close the modal
        this.props.onHide();
    }

    // CH-68
    isAllowTreatmentHoliday(dateOfVisit) {
        var allow = false;
        if (dateOfVisit && this.state.lastDateOfVisit) {
            var lastDateOfVisit = new Date(this.state.lastDateOfVisit);
            var dateOfVisit_ = new Date(dateOfVisit);
            var diff = Math.ceil((dateOfVisit_.getTime() - lastDateOfVisit.getTime()) / (1000 * 3600 * 24));

            if (diff > 28) {
                allow = true;
            } else {
                allow = false;
            }
        }

        return allow;
    }

    handleOnChange = (name, value) => {
        // console.log("name: " + name + " value: " + value);
        this.setState({ [name]: value, isSubmitButtonEnabled: true });

        if (name === 'holdReleaseDate' && !value) {
            this.clearChemoValues();
        }

        // CH-68
        if (name === 'dateOfVisit') {
            if (this.isAllowTreatmentHoliday(value)) {
                this.setState({ allowTreatmentHoliday: true });
            } else {
                this.setState({ allowTreatmentHoliday: false, treatmentHoliday: null });
                $("#treatmentHoliday").val(null);
            }
        }
    }

    handleOnChangeCB = (e) => {
        var checked = false;
        if (!this.state[e.target.name]) {
            checked = true;
        }

        // console.log("e.target.name: " + e.target.name + " checked: " + checked);
        // this.props.(e.target.name, checked);

        this.setState({ [e.target.name]: checked, isSubmitButtonEnabled: true }, () => {
            // 5-Fluorouracil - fluorouracilHold
            if (e.target.name === 'fluorouracilHold') {
                if (checked) {
                    this.setState({ fluorouracil: null });
                    $("#fluorouracil").val(null);
                } else {
                    this.setState({ fluorouracilComment: null });
                    $("#fluorouracilComment").val(null);
                }
            }

            // Leucovorin - leucovorinHold
            if (e.target.name === 'leucovorinHold') {
                if (checked) {
                    this.setState({ leucovorin: null });
                    $("#leucovorin").val(null);
                } else {
                    this.setState({ leucovorinComment: null });
                    $("#leucovorinComment").val(null);
                }
            }

            // Oxaliplatin - oxaliplatinHold
            if (e.target.name === 'oxaliplatinHold') {
                if (checked) {
                    this.setState({ oxaliplatin: null });
                    $("#oxaliplatin").val(null);
                } else {
                    this.setState({ oxaliplatinComment: null });
                    $("#oxaliplatinComment").val(null);
                }
            }

            // Irinotecan - irinotecanHold
            if (e.target.name === 'irinotecanHold') {
                if (checked) {
                    this.setState({ irinotecan: null });
                    $("#irinotecan").val(null);
                } else {
                    this.setState({ irinotecanComment: null });
                    $("#irinotecanComment").val(null);
                }
            }

            // Gemcitabine - gemcitabineHold
            if (e.target.name === 'gemcitabineHold') {
                if (checked) {
                    this.setState({ gemcitabine: null });
                    $("#gemcitabine").val(null);
                } else {
                    this.setState({ gemcitabineComment: null });
                    $("#gemcitabineComment").val(null);
                }
            }

            // Panitumumab - panitumumabHold
            if (e.target.name === 'panitumumabHold') {
                if (checked) {
                    this.setState({ panitumumab: null });
                    $("#panitumumab").val(null);
                } else {
                    this.setState({ panitumumabComment: null });
                    $("#panitumumabComment").val(null);
                }
            }

            // Capecitabine - capecitabineHold
            // CH-52 - begin
            if (e.target.name === 'capecitabineHold') {
                if (checked) {
                    this.setState({ capecitabine: null });
                    $("#capecitabine").val(null);
                } else {
                    this.setState({ capecitabineComment: null });
                    $("#capecitabineComment").val(null);
                }
            }
            // CH-52 - end

            // FUDR - fudrHold
            if (e.target.name === 'fudrHold') {
                if (checked) {
                    //
                } else {
                    this.setState({ fudrComment: null, holdReleaseDate: null });
                    $("#fudrComment").val(null);
                    $("#holdReleaseDate").val(null);

                    this.clearChemoValues();
                }
            }
        })
    }

    clearChemoValues = () => {
        if (this.state.fluorouracilHold) {
            this.setState({ fluorouracil: null });
            $("#fluorouracil").val(null);
        }
        if (this.state.leucovorinHold) {
            this.setState({ leucovorin: null });
            $("#leucovorin").val(null);
        }
        if (this.state.oxaliplatinHold) {
            this.setState({ oxaliplatin: null });
            $("#oxaliplatin").val(null);
        }
        if (this.state.irinotecanHold) {
            this.setState({ irinotecan: null });
            $("#irinotecan").val(null);
        }
        if (this.state.gemcitabineHold) {
            this.setState({ gemcitabine: null });
            $("#gemcitabine").val(null);
        }
        if (this.state.panitumumabHold) {
            this.setState({ panitumumab: null });
            $("#panitumumab").val(null);
        }
        // CH-52 - begin
        if (this.state.capecitabineHold) {
            this.setState({ capecitabine: null });
            $("#capecitabine").val(null);
        }
        // CH-52 - end
    }

    render() {
        var moment = require("moment");
        const { DateTime } = require("luxon");

        // CH-55, CH-56 
        var modalHeaderBg = "";
        if (this.props.patient.test === "Yes") {
            modalHeaderBg = "bg-warning";
        }

        var patientInfo = "";
        if (this.props.parentComp === "PatientModal") {
            if (this.props.patient && this.props.patient.id && this.props.patient.firstName && this.props.patient.lastName) {
                patientInfo = <>
                    <span className="px-md-1 px-lg-3 px-xl-4"><i className="bi bi-forward-fill mr-2"></i></span>
                    Patient ID: <span className="text-primary">{this.props.patient.id}</span>,
                    First Name: <span className="text-primary">{this.props.patient.firstName}</span>,
                    Last Name: <span className="text-primary">{this.props.patient.lastName}</span>,
                    MRN: <span className="text-primary">{this.props.patient.mrn}</span>,
                    DOB: <span className="text-primary">{moment(this.props.patient.dob).format("l")}</span>
                    {/* CH-55, CH-56 */}
                    {(this.props.patient.test === "Yes") && (<>   -   <span className="text-danger">This is a TEST Record !!!</span></>)}
                </>
            }
        }

        var todaysDoseStatusClass = '';
        if (this.state.todaysDoseStatus === 'Give') {
            todaysDoseStatusClass = 'text-success';
        } else if (this.state.todaysDoseStatus === 'Resume') {
            todaysDoseStatusClass = 'text-primary';
        } else if (this.state.todaysDoseStatus === 'Hold') {
            todaysDoseStatusClass = 'text-danger';
        }

        var systemicChemotherapyStatusClass = '';
        if (this.state.systemicChemotherapyStatus === 'Give') {
            systemicChemotherapyStatusClass = 'text-success';
        } else if (this.state.systemicChemotherapyStatus && this.state.systemicChemotherapyStatus.includes('Resume')) {
            systemicChemotherapyStatusClass = 'text-primary';
        } else if (this.state.systemicChemotherapyStatus === 'Hold') {
            systemicChemotherapyStatusClass = 'text-danger';
        }

        if (this.state.residualVolumeOutOfRange) {
            setTimeout(() => {
                $("#pumpResidualVolume").addClass('font-weight-bold text-danger');
                // CH-40
                $("#pumpResidualVolumeLabel").removeClass('text-dark').addClass('text-danger');
                $("#pumpResidualVolumeLabel").text('PR Volume - Out Of Range');
            }, 200);
        }

        // CH-68
        if (this.state.treatmentHoliday && this.state.treatmentHoliday === "Yes") {
            setTimeout(() => {
                $("#treatmentHoliday").addClass('font-weight-bold text-danger');
                $("#treatmentHolidayLabel").removeClass('text-dark').addClass('text-danger');
            }, 200);
        } else {
            setTimeout(() => {
                $("#treatmentHoliday").removeClass('font-weight-bold text-danger');
                $("#treatmentHolidayLabel").removeClass('text-danger').addClass('text-dark');
            }, 200);
        }

        // CH-44 - begin    
        let dofVisit = new Date(this.state.dateOfVisit);
        const day1 = moment(addDays(dofVisit, 1)).format("l");
        const day2 = moment(addDays(dofVisit, 1)).format("l");
        const day3 = moment(addDays(dofVisit, 1)).format("l");
        let releaseDateOptions = [{ value: "", label: "" }];
        releaseDateOptions.push({ value: day1, label: day1 });
        releaseDateOptions.push({ value: day2, label: day2 });
        releaseDateOptions.push({ value: day3, label: day3 });
        // CH-44 - end

        return (

            <div
                className="modal fade"
                id="visitModal"
                role="dialog"
                aria-labelledby="visitModalLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered" role="document" style={{ minWidth: "94%" }}>
                    <div className="modal-content" style={{ minHeight: "35vh" }} >
                        <div className={`modal-header card-header ${modalHeaderBg}`} >
                            <h5 className="modal-title" id="visitModalLabel">
                                {(this.state.action === "add") && (
                                    <>
                                        <i className="bi bi-folder-plus mr-2"></i> Add New {this.state.modalEntity} {patientInfo}
                                    </>
                                )}
                                {(this.state.action === "view") && (
                                    <>
                                        <i className="bi bi-card-list mr-2"></i> View {this.state.modalEntity} # <span className="text-primary">{this.state.id}</span> {patientInfo}
                                    </>
                                )}
                                {(this.state.action === "edit") && (
                                    <>
                                        <i className="bi bi-pencil mr-2"></i> Edit {this.state.modalEntity} # <span className="text-primary">{this.state.id}</span> {patientInfo}
                                    </>
                                )}
                                {/* {(this.state.action === "approve") && ( */}
                                {(this.state.action === "to-approval") && (             // TO Approval (Tertiary Oncologist Approval)  -  CH-32
                                    <>
                                        {/* <i className="bi bi-hand-thumbs-up mr-2"></i> Approve {this.state.modalEntity} # <span className="text-primary">{this.state.id}</span> {patientInfo} */}
                                        <i className="bi bi-hand-thumbs-up mr-2"></i> TO Approval # <span className="text-primary">{this.state.id}</span> {patientInfo}
                                    </>
                                )}
                                {(this.state.action === "co-approval") && (              // CO Approval (Community Oncologist Approval) -  CH-32
                                    <>
                                        <i className="bi bi-hand-thumbs-up mr-2"></i> CO Approval # <span className="text-primary">{this.state.id}</span> {patientInfo}
                                    </>
                                )}
                            </h5>
                            <button
                                type="button"
                                id="closeAddModalButton"
                                className="close"
                                onClick={this.handleCloseModal}
                                aria-label="Close"
                                aria-hidden="true"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <form
                                className="needs-validation"
                                noValidate
                                id="visitForm"
                                onSubmit={this.handleSubmit}>

                                <div className="row">

                                    {/* Treatment */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <Select
                                            // label="Treatment"            // CH-67
                                            label="FUDR Treatment Day"      // CH-67
                                            id="treatment"
                                            name="treatment"
                                            defaultValue={this.state.treatment}
                                            onChange={(v) => { this.handleOnChange("treatment", v) }}
                                            disabled={this.state.disabled}
                                            options={[
                                                { value: "", label: "" },
                                                { value: "Yes", label: "Yes" },
                                                { value: "No", label: "No" },
                                            ]}
                                            required={true}
                                            // invalidFeedback="The Treatment field is required."           // CH-67 
                                            invalidFeedback="The FUDR Treatment Day field is required."     // CH-67
                                        />
                                    </div>

                                    {/* Date of Visit */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <DateInput
                                            label="Date of Visit"
                                            id="dateOfVisit"
                                            name="dateOfVisit"
                                            defaultValue={this.state.dateOfVisit}
                                            onChange={(v) => { this.handleOnChange("dateOfVisit", v) }}
                                            disabled={this.props.disabled}
                                            required={true}
                                            invalidFeedback="The Date of Visit field is required."
                                        />
                                    </div>

                                    {/* CH-68 - Treatment Holiday */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <Select
                                            label="Treatment Holiday"
                                            id="treatmentHoliday"
                                            name="treatmentHoliday"
                                            defaultValue={this.state.treatmentHoliday}
                                            onChange={(v) => { this.handleOnChange("treatmentHoliday", v) }}
                                            disabled={this.props.disabled || !this.state.allowTreatmentHoliday}            
                                            options={[
                                                { value: "", label: "" },
                                                { value: "Yes", label: "Yes" },
                                            ]}
                                        />
                                    </div>

                                    {/* Weight */}
                                    {/* CH-23 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="Weight (kg)"
                                            id="weight"
                                            name="weight"
                                            defaultValue={this.state.weight}
                                            onChange={(v) => { this.handleOnChange("weight", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={5}
                                            // number with 1 decimal between 5.0 and 500
                                            pattern="^(?!(0(\.[0-9]{0,1})?|1(\.[0-9]{0,1})?|2(\.[0-9]{0,1})?|3(\.[0-9]{0,1})?|4(\.[0-9]{0,1})?)$)((([1-4]{0,1}[0-9]{0,2})(\.[0-9]{0,1})?)|(([1-9][0-9]{0,1})(\.[0-9]{0,1})?))$|^500$"
                                            required={true}
                                            invalidFeedback="Weight must be a number with 1 decimal between 5.0 and 500"
                                        />
                                    </div>

                                    {/* ================= CH-26-31-33 - begin ================= */}

                                    {/* WBC (10^3/ul)	        Decimal 5, 2	    0-999	White Blood Cell Count      -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="WBC (10^3/ul)"
                                            id="wbc"
                                            name="wbc"
                                            defaultValue={this.state.wbc}
                                            onChange={(v) => { this.handleOnChange("wbc", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={6}
                                            // number with 2 decimals between 0.00 and 999
                                            pattern="^(([0-9]{0,2}[0-8]{0,1})(\.[0-9]{0,2})?)$|^999$"
                                            required={true}
                                            invalidFeedback="WBC must be a number with 2 decimals between 0.00 and 999"
                                        />
                                    </div>

                                    {/* Hb (g/dL)	            Decimal 4, 1	    0-999	Hemoglobin                  -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="Hb (g/dL)"
                                            id="hb"
                                            name="hb"
                                            defaultValue={this.state.hb}
                                            onChange={(v) => { this.handleOnChange("hb", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={5}
                                            // number with 1 decimal between 0.0 and 999
                                            pattern="^(([0-9]{0,2}[0-8]{0,1})(\.[0-9]{0,1})?)$|^999$"
                                            required={true}
                                            invalidFeedback="Hb must be a number with 1 decimal between 0.0 and 999"
                                        />
                                    </div>

                                    {/* PLT (10^3/ul)	        Decimal 4	        0-9999	Platelets                   -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <NumberInput
                                            label="PLT (10^3/ul)"
                                            id="plt"
                                            name="plt"
                                            defaultValue={this.state.plt}
                                            onChange={(v) => { this.handleOnChange("plt", v) }}
                                            disabled={this.state.disabled}
                                            min={0}
                                            max={9999}
                                            required={true}
                                            invalidFeedback="PLT must be numeric between 0 and 9999"
                                        />
                                    </div>

                                    {/* ANC (10^3/ul)	        Decimal 5, 2	    0-999	Absolute Neutrophil Count   -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="ANC (10^3/ul)"
                                            id="anc"
                                            name="anc"
                                            defaultValue={this.state.anc}
                                            onChange={(v) => { this.handleOnChange("anc", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={6}
                                            // number with 2 decimals between 0.00 and 999
                                            pattern="^(([0-9]{0,2}[0-8]{0,1})(\.[0-9]{0,2})?)$|^999$"
                                            required={true}
                                            invalidFeedback="ANC must be a number with 2 decimals between 0.00 and 999"
                                        />
                                    </div>

                                    {/* AST	                    Decimal 3	        0-999   AST	                -   before 26  */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <NumberInput
                                            label="AST (U/L)"           // CH-48
                                            id="ast"
                                            name="ast"
                                            defaultValue={this.state.ast}
                                            onChange={(v) => { this.handleOnChange("ast", v) }}
                                            disabled={this.state.disabled}
                                            min={0}
                                            max={999}
                                            required={true}
                                            invalidFeedback="AST must be numeric between 0 and 999"
                                        />
                                    </div>


                                    {/* ALT (U/L)	            Decimal 4	        0-9999	Alanine Transaminase        -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <NumberInput
                                            label="ALT (U/L)"
                                            id="alt"
                                            name="alt"
                                            defaultValue={this.state.alt}
                                            onChange={(v) => { this.handleOnChange("alt", v) }}
                                            disabled={this.state.disabled}
                                            min={0}
                                            max={9999}
                                            required={true}
                                            invalidFeedback="ALT must be numeric between 0 and 9999"
                                        />
                                    </div>

                                    {/* ALP                      Decimal 4	        0-9999  Alk Phos	               -   before 26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <NumberInput
                                            label="ALP (U/L)"           // CH-48
                                            id="alp"
                                            name="alp"
                                            defaultValue={this.state.alp}
                                            onChange={(v) => { this.handleOnChange("alp", v) }}
                                            disabled={this.state.disabled}
                                            min={0}
                                            max={9999}
                                            required={true}
                                            invalidFeedback="ALP must be numeric between 0 and 9999"
                                        />
                                    </div>

                                    {/* Bili	                    Decimal 3, 1	    0-99    Bilirubin                   -   before 26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="Bili (mg/dL)"           // CH-48
                                            id="bili"
                                            name="bili"
                                            defaultValue={this.state.bili}
                                            onChange={(v) => { this.handleOnChange("bili", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={4}
                                            // number with 1 decimal between 0.0 and 99
                                            pattern="^(([0-9]{0,1}[0-8]{0,1})(\.[0-9]{0,1})?)$|^99$"
                                            required={true}
                                            invalidFeedback="Bili must be a number with 1 decimal between 0.0 and 99"
                                        />
                                    </div>

                                    {/* Albumin (g/dL)	        Decimal 3, 1	    0-99	Albumin                     -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="Albumin (g/dL)"
                                            id="albumin"
                                            name="albumin"
                                            defaultValue={this.state.albumin}
                                            onChange={(v) => { this.handleOnChange("albumin", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={4}
                                            // number with 1 decimal between 0.0 and 99
                                            pattern="^(([0-9]{0,1}[0-8]{0,1})(\.[0-9]{0,1})?)$|^99$"
                                            // required={true}                  // CH-49
                                            invalidFeedback="Albumin must be a number with 1 decimal between 0.0 and 99"
                                        />
                                    </div>

                                    {/* Creatinine (mg/dL)	    Decimal 4, 2	    0-99	Creatinine                  -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="Creatinine (mg/dL)"
                                            id="creatinine"
                                            name="creatinine"
                                            defaultValue={this.state.creatinine}
                                            onChange={(v) => { this.handleOnChange("creatinine", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={5}
                                            // number with 2 decimals between 0.00 and 99
                                            pattern="^(([0-9]{0,1}[0-8]{0,1})(\.[0-9]{0,2})?)$|^99$"
                                            required={true}
                                            invalidFeedback="Creatinine must be a number with 2 decimals between 0.00 and 99"
                                        />
                                    </div>

                                    {/* LDH (U/L)	            Decimal 5	        0-9999	Lactate Dehydrogrenase      -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <NumberInput
                                            label="LDH (U/L)"
                                            id="ldh"
                                            name="ldh"
                                            defaultValue={this.state.ldh}
                                            onChange={(v) => { this.handleOnChange("ldh", v) }}
                                            disabled={this.state.disabled}
                                            min={0}
                                            max={9999}
                                            // required={true}                  // CH-49
                                            invalidFeedback="LDH must be numeric between 0 and 9999"
                                        />
                                    </div>

                                    {/* CEA (ng/mL)	            Decimal 4, 1	    0-999	Carcinoembryonic Antigen    -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="CEA (ng/mL)"
                                            id="cea"
                                            name="cea"
                                            defaultValue={this.state.cea}
                                            onChange={(v) => { this.handleOnChange("cea", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={5}
                                            // number with 1 decimal between 0.0 and 999
                                            pattern="^(([0-9]{0,2}[0-8]{0,1})(\.[0-9]{0,1})?)$|^999$"
                                            // required={true}                  // CH-49
                                            invalidFeedback="CEA must be a number with 1 decimal between 0.0 and 999"
                                        />
                                    </div>

                                    {/* CA19-9 (U/mL)	        Decimal 4, 1	    0-999	Carbohydrate antigen 19-9   -   CH-26 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextInput
                                            label="CA19-9 (U/mL)"
                                            id="cA19_9"
                                            name="cA19_9"
                                            defaultValue={this.state.cA19_9}
                                            onChange={(v) => { this.handleOnChange("cA19_9", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={5}
                                            // number with 1 decimal between 0.0 and 999
                                            pattern="^(([0-9]{0,2}[0-8]{0,1})(\.[0-9]{0,1})?)$|^999$"
                                            // required={true}                  // CH-49
                                            invalidFeedback="CA19-9 must be a number with 1 decimal between 0.0 and 999"
                                        />
                                    </div>

                                    {/* Comments */}
                                    {/* <div className="col-xl-4 col-lg-8"> // CH-68 */}
                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                        <TextArea
                                            label="Comments"
                                            id="comments"
                                            name="comments"
                                            defaultValue={this.state.comments}
                                            onChange={(v) => { this.handleOnChange("comments", v) }}
                                            disabled={this.props.disabled}
                                            maxLength={1024}
                                            rows={2}
                                        />
                                    </div>

                                </div>

                                {/* =========== if (this.state.action !== "add") - begin =========== */}
                                {(this.state.action !== "add") && (
                                    <>
                                        <hr />

                                        {/* =========== Dose Calculation - begin =========== */}

                                        <div className="row">

                                            {/* Max Dose % */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Max Dose %</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="maximumDosePercentage"
                                                        name="maximumDosePercentage"
                                                        type="text"
                                                        value={this.state.maximumDosePercentage}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* CH-28 - Today's HAI FUDR Dose */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Today's HAI FUDR Dose</span>
                                                    </label>
                                                    <input
                                                        // className="form-control form-control-sm"
                                                        className="form-control form-control-sm bg-lightyellow font-weight-bold"    //  CH-21
                                                        id="todaysDose"
                                                        name="todaysDose"
                                                        type="text"
                                                        value={this.state.todaysDose}
                                                        disabled={true}
                                                    // style={{ backgroundColor: "yellow", fontWeight: "bold" }}   //  CH-21
                                                    />
                                                </div>
                                            </div>

                                            {/* CH-28 - Today's HAI FUDR Dose % */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Today's HAI FUDR Dose&nbsp;%</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="todaysDosePercentage"
                                                        name="todaysDosePercentage"
                                                        type="text"
                                                        value={this.state.todaysDosePercentage}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* CH-28 - Today's HAI Dose Status */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Today's HAI Dose Status</span>
                                                    </label>
                                                    <input
                                                        className={"form-control form-control-sm font-weight-bold " + todaysDoseStatusClass}
                                                        id="todaysDoseStatus"
                                                        name="todaysDoseStatus"
                                                        type="text"
                                                        value={this.state.todaysDoseStatus}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Ideal body weight */}
                                            {/* CH-23 */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Ideal Body Weight (kg)</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="idealBodyWeight"
                                                        name="idealBodyWeight"
                                                        type="text"
                                                        value={this.state.idealBodyWeight}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Ideal Average Weight */}
                                            {/* CH-23 */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Ideal Average Weight (kg)</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="idealAverageWeight"
                                                        name="idealAverageWeight"
                                                        type="text"
                                                        value={this.state.idealAverageWeight}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Correct Dosing Body Weight */}
                                            {/* CH-23 */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Correct Dosing Body Weight (kg)</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="correctDosingBodyWeight"
                                                        name="correctDosingBodyWeight"
                                                        type="text"
                                                        value={this.state.correctDosingBodyWeight}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Full Dose */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Full Dose</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="fullDose"
                                                        name="fullDose"
                                                        type="text"
                                                        value={this.state.fullDose}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Reference AST */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Reference AST</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="reference_AST"
                                                        name="reference_AST"
                                                        type="text"
                                                        value={this.state.reference_AST}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Reference ALP */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Reference ALP</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="reference_ALP"
                                                        name="reference_ALP"
                                                        type="text"
                                                        value={this.state.reference_ALP}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Reference Bili */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Reference Bili</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="reference_Bili"
                                                        name="reference_Bili"
                                                        type="text"
                                                        value={this.state.reference_Bili}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Comparator AST */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Comparator AST</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="comparator_AST"
                                                        name="comparator_AST"
                                                        type="text"
                                                        value={this.state.comparator_AST}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Comparator ALP */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Comparator ALP</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="comparator_ALP"
                                                        name="comparator_ALP"
                                                        type="text"
                                                        value={this.state.comparator_ALP}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Comparator Bili */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Comparator Bili</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="comparator_Bili"
                                                        name="comparator_Bili"
                                                        type="text"
                                                        value={this.state.comparator_Bili}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Flow Rate At Lab Time */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Flow Rate At Lab Time</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="flowRateLabTime"
                                                        name="flowRateLabTime"
                                                        type="text"
                                                        value={this.state.flowRateLabTime}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* Systemic Chemotherapy Status */}
                                            <div className="col-xl-2 col-lg-4 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark text-nowrap"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>Systemic Chemotherapy Status</span>
                                                    </label>
                                                    <input
                                                        className={"form-control form-control-sm font-weight-bold " + systemicChemotherapyStatusClass}
                                                        id="systemicChemotherapyStatus"
                                                        name="systemicChemotherapyStatus"
                                                        type="text"
                                                        value={this.state.systemicChemotherapyStatus}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                            {/* =========== Dose Calculation - end =========== */}

                                            {/* TO Approval (Tertiary Oncologist Approval)  -  CH-32 */}

                                            {/* TO Approved */}
                                            <div className="col-xl-1 col-lg-2 col-md-6">
                                                <Select
                                                    label="TO&nbsp;Approved"
                                                    id="approved"
                                                    name="approved"
                                                    defaultValue={this.state.approved}
                                                    onChange={(v) => { this.handleOnChange("approved", v) }}
                                                    disabled={(this.state.action !== "to-approval")}              // TO Approval (Tertiary Oncologist Approval)  -  CH-32
                                                    options={[
                                                        { value: "", label: "" },
                                                        { value: "Yes", label: "Yes" },
                                                        { value: "No", label: "No" },
                                                    ]}
                                                />
                                            </div>

                                            {/* TO Approver */}
                                            <div className="col-xl-3 col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="mb-0 font-weight-bold text-dark"
                                                        style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                        <span style={{ lineHeight: "1.7" }}>TO Approver / TO Approved ON</span>
                                                    </label>
                                                    <input
                                                        className="form-control form-control-sm"
                                                        id="toApprover"
                                                        name="toApprover"
                                                        type="text"
                                                        value={this.state.toApprover ? this.state.toApprover + '   |   ' + DateTime.fromISO(this.state?.toApprovedOn).toLocaleString(DateTime.DATETIME_SHORT) : ''}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <hr />

                                        {/* =========== if (this.state.action !== "to-approval") && (this.state.action !== "edit") - begin =========== */}
                                        {(this.state.action !== "to-approval") && (this.state.action !== "edit") && (
                                            <>
                                                {/* fluorouracil, leucovorin, irinotecan, panitumumab, gemcitabine, and oxaliplatin. - These fields will only be entered during CO Approval, but could be viewed during visit - begin */}

                                                <div className="row">

                                                    {/* 5-Fluorouracil */}
                                                    {/* 5-Fluorouracil	            0 - 2400 mg/m2          - CH-31 */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <NumberInput
                                                            label="5-Fluorouracil (mg/m2)"
                                                            id="fluorouracil"
                                                            name="fluorouracil"
                                                            defaultValue={this.state.fluorouracil}
                                                            onChange={(v) => { this.handleOnChange("fluorouracil", v) }}
                                                            // disabled={((this.state.action === "view") || ((this.state.fluorouracilHold ||
                                                            //     this.state.systemicChemotherapyStatus === "Hold") && !this.state.holdReleaseDate))}        // CH-41, CH-44, CO Approval - CH-32
                                                            disabled={((this.state.action === "view" || this.state.systemicChemotherapyStatus === "Hold") ||  // CH-54
                                                                (!this.state.holdReleaseDate && this.state.fluorouracilHold))}
                                                            min={0}
                                                            max={2400}
                                                            invalidFeedback="5-Fluorouracil must be numeric between 0 and 2400"
                                                        />
                                                    </div>

                                                    {/* 5-Fluorouracil Hold                                 - CH-31 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold text-dark mr-3" style={{ fontSize: "13px" }}>Hold</label>
                                                            <div className="border px-2 pt-1">

                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="fluorouracilHold"
                                                                            name="fluorouracilHold"
                                                                            defaultChecked={this.state.fluorouracilHold}
                                                                            onChange={this.handleOnChangeCB}
                                                                            disabled={(this.state.action !== "co-approval" ||
                                                                                this.state.systemicChemotherapyStatus === "Hold" || this.state.holdReleaseDate)}   // CH-41, CH-44, CO Approval - CH-32
                                                                        />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* 5-Fluorouracil Hold Comment                          - CH-31 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="5-Fluorouracil Hold Comment"
                                                            id="fluorouracilComment"
                                                            name="fluorouracilComment"
                                                            defaultValue={this.state.fluorouracilComment}
                                                            onChange={(v) => { this.handleOnChange("fluorouracilComment", v) }}
                                                            disabled={(this.state.action !== "co-approval" ||
                                                                !this.state.fluorouracilHold || this.state.systemicChemotherapyStatus === "Hold")}       // CH-41, CO Approval - CH-32
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>


                                                    {/* Leucovorin */}
                                                    {/* Leucovorin	                0 - 200 mg/m2           - CH-31 */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <NumberInput
                                                            label="Leucovorin (mg/m2)"
                                                            id="leucovorin"
                                                            name="leucovorin"
                                                            defaultValue={this.state.leucovorin}
                                                            onChange={(v) => { this.handleOnChange("leucovorin", v) }}
                                                            // disabled={((this.state.action === "view") || ((this.state.leucovorinHold ||
                                                            //     this.state.systemicChemotherapyStatus === "Hold") && !this.state.holdReleaseDate))}          // CH-41, CH-44, CO Approval - CH-32
                                                            disabled={((this.state.action === "view" || this.state.systemicChemotherapyStatus === "Hold") ||    // CH-54
                                                                (!this.state.holdReleaseDate && this.state.leucovorinHold))}
                                                            min={0}
                                                            max={200}
                                                            invalidFeedback="Leucovorin must be numeric between 0 and 200"
                                                        />
                                                    </div>

                                                    {/* Leucovorin Hold                                     - CH-31 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold text-dark mr-3" style={{ fontSize: "13px" }}>Hold</label>
                                                            <div className="border px-2 pt-1">

                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="leucovorinHold"
                                                                            name="leucovorinHold"
                                                                            defaultChecked={this.state.leucovorinHold}
                                                                            onChange={this.handleOnChangeCB}
                                                                            disabled={(this.state.action !== "co-approval" ||
                                                                                this.state.systemicChemotherapyStatus === "Hold" || this.state.holdReleaseDate)}     // CH-41, CH-44, CO Approval - CH-32
                                                                        />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Leucovorin Hold Comment                          - CH-31 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="Leucovorin Hold Comment"
                                                            id="leucovorinComment"
                                                            name="leucovorinComment"
                                                            defaultValue={this.state.leucovorinComment}
                                                            onChange={(v) => { this.handleOnChange("leucovorinComment", v) }}
                                                            disabled={(this.state.action !== "co-approval" ||
                                                                !this.state.leucovorinHold || this.state.systemicChemotherapyStatus === "Hold")}       // CH-41, CO Approval - CH-32
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="row">

                                                    {/* Oxaliplatin */}
                                                    {/* Oxaliplatin	                0 - 85 mg/m2       - CH-31 */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <NumberInput
                                                            label="Oxaliplatin (mg/m2)"
                                                            id="oxaliplatin"
                                                            name="oxaliplatin"
                                                            defaultValue={this.state.oxaliplatin}
                                                            onChange={(v) => { this.handleOnChange("oxaliplatin", v) }}
                                                            // disabled={((this.state.action === "view") || ((this.state.oxaliplatinHold ||
                                                            //     this.state.systemicChemotherapyStatus === "Hold") && !this.state.holdReleaseDate))}          // CH-41, CH-44, CO Approval - CH-32
                                                            disabled={((this.state.action === "view" || this.state.systemicChemotherapyStatus === "Hold") ||    // CH-54
                                                                (!this.state.holdReleaseDate && this.state.oxaliplatinHold))}
                                                            min={0}
                                                            max={85}
                                                            invalidFeedback="Oxaliplatin must be numeric between 0 and 85"
                                                        />
                                                    </div>

                                                    {/* Oxaliplatin Hold                                 - CH-31 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold text-dark mr-3" style={{ fontSize: "13px" }}>Hold</label>
                                                            <div className="border px-2 pt-1">

                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="oxaliplatinHold"
                                                                            name="oxaliplatinHold"
                                                                            defaultChecked={this.state.oxaliplatinHold}
                                                                            onChange={this.handleOnChangeCB}
                                                                            disabled={(this.state.action !== "co-approval" ||
                                                                                this.state.systemicChemotherapyStatus === "Hold" || this.state.holdReleaseDate)}    // CH-41, CH-44, CO Approval - CH-32
                                                                        />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Oxaliplatin Hold Comment                         - CH-31 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="Oxaliplatin Hold Comment"
                                                            id="oxaliplatinComment"
                                                            name="oxaliplatinComment"
                                                            defaultValue={this.state.oxaliplatinComment}
                                                            onChange={(v) => { this.handleOnChange("oxaliplatinComment", v) }}
                                                            disabled={(this.state.action !== "co-approval" ||
                                                                !this.state.oxaliplatinHold || this.state.systemicChemotherapyStatus === "Hold")}       // CH-41, CO Approval - CH-32
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>

                                                    {/* Irinotecan */}
                                                    {/* Irinotecan	                0 - 180 mg/m2        - CH-31 */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <NumberInput
                                                            label="Irinotecan (mg/m2)"
                                                            id="irinotecan"
                                                            name="irinotecan"
                                                            defaultValue={this.state.irinotecan}
                                                            onChange={(v) => { this.handleOnChange("irinotecan", v) }}
                                                            // disabled={((this.state.action === "view") || ((this.state.irinotecanHold ||
                                                            //     this.state.systemicChemotherapyStatus === "Hold") && !this.state.holdReleaseDate))}          // CH-41, CH-44, CO Approval - CH-32
                                                            disabled={((this.state.action === "view" || this.state.systemicChemotherapyStatus === "Hold") ||    // CH-54
                                                                (!this.state.holdReleaseDate && this.state.irinotecanHold))}
                                                            min={0}
                                                            max={180}
                                                            invalidFeedback="Irinotecan must be numeric between 0 and 180"
                                                        />
                                                    </div>

                                                    {/* Irinotecan Hold                                  - CH-31 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold text-dark mr-3" style={{ fontSize: "13px" }}>Hold</label>
                                                            <div className="border px-2 pt-1">

                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="irinotecanHold"
                                                                            name="irinotecanHold"
                                                                            defaultChecked={this.state.irinotecanHold}
                                                                            onChange={this.handleOnChangeCB}
                                                                            disabled={(this.state.action !== "co-approval" ||
                                                                                this.state.systemicChemotherapyStatus === "Hold" || this.state.holdReleaseDate)}      // CH-41, CH-44, CO Approval - CH-32 
                                                                        />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Irinotecan Hold Comment                          - CH-31 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="Irinotecan Hold Comment"
                                                            id="irinotecanComment"
                                                            name="irinotecanComment"
                                                            defaultValue={this.state.irinotecanComment}
                                                            onChange={(v) => { this.handleOnChange("irinotecanComment", v) }}
                                                            disabled={(this.state.action !== "co-approval" ||
                                                                !this.state.irinotecanHold || this.state.systemicChemotherapyStatus === "Hold")}       // CH-41, CO Approval - CH-32
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="row">

                                                    {/* Gemcitabine */}
                                                    {/* Gemcitabine	                0 - 1000 mg/m2     - CH-31 */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <NumberInput
                                                            label="Gemcitabine (mg/m2)"
                                                            id="gemcitabine"
                                                            name="gemcitabine"
                                                            defaultValue={this.state.gemcitabine}
                                                            onChange={(v) => { this.handleOnChange("gemcitabine", v) }}
                                                            // disabled={((this.state.action === "view") || ((this.state.gemcitabineHold ||
                                                            //     this.state.systemicChemotherapyStatus === "Hold") && !this.state.holdReleaseDate))}          // CH-41, CH-44 , CO Approval - CH-32
                                                            disabled={((this.state.action === "view" || this.state.systemicChemotherapyStatus === "Hold") ||    // CH-54
                                                                (!this.state.holdReleaseDate && this.state.gemcitabineHold))}
                                                            min={0}
                                                            max={1000}
                                                            invalidFeedback="Oxaliplatin must be numeric between 0 and 1000"
                                                        />
                                                    </div>

                                                    {/* Gemcitabine Hold                                 - CH-31 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold text-dark mr-3" style={{ fontSize: "13px" }}>Hold</label>
                                                            <div className="border px-2 pt-1">

                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="gemcitabineHold"
                                                                            name="gemcitabineHold"
                                                                            defaultChecked={this.state.gemcitabineHold}
                                                                            onChange={this.handleOnChangeCB}
                                                                            disabled={(this.state.action !== "co-approval" ||
                                                                                this.state.systemicChemotherapyStatus === "Hold" || this.state.holdReleaseDate)}     // CH-41, CH-44, CO Approval - CH-32
                                                                        />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Gemcitabine Hold Comment                         - CH-31 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="Gemcitabine Hold Comment"
                                                            id="gemcitabineComment"
                                                            name="gemcitabineComment"
                                                            defaultValue={this.state.gemcitabineComment}
                                                            onChange={(v) => { this.handleOnChange("gemcitabineComment", v) }}
                                                            disabled={(this.state.action !== "co-approval" ||
                                                                !this.state.gemcitabineHold || this.state.systemicChemotherapyStatus === "Hold")}       // CH-41, CO Approval - CH-32
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>

                                                    {/* Panitumumab */}
                                                    {/* Panitumumab	                0 - 6 mg/kg          - CH-31 */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <TextInput
                                                            label="Panitumumab (mg/kg)"
                                                            id="panitumumab"
                                                            name="panitumumab"
                                                            defaultValue={this.state.panitumumab}
                                                            onChange={(v) => { this.handleOnChange("panitumumab", v) }}
                                                            // disabled={((this.state.action === "view") || ((this.state.panitumumabHold ||
                                                            //     this.state.systemicChemotherapyStatus === "Hold") && !this.state.holdReleaseDate))}          // CH-41, CH-44, CO Approval - CH-32
                                                            disabled={((this.state.action === "view" || this.state.systemicChemotherapyStatus === "Hold") ||    // CH-54
                                                                (!this.state.holdReleaseDate && this.state.panitumumabHold))}
                                                            maxLength={3}
                                                            // number with 1 decimal between 0.0 and 6
                                                            pattern="^(([0-5]{0,1})(\.[0-9]{0,1})?)$|^6$"
                                                            invalidFeedback="Panitumumab must be a number with 1 decimal between 0.0 and 6"
                                                        />
                                                    </div>

                                                    {/* Panitumumab Hold                                 - CH-31 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold text-dark mr-3" style={{ fontSize: "13px" }}>Hold</label>
                                                            <div className="border px-2 pt-1">

                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="panitumumabHold"
                                                                            name="panitumumabHold"
                                                                            defaultChecked={this.state.panitumumabHold}
                                                                            onChange={this.handleOnChangeCB}
                                                                            disabled={(this.state.action !== "co-approval" ||
                                                                                this.state.systemicChemotherapyStatus === "Hold" || this.state.holdReleaseDate)}     // CH-41, CH-44, CO Approval - CH-32 
                                                                        />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Panitumumab Hold Comment                         - CH-31 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="Panitumumab Hold Comment"
                                                            id="panitumumabComment"
                                                            name="panitumumabComment"
                                                            defaultValue={this.state.panitumumabComment}
                                                            onChange={(v) => { this.handleOnChange("panitumumabComment", v) }}
                                                            disabled={(this.state.action !== "co-approval" ||
                                                                !this.state.panitumumabHold || this.state.systemicChemotherapyStatus === "Hold")}       // CH-41, CO Approval - CH-32
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>

                                                </div>

                                                {/* CH-52 - begin */}
                                                <div className="row">

                                                    {/* Capecitabine */}
                                                    {/* Capecitabine (mg/m2/d) 0-2500 range     - CH-52 */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <NumberInput
                                                            label="Capecitabine (mg/m2/d) "
                                                            id="capecitabine"
                                                            name="capecitabine"
                                                            defaultValue={this.state.capecitabine}
                                                            onChange={(v) => { this.handleOnChange("capecitabine", v) }}
                                                            // disabled={((this.state.action === "view") || ((this.state.capecitabineHold ||
                                                            //     this.state.systemicChemotherapyStatus === "Hold") && !this.state.holdReleaseDate))}    // CH-41, CH-44 , CO Approval - CH-32
                                                            disabled={((this.state.action === "view" || this.state.systemicChemotherapyStatus === "Hold") ||  // CH-54
                                                                (!this.state.holdReleaseDate && this.state.capecitabineHold))}
                                                            min={0}
                                                            max={1000}
                                                            invalidFeedback="Capecitabine must be numeric between 0 and 1000"
                                                        />
                                                    </div>

                                                    {/* Capecitabine Hold                        - CH-52 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold text-dark mr-3" style={{ fontSize: "13px" }}>Hold</label>
                                                            <div className="border px-2 pt-1">

                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="capecitabineHold"
                                                                            name="capecitabineHold"
                                                                            defaultChecked={this.state.capecitabineHold}
                                                                            onChange={this.handleOnChangeCB}
                                                                            disabled={(this.state.action !== "co-approval" ||
                                                                                this.state.systemicChemotherapyStatus === "Hold" || this.state.holdReleaseDate)}     // CH-41, CH-44, CO Approval - CH-32
                                                                        />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Capecitabine Hold Comment                - CH-52 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="Capecitabine Hold Comment"
                                                            id="capecitabineComment"
                                                            name="capecitabineComment"
                                                            defaultValue={this.state.capecitabineComment}
                                                            onChange={(v) => { this.handleOnChange("capecitabineComment", v) }}
                                                            disabled={(this.state.action !== "co-approval" ||
                                                                !this.state.capecitabineHold || this.state.systemicChemotherapyStatus === "Hold")}       // CH-41, CO Approval - CH-32
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>

                                                    {/* Capecitabine Dosing Schedule              - CH-52 */}
                                                    <div className="col-xl-6 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="Capecitabine Dosing Schedule"
                                                            id="capecitabineDosingSchedule"
                                                            name="capecitabineDosingSchedule"
                                                            defaultValue={this.state.capecitabineDosingSchedule}
                                                            onChange={(v) => { this.handleOnChange("capecitabineDosingSchedule", v) }}
                                                            disabled={(this.state.action !== "co-approval")}
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>

                                                </div>
                                                {/* CH-52 - end */}

                                                {/* fluorouracil, leucovorin, irinotecan, panitumumab, gemcitabine, and oxaliplatin. - These fields will only be entered during CO Approval, but could be viewed during visit - end */}

                                                <hr />

                                                <div className="row">

                                                    {/* CH-28 - HAI FUDR Dose */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="mb-0 font-weight-bold text-dark"
                                                                style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                                <span style={{ lineHeight: "1.7" }}>HAI FUDR Dose</span>
                                                            </label>
                                                            <input
                                                                // className="form-control form-control-sm"
                                                                className="form-control form-control-sm bg-lightyellow font-weight-bold"    //  CH-21
                                                                id="todaysDose"
                                                                name="todaysDose"
                                                                type="text"
                                                                value={this.state.todaysDose}
                                                                disabled={true}
                                                            // style={{ backgroundColor: "yellow", fontWeight: "bold" }}   //  CH-21
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* HAI FUDR Dose Hold                                       - CH-33 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="font-weight-bold text-dark mr-3" style={{ fontSize: "13px" }}>Hold</label>
                                                            <div className="border px-2 pt-1">

                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="fudrHold"
                                                                            name="fudrHold"
                                                                            defaultChecked={this.state.fudrHold}
                                                                            onChange={this.handleOnChangeCB}
                                                                            disabled={(this.state.action !== "co-approval" || this.state.treatment !== "Yes")}                                                  // CO Approval - CH-32 
                                                                        />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* HAI FUDR Dose Hold Comment                              - CH-33 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12">
                                                        <TextArea
                                                            label="HAI FUDR Dose Hold Comment"
                                                            id="fudrComment"
                                                            name="fudrComment"
                                                            defaultValue={this.state.fudrComment}
                                                            onChange={(v) => { this.handleOnChange("fudrComment", v) }}
                                                            disabled={(this.state.action !== "co-approval" || !this.state.fudrHold)}  // CO Approval - CH-32
                                                            maxLength={128}
                                                            rows={2}
                                                        />
                                                    </div>

                                                    {/* Hold Release Date         - CH-44 */}
                                                    <div className="col-xl-2 col-lg-3 col-sm-6">
                                                        <Select
                                                            label="Hold Release Date"
                                                            id="holdReleaseDate"
                                                            name="holdReleaseDate"
                                                            defaultValue={moment(this.state.holdReleaseDate).format("l")}
                                                            onChange={(v) => { this.handleOnChange("holdReleaseDate", v) }}
                                                            disabled={(this.state.action !== "co-approval" ||
                                                                this.state.treatment !== "Yes" || !this.state.fudrHold)}
                                                            options={releaseDateOptions}
                                                        />
                                                    </div>

                                                    {/* Hep/NS Fill               - CH-43 */}
                                                    <div className="col-xl-1 col-lg-3 col-sm-6 text-nowrap">
                                                        <Select
                                                            label="Hep/NS Fill"
                                                            id="hepNSFill"
                                                            name="hepNSFill"
                                                            defaultValue={this.state.hepNSFill}
                                                            onChange={(v) => { this.handleOnChange("hepNSFill", v) }}
                                                            // disabled={(this.state.action !== "co-approval" || this.state.treatment !== "No")}
                                                            disabled={(this.state.action !== "co-approval")}        // CH-53
                                                            options={[
                                                                { value: "", label: "" },
                                                                { value: "Yes", label: "Yes" },
                                                                { value: "No", label: "No" },
                                                            ]}
                                                            required={true}
                                                            invalidFeedback="The Hep/NS Fill field is required."
                                                        />
                                                    </div>

                                                    {/* Pump Residual Volume     Decimal 3, 1	 0-40        - CH-40 */}
                                                    <div className="col-xl-3 col-lg-6 col-sm-12 text-nowrap">
                                                        <TextInput
                                                            label="PR Volume"
                                                            id="pumpResidualVolume"
                                                            name="pumpResidualVolume"
                                                            defaultValue={this.state.pumpResidualVolume}
                                                            onChange={(v) => { this.handleOnChange("pumpResidualVolume", v) }}
                                                            disabled={(this.state.action !== "co-approval")}
                                                            maxLength={4}
                                                            // number with 1 decimal between 0.0 and 40
                                                            pattern="^(([1-3]{0,1}[0-9]{0,1})(\.[0-9]{0,1})?)$|^40$"
                                                            invalidFeedback="PR Volume must be a number with 1 decimal between 0.0 and 40"
                                                        />
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    {/* Flow Rate At Chemo Time  - Calculated/Displayed  - CH-31 */}
                                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-0 font-weight-bold text-dark text-nowrap"
                                                                style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                                <span style={{ lineHeight: "1.7" }}>Flow Rate At Chemo Time</span>
                                                            </label>
                                                            <input
                                                                className="form-control form-control-sm"
                                                                id="flowRateAtChemoTime"
                                                                name="flowRateAtChemoTime"
                                                                type="text"
                                                                value={this.state.flowRateAtChemoTime}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Pump Residual Days Since Last Fill  - Calculated/Displayed  - CH-31 */}
                                                    <div className="col-xl-2 col-lg-4 col-md-6">

                                                        <div className="form-group">
                                                            <label className="mb-0 font-weight-bold text-dark text-nowrap"
                                                                style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                                <span style={{ lineHeight: "1.7" }}>PR Days Since Last Fill</span>
                                                            </label>
                                                            <input
                                                                className="form-control form-control-sm"
                                                                id="pumpResidualDaysSinceLastFill"
                                                                name="pumpResidualDaysSinceLastFill"
                                                                type="text"
                                                                value={this.state.pumpResidualDaysSinceLastFill}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Pump Residual Volume Min  - Calculated/Displayed  - CH-31 */}
                                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-0 font-weight-bold text-dark text-nowrap"
                                                                style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                                <span style={{ lineHeight: "1.7" }}>PR Volume Min</span>
                                                            </label>
                                                            <input
                                                                className="form-control form-control-sm"
                                                                id="pumpResidualVolumeMin"
                                                                name="pumpResidualVolumeMin"
                                                                type="text"
                                                                value={this.state.pumpResidualVolumeMin}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Pump Residual Volume Max  - Calculated/Displayed  - CH-31 */}
                                                    <div className="col-xl-2 col-lg-4 col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-0 font-weight-bold text-dark text-nowrap"
                                                                style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                                <span style={{ lineHeight: "1.7" }}>PR Volume Max</span>
                                                            </label>
                                                            <input
                                                                className="form-control form-control-sm"
                                                                id="pumpResidualVolumeMax"
                                                                name="pumpResidualVolumeMax"
                                                                type="text"
                                                                value={this.state.pumpResidualVolumeMax}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* CO Completed                                 - CH-42 */}
                                                    <div className="col-xl-1 col-lg-2 col-md-6 text-nowrap">
                                                        <Select
                                                            label="CO Completed"
                                                            id="coCompleted"
                                                            name="coCompleted"
                                                            defaultValue={this.state.coCompleted}
                                                            onChange={(v) => { this.handleOnChange("coCompleted", v) }}
                                                            disabled={(this.state.action !== "co-approval")}
                                                            options={[
                                                                { value: "Yes", label: "Yes" },
                                                                { value: "No", label: "No" },
                                                            ]}
                                                        />
                                                    </div>

                                                    {/* TO Approver */}
                                                    <div className="col-xl-3 col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label className="mb-0 font-weight-bold text-dark text-nowrap"
                                                                style={{ fontSize: "13px", letterSpacing: "0.25px" }}>
                                                                <span style={{ lineHeight: "1.7" }}>CO Approver / CO Approved ON</span>
                                                            </label>
                                                            <input
                                                                className="form-control form-control-sm"
                                                                id="coApprover"
                                                                name="coApprover"
                                                                type="text"
                                                                value={this.state.coApprover ? this.state.coApprover + '   |   ' + DateTime.fromISO(this.state?.coApprovedOn).toLocaleString(DateTime.DATETIME_SHORT) : ''}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* ================= CH-26-31-33 - end ================= */}
                                            </>
                                        )}
                                        {/* =========== if(this.state.action !== "to-approval") && (this.state.action !== "edit") - end =========== */}
                                    </>
                                )}
                                {/* =========== if (this.state.action !== "add") - end =========== */}

                                <div className="row">
                                    <div className="col">
                                        <br></br>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col pt-2 text-right col align-self-end">

                                        {/* {(!this.state.disabled || this.state.action === "approve") && (  //  TO Approval (Tertiary Oncologist Approval)  -  CH-32 */}
                                        {(!this.state.disabled || this.state.action === "to-approval" || this.state.action === "co-approval") && (

                                            <Button
                                                type="submit"
                                                text={"Save " + this.state.modalEntity}
                                                loadingText={"Save " + this.state.modalEntity}
                                                form="visitForm"
                                                icon="bi-arrow-return-left"
                                                variant="success"
                                                classes="mr-2"
                                                isLoading={this.state.isProcessing}
                                                isDisabled={!this.state.isSubmitButtonEnabled}
                                            />

                                        )}

                                        <span className="ml-3"></span>

                                        <Button
                                            type="button"
                                            text="Cancel"
                                            icon="bi-x-circle"
                                            variant="secondary"
                                            isOutline={true}
                                            classes="mr-2"
                                            onClick={this.handleCloseModal}
                                        />

                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VisitModal;