import React from "react";
import $ from "jquery";

// @ukhc/devops-react-library
import Table from "@ukhc/devops-react-library/components/Table";
import Search from "@ukhc/devops-react-library/components/Search";
import Pagination from "@ukhc/devops-react-library/components/Pagination";
import ItemsPerPage from "@ukhc/devops-react-library/components/ItemsPerPage";

import DeleteConfirmationModal from "../Lib/DeleteConfirmationModal";
import TableDashboard from "../Lib/TableDashboard";                                 // CH-17 
import { pivot } from "../Lib/Helper";                                              // CH-17 - Excel
import { pivotArray } from "../Lib/Helper";                                         // CH-17 - Excel
import { getPivotHeader } from "../Lib/Helper";                                     // CH-17 - Excel    

// App components
import VisitModal from "./VisitModal";

import Button from "../Lib/Button";
import { exportToExcel, isEmpty } from "../Lib/Helper";                                     // CH-17 - Excel
import { logRequest, logSuccessResponse, logErrorResponse } from "../Lib/Logs";
import { toastError, toastSuccessDelete } from "../Lib/Toasts";

class Visits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            excelExportData: null,                                                  // CH-17 - Excel
            fetchResponseStatusCode: null,
            isLoading: true,
            isProcessingExcelExport: false,                                         // CH-17 - Excel

            isSearching: false,
            isTableDisabled: true,
            // itemsPerPage: 5,
            itemsPerPage: (this.props.action === "dashboard") ? 25 : 50,            // CH-17
            itemsPerPageOptions: [5, 10, 25, 50, 100],

            records: [],
            recordsFilter: "",
            search: "",
            selectedRecord: {},
            selectedRows: [],
            sortDescending: false,
            sortedBy: "id",
            totalPages: null,
            // totalResults: null,
            totalItems: null,
            uidProperty: "id",

            // ===========================
            visitAddModalShow: false,
            visitEditModalShow: false,
            visitViewModalShow: false,
            // visitApproveModalShow: false,        
            visitTOApprovalModalShow: false,         // TO Approval (Tertiary Oncologist Approval)  -  CH-32
            visitCOApprovalModalShow: false,         // CO Approval (Community Oncologist Approval) -  CH-32
            deleteConfirmationModalShow: false,

            id: null,
            isHandleSelectLast: false,

            lastDateOfVisit: null,                  // CH-63
            lastId: null,                           // CH-63

            allowAdd: true,                         // allowAdd button
            // ===========================
            isModal: this.props.isModal,
            modalEntity: this.props.modalEntity,
            // =============================================
            isProcessing: false,

            dashboardRaws: [],                      // CH-65
        };
        this.controller = "visits";
        this.entity = "Visit";
        this.compClass = "Visits";

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleViewButtonClick = this.handleViewButtonClick.bind(this);

        // this.handleApproveButtonClick = this.handleApproveButtonClick.bind(this);    
        this.handleTOApprovalClick = this.handleTOApprovalClick.bind(this);             // TO Approval (Tertiary Oncologist Approval)  -  CH-32
        this.handleCOApprovalClick = this.handleCOApprovalClick.bind(this);             // CO Approval (Community Oncologist Approval) -  CH-32

        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteConfirmationModalClose = this.handleDeleteConfirmationModalClose.bind(this);

        this.handlePageBack = this.handlePageBack.bind(this);
        this.handlePageForward = this.handlePageForward.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSetPage = this.handleSetPage.bind(this);
        this.handleSetItemsPerPage = this.handleSetItemsPerPage.bind(this);
        this.handleSetRecordsFilter = this.handleSetRecordsFilter.bind(this);
        this.handleSetSelectedRows = this.handleSetSelectedRows.bind(this);
        this.handleSetSortedBy = this.handleSetSortedBy.bind(this);
        this.handleSetSortDescending = this.handleSetSortDescending.bind(this);

        this.handleSelectLast = this.handleSelectLast.bind(this);
        this.handleExcelExport = this.handleExcelExport.bind(this);                     // CH-17 - Excel
        // this.getLastRecord = this.getLastRecord.bind(this);                             // CH-63
        this.handleFetch = this.handleFetch.bind(this);                                 
        this.handleFetch_ = this.handleFetch_.bind(this);                                 // CH-63
        this.getDeleteMessage = this.getDeleteMessage.bind(this);

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);

        // Initial modal state
        // $(".modal-backdrop").remove();

        //Timer for when a query takes too long
        this.isLoadingTimeout = null;
    }

    // for the data refresh inter@val
    intervalID;


    handleCloseModal() {
        // Close the modal
        // document.getElementById("closePersonsModalButton").click();
        this.props.onHide();
    }

    componentDidMount() {
        this.handleFetch();
        // call handleFetch() every 30 minutes
        this.intervalID = setInterval(this.handleFetch, 1800000);
    }

    componentWillUnmount() {
        /*
                stop handleFetch() from continuing to run even
                after unmounting this component.
            */
        clearInterval(this.intervalID);
    }

    handleSelectLast(id) {
        var patientId = 1;
        if (this.props.parentComp === "PatientModal") {
            if (this.props.patient && this.props.patient.id) {
                patientId = this.props.patient.id;
            }
        }

        // let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller +
        let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "?patientId=" + patientId +
            // "?pageSize=" + this.state.itemsPerPage + "&pageNumber=1";
            "&pageSize=" + this.state.itemsPerPage + "&currentPage=1";

        logRequest("GET", "handleSelectLast", this.entity, this.compClass, fetchUrl);

        fetch(fetchUrl, {
            method: "GET",
            headers: {
                ACCEPT: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
            },
        })
            .then((response) => {
                // error handling
                if (response.ok) {
                    logSuccessResponse("GET", "handleSelectLast", this.entity, this.compClass, response);
                    // Read pagination from the header
                    let pagination = JSON.parse(response.headers.get("x-pagination"));
                    this.setState({
                        // currentPage: Math.ceil(pagination.totalResults / this.state.itemsPerPage),
                        currentPage: Math.ceil(pagination.totalItems / this.state.itemsPerPage),
                        fetchResponseStatusCode: response.status,
                        // totalPages: Math.ceil(pagination.totalResults / this.state.itemsPerPage),
                        totalPages: Math.ceil(pagination.totalItems / this.state.itemsPerPage),
                        // totalResults: pagination.totalResults,
                        totalItems: pagination.totalItems,

                        id: id,
                        isSearching: true,
                        search: "",
                        isHandleSelectLast: true,
                    },
                        () => {
                            if (this.props.action !== "dashboard") {                // CH-17
                                this.searchRef.setState({ searchValue: "" });
                            }
                            this.handleFetch();
                        });
                } else {
                    this.setState({ fetchResponseStatusCode: response.status });
                    throw new Error(response.status);
                }
            })
            .catch((error) => {
                logErrorResponse("GET", "handleSelectLast", this.entity, this.compClass, error);
                // toastError();

                this.setState({
                    isLoading: false,
                    isSearching: false,
                    isTableDisabled: false,
                    isHandleSelectLast: false,
                    records: [],
                    totalPages: 0,
                    // totalResults: 0,
                    totalItems: 0,
                });
            });
    }

    // CH-17 - Excel - begin
    handleExcelExport(isDashboard) {
        var moment = require("moment");
        var sheetTitle = window.REACT_APP_NAME + " - Visits";
        var fName = "Visits";

        if (isDashboard) {
            sheetTitle = sheetTitle + " - Dashboard"
            fName = fName + "-Dashboard"
        }

        sheetTitle = sheetTitle + "     -      Patient ID: " + this.props.patient.id + "    First Name: " + this.props.patient.firstName + "    Last Name: " + this.props.patient.lastName +
            "    MRN: " + this.props.patient.mrn + "    DOB: " + moment(this.props.patient.dob).format("l") + "    Height (cm): " + this.props.patient.height +
            "    Sex: " + this.props.patient.sex + "    AST ULN: " + this.props.patient.asT_ULN + "    ALT ULN: " + this.props.patient.alT_ULN +
            "    ALP ULN: " + this.props.patient.alP_ULN + "    Bili ULN: " + this.props.patient.bili_ULN + "    Institution: " + this.props.patient.institutionName +
            "    Pump Type: " + this.props.patient.pumpType + "    Pump Volume (ml): " + this.props.patient.pumpVolume + "    Flow Rate: " + this.props.patient.flowRate;

        fName = fName + "-Patient-ID-" + this.props.patient.id + "-First-Name-" + this.props.patient.firstName + "-Last-Name-" + this.props.patient.lastName +
            "-MRN-" + this.props.patient.mrn + "-DOB-" + moment(this.props.patient.dob).format("l")

        if (this.state.lastDateOfVisit) {
            fName = fName + "-Last-Date-Of-Visit-" + moment(this.state.lastDateOfVisit).format("l");
        }

        const filename = `${window.REACT_APP_NAME} - ${fName}.xlsx`;
        const workbookSheet = "Visits";

        var headers = [];
        // headers = [[""], ["Date Of Visit", "Treatment", "Weight (kg)", "WBC (10^3/ul)", "Hb (g/dL)", "PLT (10^3/ul)", "ANC (10^3/ul)", "AST (U/L)", "ALT (U/L)", "ALP (U/L)", "Bili (mg/dL)", "Albumin (g/dL)", "Creatinine (mg/dL)", "LDH (U/L)", "CEA (ng/mL)", "CA19-9 (U/mL)", "Max Dose %", "Today's HAI FUDR Dose", "Today's HAI FUDR Dose %", "Today's HAI Dose Status", "Ideal Body Weight (kg)", "Ideal Average Weight (kg)", "Correct Dosing Body Weight (kg)", "Full Dose", "Reference AST", "Reference ALP", "Reference Bili", "Comparator AST", "Comparator ALP", "Comparator Bili", "Flow Rate At Lab Time", "Systemic Chemotherapy Status", "TO Approved", "TO Approver", "TO Approved ON", "FUDR", "5-Fluorouracil (mg/m2)", "Leucovorin (mg/m2)", "Oxaliplatin (mg/m2)", "Irinotecan (mg/m2)", "Gemcitabine (mg/m2)", "Panitumumab (mg/kg)", "Hold Release Date", "Hep/NS Fill", "PR Volume", "Flow Rate At Chemo Time", "PR Days Since Last Fill", "PR Volume Min", "PR Volume Max", "CO Completed", "CO Approver", "CO Approved ON", "Comments"]];
        // CH-67
        // headers = [[""], ["Date Of Visit", "FUDR Treatment Day", "Weight (kg)", "WBC (10^3/ul)", "Hb (g/dL)", "PLT (10^3/ul)", "ANC (10^3/ul)", "AST (U/L)", "ALT (U/L)", "ALP (U/L)", "Bili (mg/dL)", "Albumin (g/dL)", "Creatinine (mg/dL)", "LDH (U/L)", "CEA (ng/mL)", "CA19-9 (U/mL)", "Max Dose %", "Today's HAI FUDR Dose", "Today's HAI FUDR Dose %", "Today's HAI Dose Status", "Ideal Body Weight (kg)", "Ideal Average Weight (kg)", "Correct Dosing Body Weight (kg)", "Full Dose", "Reference AST", "Reference ALP", "Reference Bili", "Comparator AST", "Comparator ALP", "Comparator Bili", "Flow Rate At Lab Time", "Systemic Chemotherapy Status", "TO Approved", "TO Approver", "TO Approved ON", "FUDR", "5-Fluorouracil (mg/m2)", "Leucovorin (mg/m2)", "Oxaliplatin (mg/m2)", "Irinotecan (mg/m2)", "Gemcitabine (mg/m2)", "Panitumumab (mg/kg)", "Hold Release Date", "Hep/NS Fill", "PR Volume", "Flow Rate At Chemo Time", "PR Days Since Last Fill", "PR Volume Min", "PR Volume Max", "CO Completed", "CO Approver", "CO Approved ON", "Comments"]];
        // CH-68
        headers = [[""], ["Date Of Visit", "Treatment Holiday", "FUDR Treatment Day", "Weight (kg)", "WBC (10^3/ul)", "Hb (g/dL)", "PLT (10^3/ul)", "ANC (10^3/ul)", "AST (U/L)", "ALT (U/L)", "ALP (U/L)", "Bili (mg/dL)", "Albumin (g/dL)", "Creatinine (mg/dL)", "LDH (U/L)", "CEA (ng/mL)", "CA19-9 (U/mL)", "Max Dose %", "Today's HAI FUDR Dose", "Today's HAI FUDR Dose %", "Today's HAI Dose Status", "Ideal Body Weight (kg)", "Ideal Average Weight (kg)", "Correct Dosing Body Weight (kg)", "Full Dose", "Reference AST", "Reference ALP", "Reference Bili", "Comparator AST", "Comparator ALP", "Comparator Bili", "Flow Rate At Lab Time", "Systemic Chemotherapy Status", "TO Approved", "TO Approver", "TO Approved ON", "FUDR", "5-Fluorouracil (mg/m2)", "Leucovorin (mg/m2)", "Oxaliplatin (mg/m2)", "Irinotecan (mg/m2)", "Gemcitabine (mg/m2)", "Panitumumab (mg/kg)", "Hold Release Date", "Hep/NS Fill", "PR Volume", "Flow Rate At Chemo Time", "PR Days Since Last Fill", "PR Volume Min", "PR Volume Max", "CO Completed", "CO Approver", "CO Approved ON", "Comments"]];
        headers.unshift([sheetTitle]);

        var options = {
            // origin: "A2",
            origin: "A4",
            skipHeader: true,
            // header: ["dateOfVisitFormatted", "treatment", "weight", "wbc", "hb", "plt", "anc", "ast", "alt", "alp", "bili", "albumin", "creatinine", "ldh", "cea", "cA19_9", "maximumDosePercentage", "todaysDose", "todaysDosePercentage", "todaysDoseStatus", "idealBodyWeight", "idealAverageWeight", "correctDosingBodyWeight", "fullDose", "reference_AST", "reference_ALP", "reference_Bili", "comparator_AST", "comparator_ALP", "comparator_Bili", "flowRateLabTime", "systemicChemotherapyStatus", "approved", "toApprover", "toApprovedOnFormatted", "fudrDisplay", "fluorouracilDisplay", "leucovorinDisplay", "oxaliplatinDisplay", "irinotecanDisplay", "gemcitabineDisplay", "panitumumabDisplay", "holdReleaseDateFormatted", "hepNSFill", "pumpResidualVolumeFormatted", "flowRateAtChemoTime", "pumpResidualDaysSinceLastFill", "pumpResidualVolumeMin", "pumpResidualVolumeMax", "coCompleted", "coApprover", "coApprovedOnFormatted", "comments"],
            // CH-68
            header: ["dateOfVisitFormatted", "treatmentHoliday", "treatment", "weight", "wbc", "hb", "plt", "anc", "ast", "alt", "alp", "bili", "albumin", "creatinine", "ldh", "cea", "cA19_9", "maximumDosePercentage", "todaysDose", "todaysDosePercentage", "todaysDoseStatus", "idealBodyWeight", "idealAverageWeight", "correctDosingBodyWeight", "fullDose", "reference_AST", "reference_ALP", "reference_Bili", "comparator_AST", "comparator_ALP", "comparator_Bili", "flowRateLabTime", "systemicChemotherapyStatus", "approved", "toApprover", "toApprovedOnFormatted", "fudrDisplay", "fluorouracilDisplay", "leucovorinDisplay", "oxaliplatinDisplay", "irinotecanDisplay", "gemcitabineDisplay", "panitumumabDisplay", "holdReleaseDateFormatted", "hepNSFill", "pumpResidualVolumeFormatted", "flowRateAtChemoTime", "pumpResidualDaysSinceLastFill", "pumpResidualVolumeMin", "pumpResidualVolumeMax", "coCompleted", "coApprover", "coApprovedOnFormatted", "comments"],
        };

        if (!isDashboard) {
            exportToExcel(this.state.excelExportData, filename, headers, options, workbookSheet)
        } else {
            var pivotData = pivot(this.state.excelExportData);
            var pvheader = pivotData.dateOfVisitFormatted;
            var pivotHeader = getPivotHeader(pvheader);
            var pivotDataArray = pivotArray(pivotData, pivotHeader, headers);

            headers = [[sheetTitle], [""], []];
            options.header = [];

            exportToExcel(pivotDataArray, filename, headers, options, workbookSheet)
        }
    }
    // CH-17 - Excel - end

    // CH-63 - begin
    handleFetch(isExportToExcel, isDashboard) {
        if (!isExportToExcel) {
            var patientId = 1;
            if (this.props.parentComp === "PatientModal") {
                if (this.props.patient && this.props.patient.id) {
                    patientId = this.props.patient.id;
                }
            }
            let fetchUrl;
            fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "?patientId=" + patientId +
                "&pageSize=1&currentPage=1&orderby=id&orderByDirection=desc";
    
            logRequest("GET", "handleFetchLastRecord", this.entity, this.compClass, fetchUrl);
    
            fetch(fetchUrl, {
                method: "GET",
                headers: {
                    ACCEPT: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
                },
            })
                .then((response) => {
                    // error handling
                    if (response.ok) {
                        return response.json();
                    } else {
                        this.setState({ fetchResponseStatusCode: response.status });
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    logSuccessResponse("GET", "handleFetchLastRecord", this.entity, this.compClass, data);
                    var moment = require("moment");
    
                    if (data && data.length === 1) {
                        var lastDateOfVisit = data[0].dateOfVisit ? moment(data[0].dateOfVisit).format("l") : null;
                        var lastId = data[0].id ? data[0].id : null;
                        var allowAdd = (data[0].coCompleted && data[0].coCompleted === 'Yes') ? true : false;
    
                        this.setState({
                            lastDateOfVisit: lastDateOfVisit,
                            lastId: lastId,
                            allowAdd: allowAdd,
                        },
                        () => {         // CH-68
                            this.props.onLastDateOfVisit(lastDateOfVisit);
                        });
                    } else {
                        this.setState({
                            lastDateOfVisit: null,
                            lastId: null,
                            allowAdd: true,
                        });
                    }
                    this.handleFetch_(isExportToExcel, isDashboard);
                })
                .catch((error) => {
                    logErrorResponse("GET", "handleFetchLastRecord", this.entity, this.compClass, error);
                    toastError();
    
                    this.setState({
                        lastDateOfVisit: null,
                        lastId: null,
                        allowAdd: true,
                    });
                });
        } else {
            this.handleFetch_(isExportToExcel, isDashboard);
        }
    }
    // CH-63 - end

    handleFetch_(isExportToExcel, isDashboard) {
        var patientId = 1;
        if (this.props.parentComp === "PatientModal") {
            if (this.props.patient && this.props.patient.id) {
                patientId = this.props.patient.id;
            }
        }

        // CH-17 - Excel - begin
        let fetchUrl;
        if (!isExportToExcel) {
            this.setState({ isLoading: true });
            fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "?patientId=" + patientId +
                "&pageSize=" + this.state.itemsPerPage + "&currentPage=" + this.state.currentPage;
        } else {
            this.setState({ isProcessingExcelExport: true });
            fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "?patientId=" + patientId +
                "&pageSize=1000&currentPage=1";
        }
        // CH-17 - Excel - end

        if (this.state.sortDescending) {
            // fetchUrl = fetchUrl + "&orderby=" + this.state.sortedBy + " desc";
            fetchUrl = fetchUrl + "&orderby=" + this.state.sortedBy + "&orderByDirection=desc";
        } else {
            fetchUrl = fetchUrl + "&orderby=" + this.state.sortedBy;
        }

        if (this.state.search) {
            fetchUrl = fetchUrl + "&search=" + this.state.search;
        }

        logRequest("GET", "handleFetch", this.entity, this.compClass, fetchUrl);

        fetch(fetchUrl, {
            method: "GET",
            headers: {
                ACCEPT: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
            },
        })
            .then((response) => {
                // error handling
                if (response.ok) {
                    // Read pagination from the header
                    let pagination = JSON.parse(response.headers.get("x-pagination"));
                    this.setState({
                        currentPage: pagination.currentPage,
                        fetchResponseStatusCode: response.status,
                        totalPages: pagination.totalPages,
                        // totalResults: pagination.totalResults,
                        totalItems: pagination.totalItems,
                    });
                    return response.json();
                } else {
                    this.setState({ fetchResponseStatusCode: response.status });
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                logSuccessResponse("GET", "handleFetch", this.entity, this.compClass, data);
                // for date formatting
                var moment = require("moment");
                const { DateTime } = require("luxon");

                // create formatted dob property
                data.forEach(function (element) {
                    if (element.dateOfVisit) {
                        element.dateOfVisitFormatted = moment(element.dateOfVisit).format("l");
                    } else {
                        element.dateOfVisitFormatted = "";
                    }
                    if (element.dateOfNextVisit) {
                        element.dateOfNextVisitFormatted = moment(element.dateOfNextVisit).format("l");
                    } else {
                        element.dateOfNextVisitFormatted = "";
                    }
                    if (element.holdReleaseDate) {
                        element.holdReleaseDateFormatted = moment(element.holdReleaseDate).format("l");
                    } else {
                        element.holdReleaseDateFormatted = "";
                    }
                    // CH-40
                    if (element.fudrHold) {
                        element.fudrHoldFormatted = "CO Hold";
                    } else {
                        element.fudrHoldFormatted = "";
                    }
                    // CH-40
                    if (element.pumpResidualVolume) {
                        if (element.residualVolumeOutOfRange) {
                            element.pumpResidualVolumeFormatted = element.pumpResidualVolume.toString() + " - Out Of Range";
                        } else {
                            element.pumpResidualVolumeFormatted = element.pumpResidualVolume.toString();
                        }
                    } else {
                        element.pumpResidualVolumeFormatted = "";
                    }
                    if (element.toApprovedOn) {
                        element.toApprovedOnFormatted = DateTime.fromISO(element.toApprovedOn).toLocaleString(DateTime.DATETIME_SHORT)
                    } else {
                        element.toApprovedOnFormatted = "";
                    }
                    if (element.coApprovedOn) {
                        element.coApprovedOnFormatted = DateTime.fromISO(element.coApprovedOn).toLocaleString(DateTime.DATETIME_SHORT)
                    } else {
                        element.coApprovedOnFormatted = "";
                    }      
                });

                /*
                // CH-63
                var lastDateOfVisit = moment(new Date(Math.max(...data.map(e => new Date(e.dateOfVisit))))).format("l");

                var lastId = Math.max(...data.map(e => e.id));          // CH-24

                // allowAdd button
                var allowAdd = true;
                if (lastId)
                    var lastRecord = data.find((e) => { return e.id === lastId; })
                if (lastRecord && lastRecord.coCompleted)
                    allowAdd = (lastRecord.coCompleted === 'Yes') ? true : false;
                // allowAdd button
                */

                // CH-65 - begin
                const dashboardRaws_ = dashboardRaws.map((r) => { r.isHidden = false; return r; })
                    .map((raw) => {
                        if (raw.property === "cea" && data.every(d => isEmpty(d.cea))) {
                            raw.isHidden = true;
                        }
                        if (raw.property === "cA19_9" && data.every(d => isEmpty(d.cA19_9))) {
                            raw.isHidden = true;
                        }

                        if (raw.property === "fluorouracilDisplay" && data.every(d => isEmpty(d.fluorouracilDisplay))) {
                            raw.isHidden = true;
                        }
                        if (raw.property === "leucovorinDisplay" && data.every(d => isEmpty(d.leucovorinDisplay))) {
                            raw.isHidden = true;
                        }
                        if (raw.property === "oxaliplatinDisplay" && data.every(d => isEmpty(d.oxaliplatinDisplay))) {
                            raw.isHidden = true;
                        }
                        if (raw.property === "irinotecanDisplay" && data.every(d => isEmpty(d.irinotecanDisplay))) {
                            raw.isHidden = true;
                        }
                        if (raw.property === "gemcitabineDisplay" && data.every(d => isEmpty(d.gemcitabineDisplay))) {
                            raw.isHidden = true;
                        }
                        if (raw.property === "panitumumabDisplay" && data.every(d => isEmpty(d.panitumumabDisplay))) {
                            raw.isHidden = true;
                        }
                        if (raw.property === "capecitabineDisplay" && data.every(d => isEmpty(d.capecitabineDisplay))) {
                            raw.isHidden = true;
                        }
                        return raw;
                    });
                // CH-65 - end

                // CH-17 - Excel - begin
                if (!isExportToExcel) {
                    this.setState({
                        isLoading: false,
                        isSearching: false,
                        isTableDisabled: false,
                        records: data || [],
                        dashboardRaws: dashboardRaws_,          // CH-65
                    }, () => {                                  // CH-65                 
                        if (this.state.lastId && this.state.selectedRows.length === 0) {
                            setTimeout(() => {
                                this.highlightLastRowsCols();
                            }, 500);
                        }
                    });

                    // Update the selected record
                    if (this.state.selectedRows.length > 0 && data.length > 0) {
                        let selectedRow = this.state.selectedRows[0];
                        var selectedRecordValue = {};
                        selectedRecordValue = data.filter(function (record) {
                            return record.id === selectedRow.uid;
                        })[0];
                        this.setState({
                            selectedRecord: selectedRecordValue,
                        });
                    }
                    // Handle select last record
                    if (this.state.isHandleSelectLast) {
                        this.setState({
                            isHandleSelectLast: false,
                        },
                            () => {
                                if (this.state.id) {
                                    this.handleSetSelectedRows([{ uid: this.state.id }]);
                                }
                            });
                    }
                } else {
                    var excelExportData = [];
                    excelExportData = data.map((d) => {
                        return Object.assign(
                            { dateOfVisitFormatted: d.dateOfVisitFormatted },
                            { treatment: d.treatment },

                            { weight: d.weight },
                            { wbc: d.wbc },
                            { hb: d.hb },
                            { plt: d.plt },
                            { anc: d.anc },
                            { ast: d.ast },
                            { alt: d.alt },
                            { alp: d.alp },
                            { bili: d.bili },
                            { albumin: d.albumin },
                            { creatinine: d.creatinine },
                            { ldh: d.ldh },
                            { cea: d.cea },
                            { cA19_9: d.cA19_9 },

                            { maximumDosePercentage: d.maximumDosePercentage },
                            { todaysDose: d.todaysDose },
                            { todaysDosePercentage: d.todaysDosePercentage },
                            { todaysDoseStatus: d.todaysDoseStatus },

                            { idealBodyWeight: d.idealBodyWeight },
                            { idealAverageWeight: d.idealAverageWeight },
                            { correctDosingBodyWeight: d.correctDosingBodyWeight },
                            { fullDose: d.fullDose },

                            { reference_AST: d.reference_AST },
                            { reference_ALP: d.reference_ALP },
                            { reference_Bili: d.reference_Bili },
                            { comparator_AST: d.comparator_AST },
                            { comparator_ALP: d.comparator_ALP },
                            { comparator_Bili: d.comparator_Bili },
                            { flowRateLabTime: d.flowRateLabTime },
                            { systemicChemotherapyStatus: d.systemicChemotherapyStatus },

                            { approved: d.approved },
                            { toApprover: d.toApprover },
                            { toApprovedOnFormatted: d.toApprovedOnFormatted },

                            { fudrDisplay: d.fudrDisplay },
                            { fluorouracilDisplay: d.fluorouracilDisplay },
                            { leucovorinDisplay: d.leucovorinDisplay },
                            { oxaliplatinDisplay: d.oxaliplatinDisplay },
                            { irinotecanDisplay: d.irinotecanDisplay },
                            { gemcitabineDisplay: d.gemcitabineDisplay },
                            { panitumumabDisplay: d.panitumumabDisplay },

                            { holdReleaseDateFormatted: d.holdReleaseDateFormatted },
                            { hepNSFill: d.hepNSFill },
                            { pumpResidualVolumeFormatted: d.pumpResidualVolumeFormatted },

                            { flowRateAtChemoTime: d.flowRateAtChemoTime },
                            { pumpResidualDaysSinceLastFill: d.pumpResidualDaysSinceLastFill },
                            { pumpResidualVolumeMin: d.pumpResidualVolumeMin },
                            { pumpResidualVolumeMax: d.pumpResidualVolumeMax },

                            { coCompleted: d.coCompleted },
                            { coApprover: d.coApprover },
                            { coApprovedOnFormatted: d.coApprovedOnFormatted },
                            { comments: d.comments },
                        );
                    });

                    this.setState(
                        {
                            isLoading: false,
                            isSearching: false,
                            isTableDisabled: false,
                            excelExportData: excelExportData || [],
                        },
                        () => {
                            if (excelExportData && excelExportData.length > 0) {
                                this.handleExcelExport(isDashboard);
                                setTimeout(() => {
                                    this.setState({ isProcessingExcelExport: false });
                                }, 2000);
                            } else {
                                this.setState({ isProcessingExcelExport: false });
                            }
                        }
                    )
                }
                // CH-17 - Excel - end
            })
            .catch((error) => {
                logErrorResponse("GET", "handleFetch", this.entity, this.compClass, error);
                toastError();

                this.setState({
                    isProcessingExcelExport: false,     // CH-17 - Excel
                    isLoading: false,
                    isSearching: false,
                    isTableDisabled: false,
                    isHandleSelectLast: false,
                    records: [],
                    totalPages: 0,
                    // totalResults: 0,
                    totalItems: 0,

                    lastDateOfVisit: null,
                    lastId: null,                       // CH-24
                    allowAdd: true,                     // allowAdd button
                });
            });
    }

    // CRUD Handle - begin
    handleAddButtonClick() {
        if (this.props.parentComp === "PatientModal") {
            this.props.onVisitsButtonClick(null, "add");
        } else if (this.props.parentComp === "App") {
            this.setState(
                {
                    visitAddModalShow: true,
                },
                () => {
                    $("#visitModal").modal("show");
                }
            );
        }
    }

    handleEditButtonClick() {
        let selectedRows = this.state.selectedRows;
        let selectedRecord = this.state.records.filter(function (record) {
            return record.id === selectedRows[0].uid;
        })[0];

        if (this.props.parentComp === "PatientModal") {
            this.props.onVisitsButtonClick(selectedRecord, "edit");
        } else if (this.props.parentComp === "App") {
            this.setState(
                {
                    visitEditModalShow: true,
                    selectedRecord: selectedRecord,
                },
                () => {
                    $("#visitModal").modal("show");
                }
            );
        }
    }

    handleViewButtonClick() {
        let selectedRows = this.state.selectedRows;
        let selectedRecord = this.state.records.filter(function (record) {
            return record.id === selectedRows[0].uid;
        })[0];

        if (this.props.parentComp === "PatientModal") {
            this.props.onVisitsButtonClick(selectedRecord, "view");
        } else if (this.props.parentComp === "App") {
            this.setState(
                {
                    visitViewModalShow: true,
                    selectedRecord: selectedRecord,
                },
                () => {
                    $("#visitModal").modal("show");
                }
            );
        }
    }

    // handleApproveButtonClick() {
    handleTOApprovalClick() {                                                     // TO Approval (Tertiary Oncologist Approval)  -  CH-32
        let selectedRows = this.state.selectedRows;
        let selectedRecord = this.state.records.filter(function (record) {
            return record.id === selectedRows[0].uid;
        })[0];

        if (this.props.parentComp === "PatientModal") {
            // this.props.onVisitsButtonClick(selectedRecord, "approve");
            this.props.onVisitsButtonClick(selectedRecord, "to-approval");        // TO Approval (Tertiary Oncologist Approval)  -  CH-32
        } else if (this.props.parentComp === "App") {
            this.setState(
                {
                    // visitApproveModalShow: true,
                    visitTOApprovalModalShow: true,                               // TO Approval (Tertiary Oncologist Approval)  -  CH-32
                    selectedRecord: selectedRecord,
                },
                () => {
                    $("#visitModal").modal("show");
                }
            );
        }
    }

    handleCOApprovalClick() {                                                     // CO Approval (Community Oncologist Approval) -  CH-32
        let selectedRows = this.state.selectedRows;
        let selectedRecord = this.state.records.filter(function (record) {
            return record.id === selectedRows[0].uid;
        })[0];

        if (this.props.parentComp === "PatientModal") {
            this.props.onVisitsButtonClick(selectedRecord, "co-approval");        // CO Approval (Community Oncologist Approval) -  CH-32
        } else if (this.props.parentComp === "App") {
            this.setState(
                {
                    visitCOApprovalModalShow: true,                               // CO Approval (Community Oncologist Approval) -  CH-32
                    selectedRecord: selectedRecord,
                },
                () => {
                    $("#visitModal").modal("show");
                }
            );
        }
    }

    // CRUD Handle - edit

    // Delete - begin
    handleDelete() {
        let deleteMessage = this.getDeleteMessage();

        if (this.state.selectedRecord && this.state.selectedRecord.id) {

            let id = this.state.selectedRecord.id;
            // let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "/" + id;      // CH-63
            let fetchUrl = window.REACT_APP_API_URL + "/" + this.controller + "/hard/" + id;
            logRequest("DELETE", "handleDelete", this.entity, this.compClass, fetchUrl, this.state.selectedRecord);

            // Delete the record
            fetch(fetchUrl, {
                method: "DELETE",
                headers: {
                    ACCEPT: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.user.apiToken.accessToken}`,
                },
            })
                .then((response) => {
                    // error handling
                    if (response.ok) {
                        return response;
                    }
                    throw new Error(response.status)
                })
                .then((data) => {
                    //alert("Record Deleted Sucessfully");
                    logSuccessResponse("DELETE", "handleDelete", this.entity, this.compClass, data);
                    toastSuccessDelete(deleteMessage);
                    return true;
                })
                .then(this.setState({ selectedRows: [], selectedRecord: {} }))
                // .then(this.handleFetch)
                .then(() => this.handleFetch(false, false))
                .catch((error) => {
                    //alert("Error Deleting Record: " + error);
                    logErrorResponse("DELETE", "handleDelete", this.entity, this.compClass, error);
                    toastError();
                    return false;
                });
        }
    }


    getDeleteMessage() {
        var moment = require("moment");
        return (
            <>
                {this.entity} # <span className="text-primary">{this.state.selectedRecord.id}</span>
                , Weight: <span className="text-primary">{this.state.selectedRecord.weight}</span>
                , Base line AST: <span className="text-primary">{this.state.selectedRecord.baselineAST}</span>
                , ALP: <span className="text-primary">{this.state.selectedRecord.alp}</span>
                , Bili: <span className="text-primary">{this.state.selectedRecord.bili}</span>
                , Date Of Visit: <span className="text-primary">{moment(this.state.selectedRecord.dateOfVisit).format("l")}</span>
            </>
        )
    }

    handleDeleteButtonClick() {
        let selectedRows = this.state.selectedRows;
        let selectedRecord = this.state.records.filter(function (record) {
            return record.id === selectedRows[0].uid;
        })[0];

        this.setState(
            {
                deleteConfirmationModalShow: true,
                selectedRecord: selectedRecord,
            },
            () => {
                $("#deleteConfirmationModal").modal("show");
            }
        );
    }

    handleDeleteConfirmationModalClose() {
        this.setState({ deleteConfirmationModalShow: false }, () => { });
    }
    // Delete - end


    handleSetSortedBy(field) {
        // console.log(field);
        this.setState(
            {
                currentPage: 1,
                isTableDisabled: true,
                sortedBy: field,
            },
            () => {
                this.handleFetch();
            }
        );
    }

    handleSetSortDescending(boolValue) {
        this.setState(
            {
                currentPage: 1,
                isTableDisabled: true,
                sortDescending: boolValue,
            },
            () => {
                this.handleFetch();
            }
        );
    }

    handlePageForward() {
        var newPage;
        if (this.state.currentPage >= this.state.totalPages) {
            newPage = this.state.totalPages;
        } else {
            newPage = this.state.currentPage + 1;
        }
        this.setState(
            {
                currentPage: newPage,
            },
            () => {
                this.handleFetch();
            }
        );
    }

    handlePageBack() {
        var newPage;
        if (this.state.currentPage < 2) {
            newPage = 1;
        } else {
            newPage = this.state.currentPage - 1;
        }
        this.setState(
            {
                currentPage: newPage,
            },
            () => {
                this.handleFetch();
            }
        );
    }

    handleSetPage(newPage) {
        this.setState(
            {
                currentPage: newPage,
                selectedRecord: {},
                selectedRows: [],
            },
            () => {
                this.handleFetch();
            }
        );
    }

    handleSetItemsPerPage(newLimit) {
        this.setState(
            {
                itemsPerPage: newLimit,
                currentPage: 1,
            },
            () => {
                this.handleFetch();
            }
        );
    }

    handleSearch(newSearch) {
        this.setState(
            {
                isSearching: true,
                search: newSearch,
                currentPage: 1,
            },
            () => {
                this.handleFetch();
            }
        );
    }

    handleSetRecordsFilter(newFilter) {
        this.setState(
            {
                recordsFilter: newFilter,
                currentPage: 1,
            },
            () => {
                this.handleFetch();
            }
        );
    }

    handleSetSelectedRows(rows) {
        if (rows && rows.length > 0) {
            var selectedRecord = {};
            if (rows.length > 0) {
                selectedRecord = this.state.records.filter(function (record) {
                    return record.id === rows[0].uid;
                })[0];
            }
            if (window.REACT_APP_DEBUG)
                console.log("handleSetSelectedRows:" + selectedRecord);
            this.setState({
                selectedRows: rows,
                selectedRecord: selectedRecord,
            }, () => {                          // CH-59
                if (this.state.lastId && rows.length > 0 && rows[0].uid !== this.state.lastId) {
                    this.highlightLastRowsCols();
                }
            });
        } else {
            this.setState({
                selectedRows: [],
                selectedRecord: {},
            }, () => {                          // CH-59
                this.highlightLastRowsCols();
            });
        }
    }

    handleOnChange = (name, value) => {
        // console.log("name: " + name + " value: " + value);
        // this.props.onUpdateForm(name, value);
        this.setState({ [name]: value });
    }

    // CH-59
    highlightLastRowsCols = () => {
        if (this.state.lastId) {
            if ((this.props.action !== "dashboard")) {
                let TableRowLastId = $("#TableRow" + this.state.lastId);
                if (TableRowLastId && TableRowLastId.length > 0) {
                    TableRowLastId.addClass('bg-lightyellow');
                }
            } else {
                let tableCell = 'TableCell' + this.state.lastId;
                let TableCellLastId = $(`td[id$=${tableCell}]`);
                if (TableCellLastId && TableCellLastId.length > 0) {
                    TableCellLastId.addClass('bg-lightyellow');
                }
            }
        }
    }

    render() {
        // CH-24 - begin
        // CH-59
        /*
        if (this.state.lastId && $("#TableRow" + this.state.lastId)) {
            setTimeout(() => {
                if ($("#TableRow" + this.state.lastId).length > 0) {
                    $("#TableRow" + this.state.lastId).addClass('bg-warning');;
                }
            }, 200);
        }
        */
        // return record.id === rows[0].uid;
        // CH-24 - end

        let visitAddModalClose = () =>
            this.setState({ visitAddModalShow: false }, () => {
                // this.handleFetch();
            });
        let visitEditModalClose = () =>
            this.setState({ visitEditModalShow: false }, () => {
                // this.handleFetch();
            });
        let visitViewModalClose = () =>
            this.setState({ visitViewModalShow: false }, () => {
                // this.handleFetch();
            });

        // let visitApproveModalClose = () =>
        let visitTOApprovalModalClose = () =>                               // TO Approval (Tertiary Oncologist Approval)  -  CH-32
            this.setState({ visitTOApprovalModalShow: false }, () => {
                // this.handleFetch();
            });

        let visitCOApprovalModalClose = () =>                               // CO Approval (Community Oncologist Approval) -  CH-32
            this.setState({ visitCOApprovalModalShow: false }, () => {
                // this.handleFetch();
            });

        // get the selected records into an array
        var selectedRecords = [];
        for (const row of this.state.selectedRows) {
            let rowToAdd = this.state.records.filter(
                (record) => record[this.state.uidProperty] === row.uid
            )[0];
            if (rowToAdd) {
                selectedRecords.push(rowToAdd);
            }
        }

        var resultsCountString = "";
        // if (this.state.totalResults > 0) {
        if (this.state.totalItems > 0) {
            resultsCountString =
                this.state.currentPage * this.state.itemsPerPage -
                (this.state.itemsPerPage - 1) +
                "-" +
                (this.state.currentPage * this.state.itemsPerPage -
                    this.state.itemsPerPage +
                    this.state.records.length) +
                " of " +
                // this.state.totalResults;
                this.state.totalItems;
        } else {
            resultsCountString = "0 items";
        }


        var mainBody = <>
            <div className="row">

                <div className="col col-12 col-sm-4">
                    {!this.state.isTableDemoOnly && this.props.action !== "dashboard" && (          // CH-17
                        <Search
                            isSearching={this.state.isSearching}
                            onChange={this.handleSearch}
                            ref={ref => (this.searchRef = ref)}
                        />
                    )}

                    {!this.state.isTableDemoOnly && this.props.action === "dashboard" && (          // CH-17 - Added top pagination
                        <div className="d-flex">
                            <ItemsPerPage
                                disabled={
                                    this.state.isTableDisabled ||
                                    this.state.fetchResponseStatusCode > 399
                                }
                                onChange={this.handleSetItemsPerPage}
                                options={this.state.itemsPerPageOptions}
                                value={this.state.itemsPerPage}
                            />
                            <div className="ml-3"></div>
                            <Pagination
                                currentPage={this.state.currentPage}
                                disabled={
                                    this.state.isTableDisabled ||
                                    this.state.fetchResponseStatusCode > 399
                                }
                                onChange={this.handleSetPage}
                                totalPages={Math.ceil(
                                    // this.state.totalResults / this.state.itemsPerPage
                                    this.state.totalItems / this.state.itemsPerPage
                                )}
                            />

                        </div>
                    )}
                </div>

                <div className="col col-12 col-sm-8 text-right pr-2">
                    <div className="d-inline-flex">

                        {/*
                        // =========================================================

                        // =========================================================
                        */}
                        {this.state.selectedRows.length !== 1 &&
                            (this.props.user.isUser || this.props.user.isAdministrator) && (this.props.action !== "view") &&
                            (this.state.allowAdd) && (                                  // allowAdd button
                                <button
                                    type="button"
                                    id="toolbarAddButton"
                                    className="btn btn-link btn-sm"
                                    onClick={this.handleAddButtonClick}
                                    style={{ fontSize: 13, color: "#0067d5" }}
                                >
                                    <i className="bi bi-folder-plus"></i> Add
                                </button>
                            )}

                        {this.state.selectedRows.length === 1 && (
                            <button
                                type="button"
                                id="toolbarViewButton"
                                className="btn btn-link btn-sm mr-2"
                                onClick={this.handleViewButtonClick}
                                style={{ fontSize: 13, color: "#0067d5" }}
                            >
                                <i className="bi bi-card-list"></i> View
                            </button>
                        )}

                        {/*
                        // =========================================================

                        // =========================================================
                        */}
                        {this.state.selectedRows.length === 1 &&
                            (this.props.user.isUser || this.props.user.isAdministrator) && (this.props.action !== "view") &&
                            // (this.state.selectedRecord.approved !== "Yes") &&
                            // (this.state.selectedRecord.approved !== "No") &&
                            (this.state.selectedRecord.allowEdit) &&
                            (this.state.selectedRecord.dateOfVisitFormatted === this.state.lastDateOfVisit) && (
                                <button
                                    type="button"
                                    id="toolbarEditButton"
                                    className="btn btn-link btn-sm mr-2"
                                    onClick={this.handleEditButtonClick}
                                    style={{ fontSize: 13, color: "#0067d5" }}
                                >
                                    <i className="bi bi-pencil"></i> Edit
                                </button>
                            )}

                        {/*
                        // =========================================================
                        // CH-19
                        // =========================================================
                        */}
                        {this.state.selectedRows.length === 1 &&
                            (this.props.user.isUser || this.props.user.isAdministrator) && (this.props.action !== "view") &&
                            // (this.state.selectedRecord.treatment === "Yes") &&
                            // (this.state.selectedRecord.approved !== "Yes") &&
                            // (this.state.selectedRecord.approved !== "No") &&
                            (this.state.selectedRecord.allowTOApprove) &&
                            (this.state.selectedRecord.dateOfVisitFormatted === this.state.lastDateOfVisit) && (
                                <button
                                    type="button"
                                    id="toolbarEditButton"
                                    className="btn btn-link btn-sm mr-2"
                                    // onClick={this.handleApproveButtonClick}
                                    onClick={this.handleTOApprovalClick}             // TO Approval (Tertiary Oncologist Approval)  -  CH-32
                                    style={{ fontSize: 13, color: "#0067d5" }}
                                >
                                    {/* TO Approval (Tertiary Oncologist Approval)  -  CH-32*/}
                                    <i className="bi bi-hand-thumbs-up"></i> TO Approve
                                </button>
                            )}

                        {/*
                        // =========================================================
                        // CH-32
                        // =========================================================
                        */}
                        {this.state.selectedRows.length === 1 &&
                            (this.props.user.isUser || this.props.user.isAdministrator) && (this.props.action !== "view") &&
                            // (this.state.selectedRecord.treatment === "Yes") &&
                            // (this.state.selectedRecord.approved === "Yes") &&
                            (this.state.selectedRecord.coCompleted !== "Yes") &&            // CH-44
                            (this.state.selectedRecord.allowCOApprove) &&
                            (this.state.selectedRecord.dateOfVisitFormatted === this.state.lastDateOfVisit) && (
                                <button
                                    type="button"
                                    id="toolbarEditButton"
                                    className="btn btn-link btn-sm mr-2"
                                    onClick={this.handleCOApprovalClick}             // CO Approval (Community Oncologist Approval) -  CH-32
                                    style={{ fontSize: 13, color: "#0067d5" }}
                                >
                                    {/* CO Approval (Community Oncologist Approval) -  CH-32*/}
                                    <i className="bi bi-hand-thumbs-up"></i> CO Approve
                                </button>
                            )}

                        {/*
                        // =========================================================
                        // CH-63
                        // =========================================================
                        */}
                        {this.state.selectedRows.length === 1 &&
                            (this.props.user.isAdministrator) &&
                            (this.state.selectedRecord.dateOfVisitFormatted === this.state.lastDateOfVisit) && (
                                <button
                                    type="button"
                                    id="toolbarDeleteButton"
                                    className="btn btn-link btn-sm mr-2"
                                    onClick={this.handleDeleteButtonClick}
                                    style={{ fontSize: 13, color: "#0067d5" }}
                                >
                                    <i className="bi bi-trash"></i> Delete
                                </button>
                            )}

                        {(this.props.user.isUser || this.props.user.isAdministrator) && (this.props.action !== "dashboard") && (
                            <Button
                                type="button"
                                text={"Export to Excel"}
                                loadingText={"Export to Excel"}
                                icon="bi-file-earmark-excel-fill"
                                size="small"
                                variant="link"
                                classes="mr-2"
                                onClick={() => { this.handleFetch(true, false) }}              // CH-17 - Excel
                                isLoading={this.state.isProcessingExcelExport}
                            />
                        )}

                        {(this.props.user.isUser || this.props.user.isAdministrator) && (this.props.action === "dashboard") && (
                            <Button
                                type="button"
                                text={"Export to Excel"}
                                loadingText={"Export to Excel"}
                                icon="bi-file-earmark-excel-fill"
                                size="small"
                                variant="link"
                                classes="mr-2"
                                onClick={() => { this.handleFetch(true, true) }}              // CH-17 - Excel
                                isLoading={this.state.isProcessingExcelExport}
                            />
                        )}

                    </div>
                </div>

            </div>
            <div className="row pt-3">
                <div className="col overflow-auto">
                    {/* CH-17 */}
                    {(this.props.action !== "dashboard") && (
                        <Table
                            checkboxSelectOnly={false}
                            columns={tableColumns}
                            disabled={this.state.isTableDisabled}
                            handleSetSelectedRows={this.handleSetSelectedRows}
                            handleSetSortedBy={this.handleSetSortedBy}
                            handleSetSortDescending={this.handleSetSortDescending}
                            isBordered={false}
                            isLoading={this.state.isLoading}
                            isMultiSelect={false}
                            isSmall={true}
                            records={this.state.records}
                            responseStatusCode={this.state.fetchResponseStatusCode}
                            selectedRows={this.state.selectedRows}
                            sortedBy={this.state.sortedBy}
                            sortDescending={this.state.sortDescending}
                            uidProperty={this.state.uidProperty}
                            useHoverableRows={true}
                            useStripedRows={false}
                        />
                    )}
                    {/* CH-17 */}
                    {(this.props.action === "dashboard") && (
                        <TableDashboard
                            checkboxSelectOnly={false}
                            columns={this.state.dashboardRaws}
                            disabled={this.state.isTableDisabled}
                            handleSetSelectedRows={this.handleSetSelectedRows}
                            handleSetSortedBy={this.handleSetSortedBy}
                            handleSetSortDescending={this.handleSetSortDescending}
                            isBordered={true}
                            isLoading={this.state.isLoading}
                            isMultiSelect={false}
                            isSmall={true}

                            isHeadingUppercase={false}                     // CH-17
                            isHeadingBold={true}                           // CH-17

                            records={this.state.records}
                            responseStatusCode={this.state.fetchResponseStatusCode}
                            selectedRows={this.state.selectedRows}
                            sortedBy={this.state.sortedBy}
                            sortDescending={this.state.sortDescending}
                            uidProperty={this.state.uidProperty}
                            useHoverableRows={true}
                            useStripedRows={true}
                        />
                    )}
                </div>
            </div>
            <hr className="my-0 py-0" />
            <div className="row no-gutters">
                {/* <div className="col mt-1"> */}
                <div className="col-6 col-sm-4 mt-1">
                    <ItemsPerPage
                        disabled={
                            this.state.isTableDisabled ||
                            this.state.fetchResponseStatusCode > 399
                        }
                        onChange={this.handleSetItemsPerPage}
                        options={this.state.itemsPerPageOptions}
                        value={this.state.itemsPerPage}
                    />
                </div>
                {/* <div className="col align-middle"> */}
                <div className="col-6 col-sm-4 d-flex justify-content-end justify-content-sm-center">
                    <div>
                        <center>
                            <div className="mt-2 text-muted">
                                {resultsCountString}
                            </div>
                        </center>
                    </div>
                </div>
                {/* <div className="col d-flex justify-content-end py-1"> */}
                <div className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-end">
                    <Pagination
                        currentPage={this.state.currentPage}
                        disabled={
                            this.state.isTableDisabled ||
                            this.state.fetchResponseStatusCode > 399
                        }
                        onChange={this.handleSetPage}
                        totalPages={Math.ceil(
                            // this.state.totalResults / this.state.itemsPerPage
                            this.state.totalItems / this.state.itemsPerPage
                        )}
                    />
                </div>
            </div>
        </>

        return (
            <>


                {!this.state.isModal ? (
                    <>
                        <div className="container-fluid mt-2">

                            {/* CH-17 */}
                            {(this.props.action !== "dashboard") && (
                                <>
                                    {(this.props.parentComp === "PatientModal") && (
                                        <div className="row no-gutters mb-2">
                                            <div className="col bg-light border pt-2 mt-2">
                                                <h4><i className="bi bi-folder mx-2"></i>Visits</h4>
                                            </div>
                                        </div>
                                    )}

                                    {(this.props.parentComp === "App") && (
                                        <div className="row no-gutters mb-2 d-none d-lg-block d-xl-block">
                                            <div className="col mt-2">
                                                <h4>Visits</h4>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body">
                                            {mainBody}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className="modal fade"
                            id="visitIndexModal"
                            role="dialog"
                            aria-labelledby="visitIndexModalLabel"
                            aria-hidden="true"
                            data-backdrop="static"
                        >
                            <div className="modal-dialog modal-dialog-centered" role="document" style={{ minWidth: "90%" }}>
                                <div className="modal-content" style={{ minHeight: "35vh" }} >
                                    <div className="modal-header card-header py-2 my-2">

                                        <h5 className="modal-title" id="visitIndexModalLabel">
                                            {(this.state.modalEntity !== "Visit") && (
                                                <>
                                                    <i className="bi bi-person mr-2"></i> Find, Choose, Add, View, Edit, or Delete {this.state.modalEntity}
                                                </>
                                            )}
                                        </h5>

                                        <button
                                            type="button"
                                            id="closePersonsModalButton"
                                            className="close"
                                            onClick={this.handleCloseModal}
                                            aria-label="Close"
                                            aria-hidden="true"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                    </div>
                                    <div className="modal-body">
                                        {mainBody}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {this.state.visitAddModalShow && (
                    <VisitModal
                        onHide={visitAddModalClose}
                        onSelectLast={this.handleSelectLast}
                        show={this.state.visitAddModalShow}
                        // selectOptions={this.props.selectOptions}
                        selectionOptions={this.props.selectionOptions}
                        user={this.props.user}
                        patient={null}
                        lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                        disabled={false}
                        action={"add"}
                        parentComp={"Visits"}
                        modalEntity={"Visit"}
                    />
                )}
                {this.state.visitViewModalShow &&
                    this.state.selectedRecord !== null && (
                        <VisitModal
                            onHide={visitViewModalClose}
                            show={this.state.visitViewModalShow}
                            // selectOptions={this.props.selectOptions}
                            selectionOptions={this.props.selectionOptions}
                            user={this.props.user}
                            patient={null}
                            visit={this.state.selectedRecord}
                            lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                            disabled={true}
                            action={"view"}
                            parentComp={"Visits"}
                            modalEntity={"Visit"}
                        />
                    )}
                {this.state.visitEditModalShow &&
                    this.state.selectedRecord !== null && (
                        <VisitModal
                            onHide={visitEditModalClose}
                            onFetch={this.handleFetch}
                            show={this.state.visitEditModalShow}
                            // selectOptions={this.props.selectOptions}
                            selectionOptions={this.props.selectionOptions}
                            user={this.props.user}
                            patient={null}
                            visit={this.state.selectedRecord}
                            lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                            disabled={false}
                            action={"edit"}
                            parentComp={"Visits"}
                            modalEntity={"Visit"}
                        />
                    )}
                {/* {this.state.visitApproveModalShow && */}
                {this.state.visitTOApprovalModalShow &&                             //  TO Approval (Tertiary Oncologist Approval)  -  CH-32
                    this.state.selectedRecord !== null && (
                        <VisitModal
                            // onHide={visitApproveModalClose}
                            onHide={visitTOApprovalModalClose}
                            // show={this.state.visitApproveModalShow}
                            show={this.state.visitTOApprovalModalShow}
                            // selectOptions={this.props.selectOptions}
                            selectionOptions={this.props.selectionOptions}
                            user={this.props.user}
                            patient={null}
                            visit={this.state.selectedRecord}
                            lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                            disabled={true}
                            // action={"approve"}     
                            action={"to-approval"}                                  //  TO Approval (Tertiary Oncologist Approval)  -  CH-32
                            parentComp={"Visits"}
                            modalEntity={"Visit"}
                        />
                    )}
                {this.state.visitCOApprovalModalShow &&                             // CO Approval (Community Oncologist Approval) -  CH-32
                    this.state.selectedRecord !== null && (
                        <VisitModal
                            onHide={visitCOApprovalModalClose}
                            show={this.state.visitCOApprovalModalShow}
                            // selectOptions={this.props.selectOptions}
                            selectionOptions={this.props.selectionOptions}
                            user={this.props.user}
                            patient={null}
                            lastDateOfVisit={this.state.lastDateOfVisit}            // CH-68
                            visit={this.state.selectedRecord}
                            disabled={true}
                            action={"co-approval"}                                   // CO Approval (Community Oncologist Approval) -  CH-32
                            parentComp={"Visits"}
                            modalEntity={"Visit"}
                        />
                    )}

                {this.state.deleteConfirmationModalShow && this.state.selectedRecord !== null && (
                    <DeleteConfirmationModal
                        handleDelete={this.handleDelete}
                        onHide={this.handleDeleteConfirmationModalClose}
                        selectedRecords={[this.state.selectedRecord]}
                        confirmationText={this.getDeleteMessage()}
                    />
                )}
            </>
        );

    }
}

let tableColumns = [
    {
        name: "ID",
        property: "id",
        align: "center",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Date Of Visit",
        property: "dateOfVisitFormatted",
        sortableProperty: "dateOfVisit",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Treatment Holiday",      // CH-68
        property: "treatmentHoliday",
        sortable: true,
        nowrap: true,
    },
    {
        // name: "Treatment",           // CH-67 
        name: "FUDR Treatment Day",     // CH-67
        property: "treatment",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Approved",
        property: "approved",
        sortable: true,
        nowrap: true,
    },

    {
        name: "Max Dose %",
        property: "maximumDosePercentage",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Today's HAI FUDR Dose",
        property: "todaysDose",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Today's HAI FUDR Dose %",
        property: "todaysDosePercentage",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Today's HAI Dose Status",
        property: "todaysDoseStatus",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Systemic Chemotherapy Status",
        property: "systemicChemotherapyStatus",
        sortable: true,
        nowrap: true,
    },
    {
        name: "TO Approved",
        property: "approved",
        sortable: true,
        nowrap: true,
    },
    {
        name: "TO Approver",
        property: "toApprover",
        sortable: true,
        nowrap: true,
    },
    {
        name: "TO Approved ON",
        property: "toApprovedOnFormatted",
        sortable: true,
        sortableProperty: "toApprovedOn",
        nowrap: true,
    },
    {
        name: "FUDR",
        property: "fudrDisplay",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Fluorouracil",
        property: "fluorouracilDisplay",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Leucovorin",
        property: "leucovorinDisplay",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Oxaliplatin",
        property: "oxaliplatinDisplay",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Irinotecan",
        property: "irinotecanDisplay",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Gemcitabine",
        property: "gemcitabineDisplay",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Panitumumab",
        property: "panitumumabDisplay",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Ideal body weight",
        property: "idealBodyWeight",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Ideal Average Weight",
        property: "idealAverageWeight",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Correct Dosing Body Weight",
        property: "correctDosingBodyWeight",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Full Dose",
        property: "fullDose",
        sortable: true,
        nowrap: true,
    },

    {
        name: "Reference AST",
        property: "reference_AST",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Reference ALP",
        property: "reference_ALP",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Reference Bili",
        property: "reference_Bili",
        sortable: true,
        nowrap: true,
    },

    {
        name: "Comparator AST",
        property: "comparator_AST",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Comparator ALP",
        property: "comparator_ALP",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Comparator Bili",
        property: "comparator_Bili",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Flow Rate At Lab Time",
        property: "flowRateLabTime",
        sortable: true,
        nowrap: true,
    },
    {
        name: "PR Volume",
        property: "pumpResidualVolumeFormatted",
        sortable: true,
        sortableProperty: "pumpResidualVolume",
        nowrap: true,
    },
    {
        name: "Flow Rate At Chemo Time",
        property: "flowRateAtChemoTime",
        sortable: true,
        nowrap: true,
    },
    {
        name: "PR Days Since Last Fill",
        property: "pumpResidualDaysSinceLastFill",
        sortable: true,
        nowrap: true,
    },
    {
        name: "PR Volume Min",
        property: "pumpResidualVolumeMin",
        sortable: true,
        nowrap: true,
    },
    {
        name: "PR Volume Max",
        property: "pumpResidualVolumeMax",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Comments",
        property: "comments",
        sortable: true,
        nowrap: true,
    },

    {
        name: "CO Completed",
        property: "coCompleted",
        sortable: true,
        nowrap: true,
    },
    {
        name: "CO Approver",
        property: "coApprover",
        sortable: true,
        nowrap: true,
    },
    {
        name: "CO Approved ON",
        property: "coApprovedOnFormatted",
        sortable: true,
        sortableProperty: "coApprovedOn",
        nowrap: true,
    },
];


// CH-17 - begin
let dashboardRaws = [
    {
        name: "ID",
        property: "id",
        align: "center",
        // sortable: true,      // CH-55
        nowrap: true,
    },
    {
        name: "Date Of Visit",
        property: "dateOfVisitFormatted",
        sortableProperty: "dateOfVisit",
        sortable: true,
        nowrap: true,
    },
    {
        name: "Treatment Holiday",     // CH-68
        property: "treatmentHoliday",
        // sortable: true,
        nowrap: true,
    },
    {
        // name: "Treatment",           // CH-67 
        name: "FUDR Treatment Day",     // CH-67
        property: "treatment",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "Weight (kg)",
        property: "weight",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "WBC (10^3/ul)",
        property: "wbc",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "Hb (g/dL)",
        property: "hb",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "PLT (10^3/ul)",
        property: "plt",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "ANC (10^3/ul)",
        property: "anc",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "AST (U/L)",
        property: "ast",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "ALT (U/L)",
        property: "alt",
        // sortable: true,           // CH-55
        nowrap: true,
    },
    {
        name: "ALP (U/L)",
        property: "alp",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "Bili (mg/dL)",
        property: "bili",
        // sortable: true,           // CH-55
        nowrap: true,
    },
    {
        name: "Albumin (g/dL)",
        property: "albumin",
        // sortable: true,           // CH-55
        nowrap: true,
    },
    {
        name: "Creatinine (mg/dL)",
        property: "creatinine",
        // sortable: true,           // CH-55
        nowrap: true,
    },
    {
        name: "LDH (U/L)",
        property: "ldh",
        // sortable: true,           // CH-55
        nowrap: true,
    },
    {
        name: "CEA (ng/mL)",
        property: "cea",
        // sortable: true,           // CH-55
        nowrap: true,
        isHidden: false,             // CH-65 
    },
    {
        name: "CA19-9 (U/mL)",
        property: "cA19_9",
        // sortable: true,          // CH-55
        nowrap: true,
        isHidden: false,             // CH-65 
    },
    /*
    // CH-66 
    {
        name: "Max Dose %",
        property: "maximumDosePercentage",
        // sortable: true,           // CH-55
        nowrap: true,
    },
    */
    {
        name: "Today's HAI FUDR Dose",
        property: "todaysDose",
        // sortable: true,           // CH-55
        nowrap: true,
    },
    /* 
    // CH-66
    {
        name: "Today's HAI FUDR Dose %",
        property: "todaysDosePercentage",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    */
    {
        name: "Today's HAI Dose Status",
        property: "todaysDoseStatus",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    // CH-59
    // {
    //     name: "Ideal Body Weight (kg)",
    //     property: "idealBodyWeight",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    // CH-59
    // {
    //     name: "Ideal Average Weight (kg)",
    //     property: "idealAverageWeight",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    {
        name: "Correct Dosing Body Weight (kg)",
        property: "correctDosingBodyWeight",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    // CH-59
    // {
    //     name: "Full Dose",
    //     property: "fullDose",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    /*
    // CH-66
    {
        name: "Reference AST",
        property: "reference_AST",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "Reference ALP",
        property: "reference_ALP",
        // sortable: true,           // CH-55
        nowrap: true,
    },
    {
        name: "Reference Bili",
        property: "reference_Bili",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    */
    // CH-59
    // {
    //     name: "Comparator AST",
    //     property: "comparator_AST",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    // CH-59
    // {
    //     name: "Comparator ALP",
    //     property: "comparator_ALP",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    // CH-59
    // {
    //     name: "Comparator Bili",
    //     property: "comparator_Bili",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    // CH-59
    // {
    //     name: "Flow Rate At Lab Time",
    //     property: "flowRateLabTime",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    {
        name: "Systemic Chemotherapy Status",
        property: "systemicChemotherapyStatus",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "TO Approved",
        property: "approved",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    // CH-59
    // {
    //     name: "TO Approver",
    //     property: "toApprover",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    // CH-59
    // {
    //     name: "TO Approved ON",
    //     property: "toApprovedOnFormatted",
    //     // sortable: true,          // CH-55
    //     sortableProperty: "toApprovedOn",
    //     nowrap: true,
    // },
    /*
    // CH-66
    {
        name: "FUDR",
        property: "fudrDisplay",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    */
    {
        name: "5-Fluorouracil (mg/m2)",
        property: "fluorouracilDisplay",
        // sortable: true,          // CH-55
        nowrap: true,
        isHidden: false,            // CH-65
    },
    {
        name: "Leucovorin (mg/m2)",
        property: "leucovorinDisplay",
        // sortable: true,          // CH-55
        nowrap: true,
        isHidden: false,            // CH-65
    },
    {
        name: "Oxaliplatin (mg/m2)",
        property: "oxaliplatinDisplay",
        // sortable: true,          // CH-55
        nowrap: true,
        isHidden: false,            // CH-65
    },
    {
        name: "Irinotecan (mg/m2)",
        property: "irinotecanDisplay",
        // sortable: true,          // CH-55
        nowrap: true,
        isHidden: false,            // CH-65
    },
    {
        name: "Gemcitabine (mg/m2)",
        property: "gemcitabineDisplay",
        // sortable: true,         // CH-55
        nowrap: true,
        isHidden: false,           // CH-65
    },
    {
        name: "Panitumumab (mg/kg)",
        property: "panitumumabDisplay",
        // sortable: true,          // CH-55
        nowrap: true,
        isHidden: false,            // CH-65
    },
    {
        name: "Capecitabine (mg/m2/d)",
        property: "capecitabineDisplay",
        // sortable: true,          // CH-55
        nowrap: true,
        isHidden: false,            // CH-65
    },
    {
        name: "Hold Release Date",
        property: "holdReleaseDateFormatted",
        // sortable: true,          // CH-55
        sortableProperty: "holdReleaseDate",
        nowrap: true,
    },
    {
        name: "Hep/NS Fill",
        property: "hepNSFill",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    {
        name: "PR Volume",
        property: "pumpResidualVolumeFormatted",
        // sortable: true,          // CH-55
        sortableProperty: "pumpResidualVolume",
        nowrap: true,
    },
    // CH-59
    // {
    //     name: "Flow Rate At Chemo Time",
    //     property: "flowRateAtChemoTime",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    {
        name: "PR Days Since Last Fill",
        property: "pumpResidualDaysSinceLastFill",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    // CH-59
    // {
    //     name: "PR Volume Min",
    //     property: "pumpResidualVolumeMin",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    // CH-59
    // {
    //     name: "PR Volume Max",
    //     property: "pumpResidualVolumeMax",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    {
        name: "CO Completed",
        property: "coCompleted",
        // sortable: true,          // CH-55
        nowrap: true,
    },
    // CH-59
    // {
    //     name: "CO Approver",
    //     property: "coApprover",
    //     // sortable: true,          // CH-55
    //     nowrap: true,
    // },
    // CH-59
    // {
    //     name: "CO Approved ON",
    //     property: "coApprovedOnFormatted",
    //     // sortable: true,          // CH-55
    //     sortableProperty: "coApprovedOn",
    //     nowrap: true,
    // },
    {
        name: "Comments",
        property: "comments",
        // sortable: true,          // CH-55
        nowrap: true,
    },
];
// CH-17 - end

export default Visits;
