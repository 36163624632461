import React, { Component } from "react";
import "./App.css";

import $ from "jquery";

// https://fkhadra.github.io/react-toastify/introduction
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Azure AD authentication
import * as azureAD from "./utils/AzureAD";
import * as graph from "./utils/Graph";
// @ukhc/devops-react-library
import AppView from "@ukhc/devops-react-library/components/AppView";
import LandingPage from "@ukhc/devops-react-library/components/LandingPage";
// App components
import Patients from "./components/Patient/Patients";
import Visits from "./components/Visit/Visits";
import Reports from "./components/Reports/Reports";

import Settings from "./components/Settings";

import { logRequest, logSuccessResponse, logErrorResponse } from "./components/Lib/Logs";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutModalShow: false,
      selectedView: "",
      error: null,
      isAuthenticated: false,
      isAuthorized: false,
      isSigningIn: true, // true here for first load
      user: {},

      selectionOptions: {},
    };
    this.entity = "Selections";
    this.compClass = "App";

    this.fetchDropdowns = this.fetchDropdowns.bind(this);

    this.getUserProfile = this.getUserProfile.bind(this);
    this.handleShowAboutModal = this.handleShowAboutModal.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
  }

  // for the data refresh interval
  intervalID;

  componentDidMount() {
    this.getUserProfile();
    // Refresh all tokens and user identity data on a schedule every 10 minutes
    this.intervalID = setInterval(this.getUserProfile, 600000);
  }

  componentWillUnmount() {
    /*
          stop token refresh from continuing to run even
          after unmounting this component.
      */
    clearInterval(this.intervalID);
  }

  fetchDropdowns() {

    this.entity = "Institution";

    let fetchUrl = window.REACT_APP_API_URL + "/institutions";
    logRequest("GET", "fetchDropdowns", this.entity, this.compClass, fetchUrl);

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.state.user.apiToken.accessToken}`,
      },
    })
      .then((response) => {
        // error handling
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        logSuccessResponse("GET", "fetchDropdowns", this.entity, this.compClass, data);

        if (data.length && data.length > 0) {

          var selectionOptions = {};
          var institutionOptions = [];
          // institutionOptions.push({ value: "", label: "" }, data.map(i => ({ value: i.institutionName, label: i.institutionName, })));
          institutionOptions =  data.map(i => ({ value: i.institutionName, label: i.institutionName }));
          selectionOptions = Object.assign(selectionOptions, { Institution_InstitutionName: institutionOptions });
  
          this.setState({
            isLoading: false,
            selectionOptions: selectionOptions,
          });
        }
      })
      .catch((error) => {
        logErrorResponse("GET", "fetchDropdowns", this.entity, this.compClass, error);
      });
  }

  async login() {
    if (window.REACT_APP_DEBUG) console.log("login");
    this.setState({
      isSigningIn: true,
    });
    await azureAD.login();
    this.getUserProfile();
  }

  async logout() {
    await azureAD.logout();
    this.setState({
      isAuthenticated: false,
      isAuthorized: false,
      isSigningIn: false,
      user: {},
    });
  }

  async getUserProfile() {
    // If there is a cached user we are already logged in
    var cachedUser = azureAD.getCachedUser();
    if (window.REACT_APP_DEBUG)
      console.log("cached user: " + JSON.stringify(cachedUser));

    if (cachedUser) {
      // User is authenticated
      // Get the graph token
      const graphToken = await azureAD.getToken(["user.read"]);
      if (window.REACT_APP_DEBUG)
        console.log("graphToken: " + JSON.stringify(graphToken));

      // Get user data from Graph
      if (graphToken) {
        var graphUser = await graph.getUserDetails(graphToken);
        if (window.REACT_APP_DEBUG)
          console.log("graphUser: " + JSON.stringify(graphUser));
        var graphPhoto = await graph.getUserProfilePhoto(graphToken);
      } else {
        // token fetch failed, remove user data and go
        this.setState({
          isAuthorized: false,
          isSigningIn: false,
          isAuthenticated: false,
          user: { userName: cachedUser.username },
        });
        return;
      }

      // Get the token for the app api
      const apiToken = await azureAD.getToken([window.REACT_APP_API_SCOPE]);
      if (window.REACT_APP_DEBUG)
        console.log("apiToken: " + JSON.stringify(apiToken));

      // Roles
      if (apiToken) {
        // Turn the user roles into a hierarchical thing
        // The user object will carry a bool for each role
        // We also determine a top level, "primary" role for display
        var theRoles = "";
        var thePrimaryRole = "";
        var isAuthorized = false;
        var isAdministrator = false;
        var isUser = false;
        if (apiToken.idTokenClaims.roles) {
          // Only set these if we have roles in the token.
          theRoles = apiToken.idTokenClaims.roles.toString();
          // Only for TESTING !!!
          // theRoles = "User,Administrator";     // Full access
          // theRoles = "Administrator"; 
          // theRoles = "User"; 
          // Only for TESTING !!!

          if (window.REACT_APP_DEBUG) console.log(theRoles);
          if (theRoles.includes("User")) {
            thePrimaryRole = "User";
            isAuthorized = true;
            isUser = true;
          }
          if (theRoles.includes("Administrator")) {
            thePrimaryRole = "Administrator";
            isAuthorized = true;
            isUser = true;
            isAdministrator = true;
          }
        } else {
          // user has no roles in the token
        }

        // Initialize the selectedView based on role
        if (this.state.selectedView === "") {
          var theSelectedView = "Patients";
          if (!isUser) {
            theSelectedView = "Patients";
          }
          this.setState({
            selectedView: theSelectedView,
          });
        }
      } else {
        // token fetch failed, remove user data and go
        this.setState({
          isAuthorized: false,
          isSigningIn: false,
          isAuthenticated: false,
          user: { userName: cachedUser.username },
        });
        return;
      }

      //Set the state with the new user data
      this.setState({
        isAuthenticated: true,
        isAuthorized: isAuthorized,
        isSigningIn: false,
        user: {
          displayName: graphUser.displayName,
          email: graphUser.mail || graphUser.userPrincipalName,
          isAdministrator: isAdministrator,
          isUser: isUser,
          primaryRole: thePrimaryRole,
          photo: graphPhoto,
          roles: theRoles,
          graphToken: graphToken,
          apiToken: apiToken,
          userName: apiToken.account.username,
        },
        error: null,
      },
        () => {
          this.fetchDropdowns();
        });
    } else {
      // there was no user in the browser cache
      this.setState({
        isAuthenticated: false,
        isAuthorized: false,
        isSigningIn: false,
        user: {},
      });
    }
  }

  handleChangeView(view) {
    // Show Help Modal for mobile
    if (view === "Help") {
      $("#helpModal").modal("show");
    } else {
      this.setState({
        selectedView: view,
      });
      // Show view for mobile
      $("#contentViewName").text(view);
    }
  }

  handleShowAboutModal() {
    if (window.REACT_APP_DEBUG) console.log("handleShowAboutModal");
    this.setState({
      aboutModalShow: true,
    });
  }

  render() {

    // CH-57-58 
    // setTimeout(() => {
    //   // $("#contentView").css('height', 'calc(100vh - 230px)');
    //   var navbarApplicationName = $("#navbarApplicationName");
    //   if (!navbarApplicationName || !navbarApplicationName.length) {
    //     $("nav.navbar.navbar-dark > span")
    //       .append('<span class="separator d-sm-inline-block d-md-inline-block d-lg-none d-xl-none mx-3">|</span>')
    //       .append(`<span id="navbarApplicationName" class="d-sm-inline-block d-md-inline-block d-lg-none d-xl-none">${window.REACT_APP_NAME}</span>`)
    //       .append('<span class="separator d-none d-sm-inline-block d-md-inline-block d-lg-none d-xl-none mx-2">></span>')
    //       .append(`<span id="contentViewName" class="d-none d-sm-inline-block d-md-inline-block d-lg-none d-xl-none">${this.state.selectedView}</span>`);
    //   }

    //   $("#contentNavbar").addClass('d-none d-lg-flex d-xl-flex');                         // Shows contentNavbar only on lg and xl  
    //   $("#Settings").addClass('d-sm-flex d-md-flex d-lg-none d-xl-none');                 // Hides Settings nav link only on lg and xl 
    //   $("#Help").addClass('d-sm-flex d-md-flex d-lg-none d-xl-none');                     // Hides Help nav link only on lg and xl 
    // }, 200);

    if (window.REACT_APP_DEBUG) {
      console.log("isAuthenticated: " + this.state.isAuthenticated);
      console.log(JSON.stringify(this.state.user));
    }

    // Build the array of nav links for the header
    // let navigation = [{ name: "All Records" }];
    // only show the reports dropdown if the user is an administrator
    // if (this.state.user.isAdministrator) {
    //   navigation.push({
    //     name: "Reports",
    //     sub: [{ name: "Report 1" }, { name: "Report 2" }, { name: "Report 3" }],
    //   });
    // }

    let navigation = [];

    navigation.push(
      {
        name: "Patients",
      },
      // {
      //   name: "Visits",
      // },
      {
        name: "Reports",
      },
    );

    // Mobile Navigation Links
    // CH-57-58 
    // navigation.push(
    //   {
    //     name: "Settings",
    //   },
    //   {
    //     name: "Help",
    //   },
    // );

    var contentView = <div />;
    switch (this.state.selectedView) {
      case "Settings":
        contentView =
          <Settings
            user={this.state.user} />;
        break;
      case "Patients":
        contentView =
          <Patients
            selectionOptions={this.state.selectionOptions}
            user={this.state.user}
            isModal={false}
            modalEntity={"Patient"}
          />;
        break;
      case "Visits":
        contentView =
          <Visits
            selectionOptions={this.state.selectionOptions}
            user={this.state.user}
            patient={null}
            isModal={false}
            action={"view"}
            parentComp={"App"}
            modalEntity={"Visit"}
          />;
        break;
      case "Reports":
        contentView =
          <Reports
            user={this.state.user}
            isModal={false}
          />;
        break;
      default:
        contentView =
          <Patients
            selectionOptions={this.state.selectionOptions}
            user={this.state.user}
            isModal={false}
            modalEntity={"Patient"}
          />;
        break;
    }

    /* Login Screen */
    if (!this.state.isAuthenticated || !this.state.isAuthorized) {
      return (
        <LandingPage
          applicationName={window.REACT_APP_NAME}
          applicationDescription={window.REACT_APP_DESCRIPTION}
          isAuthenticated={this.state.isAuthenticated}
          isAuthorized={this.state.isAuthorized}
          isSigningIn={this.state.isSigningIn}
          login={this.login}
          logout={this.logout}
          userName={this.state.user.userName}
        />
      );
    }

    /* Main App View */
    return (
      <>
        <AppView
          applicationName={window.REACT_APP_NAME}
          applicationVersion={window.REACT_APP_VERSION}
          contentView={contentView}
          navigation={navigation}
          handleChangeView={this.handleChangeView}
          logout={this.logout}
          selectedView={this.state.selectedView}
          showSettingsGear={true}
          showSideNavbar={false}
          user={this.state.user}
        />
        <ToastContainer
          // position="top-right"
          position="bottom-left"
          // position="top-center"
          // theme="colored"
          autoClose={6000}
          // limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

export default App;
