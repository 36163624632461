/// CH-17
/// ================================================================
/// TableDashboard.js
///
/// - Props:
///   - checkboxSelectOnly: A bool to determine if rows can be selected by checkboxes only
///   - colorRowDangerProperty: The property name for optionally coloring the row table-danger
///   - colorRowDangerValue: The value that colorRowDangerProperty should equal to color the row table-danger
///   - colorRowInfoProperty: The property name for optionally coloring the row table-info
///   - colorRowInfoValue: The value that colorRowInfoProperty should equal to color the row table-info
///   - colorRowSecondaryProperty: The property name for optionally coloring the row table-secondary
///   - colorRowSecondaryValue: The value that colorRowSecondaryProperty should equal to color the row table-secondary
///   - colorRowSuccessProperty: The property name for optionally coloring the row table-success
///   - colorRowSuccessValue: The value that colorRowSuccessProperty should equal to color the row table-success
///   - colorRowWarningProperty: The property name for optionally coloring the row table-warning
///   - colorRowWarningValue: The value that colorRowWarningProperty should equal to color the row table-warning
///   - columns: An array of objects that defines the columns to display on the table as well as the column properties
///   - disabled: A bool to show the table in a disabled state when set to true
///   - handleSetSelectedRows: A function to update the array of selected rows when select/deselect actions occur
///   - handleSetSortedBy: A function to sort by a new column or change the sort direction if column is already being sorted
///   - handleSetSortDescending: A function to modify the sort direction of the column
///   - hideTableHeader: A bool that will hide the table header if true
///   - isBordered: A bool that will add the `table-bordered` class to the table if true
///   - isHeadingBold: A bool that will add the `font-weight-bold` class to the table heading if true
///   - isHeadingUppercase: A bool that will add the `text-uppercase` class to the table heading if true
///   - isLoading: A bool to show or hide the loading state of the table
///   - isMultiSelect: A bool that will add or remove the ability to select multiple rows in a table
///   - isSmall: A bool that will add the `table-sm` class to the table if true
///   - records: An array of the table data
///   - responseStatusAdditionalMessage: A string for the additional message in the ResponseStatusAlert accordion
///   - responseStatusCode: A number for the http response status code
///   - responseStatusMessage: A string for an alternate message to display in the ResponseStatusAlert
///   - responseStatusVariant: A string for the bootstrap variant color for the ResponseStatusAlert
///   - selectedRows: An array of objects representing selected rows. e.g. [ { uid: 3 }, { uid: 4 } ]
///   - sortedBy: A string for the currently sorted column property name
///   - sortDescending: A bool that represents the sort direction
///   - uidProperty: A string to identify the uid property name of the records in a table
///   - useHoverableRows: A bool that will add the `table-hover` class to the table if true
///   - useStripedRows: A bool that will add the `table-striped` class to the table if true
///
/// - Example usage:
///   <TableDashboard
///     checkboxSelectOnly={false}
///     columns={tableColumns}
///     disabled={this.state.isTableDisabled}
///     handleSetSelectedRows={this.handleSetSelectedRows}
///     handleSetSortedBy={this.handleSetSortedBy}
///     handleSetSortDescending={this.handleSetSortDescending}
///     hideTableHeader={false}
///     isBordered={false}
///     isHeadingBold={false}
///     isHeadingUppercase={true}
///     isLoading={this.state.isLoading}
///     isMultiSelect={true}
///     isSmall={true}
///     records={this.state.records}
///     responseStatusCode={this.state.fetchResponseStatusCode}
///     selectedRows={this.state.selectedRows}
///     sortedBy={this.state.sortedBy}
///     sortDescending={this.state.sortDescending}
///     uidProperty={this.state.uidProperty}
///     useHoverableRows={true}
///     useStripedRows={false}
///   />;
///
/// - Table columns
///   - name: A string for the display name for the column header
///   - property: A string for the record property name for the data
///   - sortable: A bool to make the column sortable
///   - sortableProperty: A string for the property name to use for sorting if different than property above
///   - align: A string for the text alignment (center, left, right) (defaults left)
///   - nowrap: A bool to stop the text from wrapping in the cell (false by default)
///   - width: A number for the width of a cell in pixels
///
/// - Example table columns / Raws
///   let dashboardRaws = [
///     {
///       name: "First Name",
///       property: "firstName",
///       sortable: "true",
///       width: 300,
///     },
///     {
///       name: "Birthday",
///       property: "dobFormatted",
///       align: "center",
///       sortable: "true",
///       sortableProperty: "dob",
///     },
///     {
///       name: "Notes",
///       property: "notes",
///       nowrap: true,
///     },
///   ];

import React from "react";
import PropTypes from "prop-types";
import "bootstrap-icons/font/bootstrap-icons.css";
import { pivot } from "../Lib/Helper";              // CH-17

class TableDashboard extends React.Component {
    handleSetSortedBy(property) {
        if (!this.props.disabled) {
            if (this.props.handleSetSortedBy) {
                if (this.props.sortedBy === property) {
                    // Already sorted by this column so toggle descending
                    if (this.props.handleSetSortDescending) {
                        this.props.handleSetSortDescending(!this.props.sortDescending);
                    }
                } else {
                    // Clicked on a new column, default descending to false
                    this.props.handleSetSortDescending(false);
                    this.props.handleSetSortedBy(property);
                }
            }
        }
    }

    handleSetSelectedRows(rowUID, sender) {
        // console.log("rowUID: " + rowUID + " -  sender: " + sender);
        if (!this.props.disabled) {
            if (this.props.handleSetSelectedRows && this.props.selectedRows) {
                if (rowUID) {
                    if (this.props.isMultiSelect) {
                        // TODO: setup multiselect
                        // Clicking the same row will not deselect it
                        // You have to click on the checkbox
                        var newSelectedRows = [];
                        if (this.props.selectedRows.length > 0) {
                            var selectedRowClicked = false;
                            for (const selectedRow of this.props.selectedRows) {
                                if (selectedRow.uid === rowUID) {
                                    // the user clicked on a selected row
                                    selectedRowClicked = true;
                                    if (sender === "checkbox") {
                                        // it's deselected, don't add the row
                                    } else {
                                        // it remains selected, add the row
                                        newSelectedRows.push({ uid: selectedRow.uid });
                                    }
                                } else {
                                    // the currently selected row doesn't match the clicked row
                                    // add the row
                                    newSelectedRows.push({ uid: selectedRow.uid });
                                }
                            }
                            if (!selectedRowClicked) {
                                // the user clicked an unselected row
                                // add the new row
                                if (this.props.checkboxSelectOnly) {
                                    if (sender === "checkbox") {
                                        newSelectedRows.push({ uid: rowUID });
                                    }
                                } else {
                                    newSelectedRows.push({ uid: rowUID });
                                }
                                if (sender === "checkbox") {
                                    // add the row as well as keep the other rows too
                                } else {
                                    // clicking on the row body acts like single select
                                    // this is now the only selected row
                                    if (!this.props.checkboxSelectOnly) {
                                        newSelectedRows = [{ uid: rowUID }];
                                    }
                                }
                            }
                        } else {
                            // there are no selected rows, add the row
                            if (this.props.checkboxSelectOnly) {
                                if (sender === "checkbox") {
                                    newSelectedRows.push({ uid: rowUID });
                                }
                            } else {
                                newSelectedRows.push({ uid: rowUID });
                            }
                        }
                        this.props.handleSetSelectedRows(newSelectedRows);
                    } else {
                        // Single select mode
                        var rowArray = [];
                        // Clicking the same row will not deselect it
                        // You have to click on the checkbox
                        if (this.props.selectedRows.length > 0) {
                            if (this.props.selectedRows[0].uid === rowUID) {
                                if (sender === "checkbox") {
                                    rowArray = [];
                                } else {
                                    rowArray = [{ uid: rowUID }];
                                }
                            } else {
                                if (this.props.checkboxSelectOnly) {
                                    if (sender === "checkbox") {
                                        rowArray = [{ uid: rowUID }];
                                    } else {
                                        rowArray = this.props.selectedRows;
                                    }
                                } else {
                                    rowArray = [{ uid: rowUID }];
                                }
                            }
                        } else {
                            if (this.props.checkboxSelectOnly) {
                                if (sender === "checkbox") {
                                    rowArray = [{ uid: rowUID }];
                                }
                            } else {
                                rowArray = [{ uid: rowUID }];
                            }
                        }
                        this.props.handleSetSelectedRows(rowArray);
                    }
                } else {
                    // there was no rowUID
                    // if there is more than one selected,
                    // deselect all if called by the checkbox-header
                    if (sender === "checkbox-header") {
                        if (this.props.isMultiSelect) {
                            if (this.props.selectedRows.length > 0) {
                                // if there is more than one selected, deselect all
                                this.props.handleSetSelectedRows();
                            } else {
                                // if there is 0 selected, select all
                                if (this.props.uidProperty) {
                                    var newSelectedRowsArray = [];
                                    for (const record of this.props.records) {
                                        newSelectedRowsArray.push({
                                            uid: record[this.props.uidProperty],
                                        });
                                    }
                                    this.props.handleSetSelectedRows(newSelectedRowsArray);
                                } else {
                                    // no uidProperty in props, do nothing
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    render() {
        const columns = this.props.columns;
        // CH-17  - begin
        // const records = this.props.records;
        var records;
        if (this.props.records && this.props.records.length > 0) {
            records = pivot(this.props.records);
        }
        // CH-17  - end

        // Set the table class options
        var tableClass = "table";
        if (this.props.isSmall) {
            tableClass = tableClass + " table-sm";
        }
        if (this.props.isBordered) {
            tableClass = tableClass + " table-bordered";
        }
        if (this.props.useStripedRows) {
            tableClass = tableClass + " table-striped";
        }
        if (this.props.useHoverableRows) {
            tableClass = tableClass + " table-hover";
        }

        const selectedRows = this.props.selectedRows;
        const uidProperty = this.props.uidProperty;

        function getTableHeadingClass(isBold, isUppercase, sortable = false) {
            let classString = "text-nowrap m-0 p-0";
            classString += sortable ? " btn btn-link " : "";
            classString += isBold ? " font-weight-bold" : " font-weight-normal";
            classString += isUppercase ? " text-uppercase" : "";
            return classString;
        }

        const colorRowDangerProperty = this.props.colorRowDangerProperty;
        const colorRowDangerValue = this.props.colorRowDangerValue;
        const colorRowWarningProperty = this.props.colorRowWarningProperty;
        const colorRowWarningValue = this.props.colorRowWarningValue;
        const colorRowInfoProperty = this.props.colorRowInfoProperty;
        const colorRowInfoValue = this.props.colorRowInfoValue;
        const colorRowSuccessProperty = this.props.colorRowSuccessProperty;
        const colorRowSuccessValue = this.props.colorRowSuccessValue;
        const colorRowSecondaryProperty = this.props.colorRowSecondaryProperty;
        const colorRowSecondaryValue = this.props.colorRowSecondaryValue;

        function getTableRowClass(record) {
            var classString = "";
            if (uidProperty) {
                if (selectedRows) {
                    for (const row of selectedRows) {
                        if (record[uidProperty] === row.uid) {
                            classString = classString + "table-primary";
                        }
                    }
                }
            }

            if (classString === "" && colorRowDangerProperty && colorRowDangerValue) {
                if (record[colorRowDangerProperty] === colorRowDangerValue) {
                    classString = classString + "table-danger";
                }
            }
            if (
                classString === "" &&
                colorRowWarningProperty &&
                colorRowWarningValue
            ) {
                if (record[colorRowWarningProperty] === colorRowWarningValue) {
                    classString = classString + "table-warning";
                }
            }
            if (classString === "" && colorRowInfoProperty && colorRowInfoValue) {
                if (record[colorRowInfoProperty] === colorRowInfoValue) {
                    classString = classString + "table-info";
                }
            }
            if (
                classString === "" &&
                colorRowSuccessProperty &&
                colorRowSuccessValue
            ) {
                if (record[colorRowSuccessProperty] === colorRowSuccessValue) {
                    classString = classString + "table-success";
                }
            }
            if (
                classString === "" &&
                colorRowSecondaryProperty &&
                colorRowSecondaryValue
            ) {
                if (record[colorRowSecondaryProperty] === colorRowSecondaryValue) {
                    classString = classString + "table-secondary";
                }
            }
            return classString;
        }

        function getTableColumnClass(uid) {
            var classString = "";
            if (selectedRows) {
                for (const row of selectedRows) {
                    if (uid === row.uid) {
                        classString = classString + "table-primary";
                    }
                }
            }
            return classString;
        }

        function getTableRowChecked(record) {
            var isChecked = false;
            if (uidProperty) {
                if (selectedRows) {
                    for (const row of selectedRows) {
                        if (record[uidProperty] === row.uid) {
                            isChecked = true;
                        }
                    }
                }
            }
            return isChecked;
        }

        function getTableColumnChecked(uid) {
            var isChecked = false;
            if (selectedRows) {
                for (const row of selectedRows) {
                    if (uid === row.uid) {
                        isChecked = true;
                    }
                }
            }
            return isChecked;
        }

        function getColumnHeaderCellClass(align) {
            var buttonWrapperClass = "";
            var buttonClass = "";
            if (align) {
                switch (align) {
                    case "center":
                        buttonWrapperClass =
                            buttonWrapperClass + " text-center justify-content-center";
                        buttonClass = buttonClass + " text-center";
                        break;
                    case "right":
                        buttonWrapperClass =
                            buttonWrapperClass + " text-right justify-content-end";
                        buttonClass = buttonClass + " text-right";
                        break;
                    default:
                        buttonWrapperClass =
                            buttonWrapperClass + " text-left justify-content-start";
                        buttonClass = buttonClass + " text-left";
                }
            } else {
                // align was empty, use the default
                buttonWrapperClass = buttonWrapperClass + " text-left";
                buttonClass = buttonClass + " text-left";
            }
            return [buttonClass, buttonWrapperClass];
        }

        function getCellClass(align, nowrap) {
            var cellClass = "align-middle";
            if (nowrap) {
                cellClass = cellClass + " text-nowrap";
            }
            if (align) {
                switch (align) {
                    case "center":
                        cellClass = cellClass + " text-center";
                        break;
                    case "right":
                        cellClass = cellClass + " text-right";
                        break;
                    default:
                        cellClass = cellClass + " text-left";
                }
            } else {
                // align was empty, use the default
                cellClass = cellClass + " text-left";
            }
            return cellClass;
        }

        function getCellWidth(column) {
            let cellWidth = "auto";
            if (column.width && typeof column.width === "number" && !column.nowrap) {
                cellWidth = column.width;
            }
            return cellWidth;
        }

        if (this.props.isLoading) {
            return (
                <div className="text-center text-muted mt-4" id="isLoadingDiv">
                    {/* <h6>Loading...</h6> */}
                    <div className="spinner-grow spinner-grow-sm mr-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm mr-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow spinner-grow-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        return (
            <table
                className={tableClass}
                id={this.props.id}
                style={{
                    fontSize: 14,
                    borderCollapse: "separate",
                    borderSpacing: 0,
                    borderTopStyle: "none",
                    filter: this.props.disabled ? `opacity(0.65)` : `opacity(1)`,
                }}
            >
                <thead className="thead-light">
                    <tr id="tableHeaderRow">
                        {
                            (records && records[uidProperty]) && (
                                <>
                                    <th></th>
                                    {records[uidProperty].map((uid) =>
                                        this.props.handleSetSelectedRows && this.props.selectedRows && (
                                            <th
                                                className="text-center align-middle"
                                                id={"checkboxTableCell" + uid}
                                                key={"checkboxTableCell" + uid}
                                                style={{
                                                    borderLeftStyle: "none",
                                                    borderRightStyle: "none",
                                                    borderBottomStyle: "none",
                                                }}
                                                onClick={() =>
                                                    this.handleSetSelectedRows(uid, "checkbox")
                                                }
                                            >
                                                <div className="">
                                                    {getTableColumnChecked(uid) && (
                                                        <i
                                                            className="bi-check-square-fill"
                                                            style={{ color: "#0067d5" }}
                                                            id={"checkboxCheckSquareFillIcon" + uid}
                                                            key={"checkboxCheckSquareFillIcon" + uid}
                                                        ></i>
                                                    )}
                                                    {!getTableColumnChecked(uid) && (
                                                        <i
                                                            className="bi-square"
                                                            id={"checkboxSquareIcon" + uid}
                                                            key={"checkboxSquareIcon" + uid}
                                                            style={{ color: "#6c757d" }}
                                                        ></i>
                                                    )}
                                                </div>
                                            </th>
                                        )
                                    )}
                                </>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {records &&
                        columns.map((column) =>
                            Object.entries(records).map(([key, values], j) => (
                                // (key === column.property && key !== uidProperty) && (
                                (key === column.property && key !== uidProperty && column.isHidden !== true) && (       // CH-65
                                    <tr
                                        id={"TableRow" + key}
                                        key={"TableRow" + key}
                                    >
                                        {(!column.sortable ||
                                            !this.props.handleSetSortedBy ||
                                            !this.props.handleSetSortDescending ||
                                            !this.props.sortedBy ||
                                            this.props.sortDescending === undefined) && (
                                                <td>
                                                    <div
                                                        className={getTableHeadingClass(
                                                            this.props.isHeadingBold,
                                                            this.props.isHeadingUppercase
                                                        )}
                                                        id={column.property + "TableHeaderText"}
                                                        key={column.property + "TableHeaderText"}
                                                    >
                                                        {column.name}
                                                    </div>
                                                </td>
                                            )}

                                        {column.sortable &&
                                            this.props.handleSetSortedBy &&
                                            this.props.handleSetSortDescending &&
                                            this.props.sortedBy &&
                                            this.props.sortDescending !== undefined && (
                                                <td>
                                                    <div
                                                        className={getTableHeadingClass(
                                                            this.props.isHeadingBold,
                                                            this.props.isHeadingUppercase,
                                                            column.sortable
                                                        )}
                                                        id={column.property + "TableHeaderText"}
                                                        key={column.property + "TableHeaderText"}
                                                        onClick={() =>
                                                            this.handleSetSortedBy(
                                                                column.sortableProperty
                                                                    ? column.sortableProperty
                                                                    : column.property
                                                            )
                                                        }
                                                    >
                                                        {
                                                            (this.props.sortedBy === column.property ||
                                                                this.props.sortedBy === column.sortableProperty) &&
                                                            this.props.sortDescending && (
                                                                <i
                                                                    className="bi bi-arrow-left text-success pr-2"
                                                                    id={column.property + "TableHeaderArrowUpIcon"}
                                                                    key={column.property + "TableHeaderArrowUpIcon"}
                                                                    style={{ color: "#000" }}
                                                                ></i>
                                                            )}

                                                        {
                                                            (this.props.sortedBy === column.property ||
                                                                this.props.sortedBy === column.sortableProperty) &&
                                                            this.props.sortDescending === false && (
                                                                <i
                                                                    className="bi bi-arrow-right text-success pr-2"
                                                                    id={column.property + "TableHeaderArrowDownIcon"}
                                                                    key={column.property + "TableHeaderArrowDownIcon"}
                                                                    style={{ color: "#000" }}
                                                                ></i>
                                                            )}

                                                        {
                                                            this.props.sortedBy !== column.property &&
                                                            this.props.sortedBy !== column.sortableProperty && (
                                                                <i
                                                                    className="bi bi-arrow-left-right pr-2"
                                                                    id={column.property + "TableHeaderArrowDownUpIcon"}
                                                                    key={column.property + "TableHeaderArrowDownUpIcon"}
                                                                    style={{ color: "#6c757d" }}
                                                                ></i>
                                                            )}


                                                        {column.name}
                                                    </div>
                                                </td>
                                            )}

                                        {
                                            values.map((value, i) => {
                                                if (!column.isHidden) {
                                                    if (key === column.property) {
                                                        return (
                                                            <td
                                                                className={getTableColumnClass(records[uidProperty][i])}
                                                                id={
                                                                    column.property + "TableCell" + records[uidProperty][i]
                                                                }
                                                                key={
                                                                    column.property + "TableCell" + records[uidProperty][i]
                                                                }
                                                                style={{
                                                                    borderRightStyle: "none",
                                                                    borderBottomStyle: "none",
                                                                }}
                                                                onClick={() =>
                                                                    this.handleSetSelectedRows(records[uidProperty][i], key)
                                                                }
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: getCellWidth(column),
                                                                    }}
                                                                >
                                                                    {value}
                                                                </div>
                                                            </td>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </tr>
                                )
                            ))
                        )}

                    {!records && (
                        <tr>
                            <td>
                                <h5 className="pt-2">No records</h5>
                            </td>
                        </tr>

                    )}
                </tbody>
            </table>
        );
    }
}

// Props validation and defaults
TableDashboard.propTypes = {
    checkboxSelectOnly: PropTypes.bool,
    colorRowDangerProperty: PropTypes.string,
    colorRowDangerValue: PropTypes.string,
    colorRowInfoProperty: PropTypes.string,
    colorRowInfoValue: PropTypes.string,
    colorRowSecondaryProperty: PropTypes.string,
    colorRowSecondaryValue: PropTypes.string,
    colorRowSuccessProperty: PropTypes.string,
    colorRowSuccessValue: PropTypes.string,
    colorRowWarningProperty: PropTypes.string,
    colorRowWarningValue: PropTypes.string,
    columns: PropTypes.array,
    disabled: PropTypes.bool,
    handleSetSelectedRows: PropTypes.func,
    handleSetSortedBy: PropTypes.func,
    handleSetSortDescending: PropTypes.func,
    hideTableHeader: PropTypes.bool,
    id: PropTypes.string,
    responseStatusAdditionalMessage: PropTypes.string,
    responseStatusCode: PropTypes.number,
    responseStatusMessage: PropTypes.string,
    responseStatusVariant: PropTypes.string,
    isBordered: PropTypes.bool,
    isHeadingBold: PropTypes.bool,
    isHeadingUppercase: PropTypes.bool,
    isLoading: PropTypes.bool,
    isMultiSelect: PropTypes.bool,
    isSmall: PropTypes.bool,
    records: PropTypes.array,
    selectedRows: PropTypes.array,
    sortedBy: PropTypes.string,
    sortDescending: PropTypes.bool,
    uidProperty: PropTypes.string,
    useHoverableRows: PropTypes.bool,
    useStripedRows: PropTypes.bool,
};

TableDashboard.defaultProps = {
    checkboxSelectOnly: false,
    columns: [
        {
            name: "Setup The Columns For This Table",
            property: "setup",
            align: "center",
        },
    ],
    disabled: false,
    hideTableHeader: false,
    id: "table",
    isBordered: false,
    isHeadingBold: false,
    isHeadingUppercase: true,
    isLoading: false,
    isMultiSelect: false,
    isSmall: true,
    records: [],
    responseStatusVariant: "light",
    useHoverableRows: false,
    useStripedRows: false,
};

export default TableDashboard;