/// Button.js
///
/// - Props:
///   - classes: A string providing additional classes to the button element
///   - form: A string to identify the form the button belongs to
///   - icon: A string for the bootstrap icon to display in the button
///   - iconPrefix: A bool to set the location of the icon
///   - id: A string for the id of the button
///   - isDisabled: A bool to determine if button is disabled
///   - isLoading: A bool to determine if the button is in a loading state
///   - isOutline: A bool to set the style of button as outlined
///   - loadingText: A string for the text that displays when button is loading
///   - onClick: A func to define what the button does when clicked
///   - px: A string to set the width padding
///   - showLoadingSpinner: A bool to show a spinner when in a loading state
///   - showText: A bool to show or hide the button text
///   - size: A string to set the size of the button
///   - text: A string to display button text
///   - type: A string to define the type of button
///   - variant: A function to execute when the user has clicked the button
///
/// - Example usage:
///   <Button
///      icon="bi-trash"
///      isDisabled={this.props.isDisabled}
///      isLoading={this.props.isLoading}
///      isOutline={false}
///      size="medium"
///      type="submit"
///      variant="primary"
///   />

import React from "react";
import PropTypes from "prop-types";
import "bootstrap-icons/font/bootstrap-icons.css";

class Button extends React.Component {
  handleOnClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const outline = this.props.isOutline ? "btn-outline-" : "btn-";

    let variant = "";
    switch (this.props.variant) {
      case "secondary":
        variant = outline + "secondary";
        break;
      case "success":
        variant = outline + "success";
        break;
      case "warning":
        variant = outline + "warning";
        break;
      case "danger":
        variant = outline + "danger";
        break;
      case "info":
        variant = outline + "info";
        break;
      default:
        variant = outline + "primary";
    }

    let iconVariant = "";
    switch (this.props.iconVariant) {
      case "primary":
        iconVariant = " text-primary";
        break;
      case "secondary":
        iconVariant = " text-secondary";
        break;
      case "success":
        iconVariant = " text-success";
        break;
      case "warning":
        iconVariant = " text-warning";
        break;
      case "danger":
        iconVariant = " text-danger";
        break;
      case "info":
        iconVariant = " text-info";
        break;
      case "muted":
        iconVariant = " text-muted";
        break;
      default:
        iconVariant = "";
    }

    let size = "";
    let rounded = "";
    let fontSize = 14;
    switch (this.props.size) {
      case "small":
        size = " btn-sm";
        rounded = " rounded-sm";
        fontSize = 13;
        break;
      case "large":
        size = " btn-lg";
        break;
      default:
        size = "";
    }

    if (this.props.variant === "link") {
      variant = "btn-link";
    }

    let icon = "";
    let margin = "";
    if (this.props.icon) {
      if (this.props.showText) {
        margin = this.props.iconPrefix ? "mr-2 " : "ml-2 ";
      }
      icon = margin + "bi " + this.props.icon;
    }

    let px = "";
    switch (this.props.px) {
      case "0":
        px = " px-0";
        break;
      case "1":
        px = " px-1";
        break;
      case "2":
        px = " px-2";
        break;
      case "3":
        px = " px-3";
        break;
      case "4":
        px = " px-4";
        break;
      case "5":
        px = " px-5";
        break;
      default:
        px = "";
    }

    let style = {};
    if (this.props.showText) {
      style = {
        fontSize: fontSize,
        letterSpacing: 0.25,
      };
    }

    const bold = this.props.isFontBold ? " font-weight-bold" : "";

    const classes = this.props.classes ? " " + this.props.classes : "";

    const dataTarget = this.props.dataTarget ? "#" + this.props.dataTarget : "";
    const dataToggle = this.props.dataToggle ? this.props.dataToggle : "";
    const dataDismiss = this.props.dataDismiss ? this.props.dataDismiss : "";

    return (
      <button
        className={"btn " + variant + size + px + classes + rounded + bold}
        data-target={dataTarget}
        data-toggle={dataToggle}
        data-dismiss={dataDismiss}
        disabled={this.props.isLoading || this.props.isDisabled}
        id={this.props.id}
        onClick={() => this.handleOnClick()}
        style={style}
        type={this.props.type}
      >
        {this.props.isLoading ? (
          this.props.showLoadingSpinner ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              {this.props.loadingText}
            </>
          ) : (
            this.props.loadingText
          )
        ) : (
          <>
            {this.props.iconPrefix && this.props.icon && (
              <i className={icon + iconVariant} ></i>
            )}
            {this.props.showText && this.props.text}
            {!this.props.iconPrefix && this.props.icon && (
              <i className={icon + iconVariant}></i>
            )}
          </>
        )}
      </button>
    );
  }
}

Button.propTypes = {
  classes: PropTypes.string,
  dataToggle: PropTypes.string,
  dataTarget: PropTypes.string,
  icon: PropTypes.string,
  iconPrefix: PropTypes.bool,
  iconVariant: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.string,
  isDisabled: PropTypes.bool,
  isFontBold: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOutline: PropTypes.bool,
  loadingText: PropTypes.string,
  px: PropTypes.string,
  showLoadingSpinner: PropTypes.bool,
  showText: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

Button.defaultProps = {
  classes: "",
  dataToggle: null,
  dataTarget: null,
  icon: null,
  iconPrefix: true,
  id: "button",
  isDisabled: false,
  isFontBold: false,
  isLoading: false,
  isOutline: false,
  loadingText: "Loading...",
  showLoadingSpinner: true,
  showText: true,
  size: "medium",
  text: "Button",
  type: "button",
  variant: "primary",
};

export default Button;
